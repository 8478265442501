import React, { useCallback } from "react";
import Dropzone, { useDropzone } from "react-dropzone";

function AvsDropzone(props) {
  return (
    <Dropzone  {...props}>
      {({ getRootProps, getInputProps }) => (
        <div className="container">
          <div 
            {...getRootProps({
              className: "dropzone",
              onDrop: (event) => event.stopPropagation(),
            })}
          >
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
        </div>
      )}
    </Dropzone>
  );
}
export default AvsDropzone;
