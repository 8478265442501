import moment from "moment";

const getFisNo = () => {
  return moment().format("YYYYMMDDhmmssSS");
};

const getGunlukNo = (companyName, fishNo) => {
  return `${companyName.toUpperCase()}-${fishNo}`;
};

const getTextShortWithThreeDots = (text, maxLength) => {
  return text.substring(0, maxLength) + (text.length > maxLength ? "..." : "");
};
const getFixedNumber = (x, count = 2) => {
  var result = Number.parseFloat(x).toFixed(count);
  return Number.parseFloat(result);
};

export { getFisNo, getGunlukNo, getTextShortWithThreeDots, getFixedNumber };
