// import PropTypes from "prop-types";
import React from "react";

//language
import { FormattedMessage } from "react-intl";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";

// react-router-dom components
import { Link } from "react-router-dom";

// Soft UI component
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function AvsBreadcrumbs({ title, currentRoute, otherRoutes }) {
  return (
    <SuiBox display="flex" justifyContent="space-between" alignItems="center">
      <SuiBox>
        <SuiTypography
          component="span"
          variant="h6"
          fontWeight="bold"
          color="dark"
          sx={{ lineHeight: 0 }}
        >
          <FormattedMessage id={title} />
        </SuiTypography>
      </SuiBox>
      <MuiBreadcrumbs aria-label="breadcrumb">
        <SuiTypography variant="caption" fontWeight="light">
          <FormattedMessage id={currentRoute} />
        </SuiTypography>
        {otherRoutes.map((otherRoute, index) => (
          <Link key={index} to={`/${otherRoute.route}`}>
            <SuiTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color="dark"
              opacity={0.8}
              sx={{ lineHeight: 0 }}
            >
              <FormattedMessage id={otherRoute.label} />
            </SuiTypography>
          </Link>
        ))}
      </MuiBreadcrumbs>
    </SuiBox>
  );
}

export default AvsBreadcrumbs;
