import React, { useCallback, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { LicenseManager } from "ag-grid-enterprise";

import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";

import "./styles.css";

//service axios
import axios from "services/axios/index";
import { getDateFormatYearMonthDay } from "utils/helpers/dateFormat";

//state
import {
  footerTotalAtom,
  mainFilterAtom,
  virmanSelectAtom,
} from "recoil/atoms";
import { useSetRecoilState, useRecoilValue } from "recoil";

//table column
import { mizanColumn } from "components/avs/AvsAgGrid/AvsVirementDetailGrid/column";

// utils
import { tableTypeList } from "utils/constants/recordType";
import SuiDatePicker from "components/SuiDatePicker";

LicenseManager.setLicenseKey(
  "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-4_September_2022_[v2]_MTY2MjI0NjAwMDAwMA==a44b97b1205cafc9e866fb63f3524402"
);

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};

const _optionalChain = (ops) => {
  let lastAccessLHS = undefined;
  let value = ops[0];
  let i = 1;
  while (i < ops.length) {
    const op = ops[i];
    const fn = ops[i + 1];
    i += 2;
    if ((op === "optionalAccess" || op === "optionalCall") && value == null) {
      return undefined;
    }
    if (op === "access" || op === "optionalAccess") {
      lastAccessLHS = value;
      value = fn(value);
    } else if (op === "call" || op === "optionalCall") {
      value = fn((...args) => value.call(lastAccessLHS, ...args));
      lastAccessLHS = undefined;
    }
  }
  return value;
};

//get server side data
const getServerSideDatasource = (
  endPoint,
  tableType,
  setFooter,
  filterState,
  filterData
) => {
  let lastFilter = {};
  let lastFilterStatus = true;
  let lastFilterStatusCount = 0;
  return {
    getRows: async (params) => {
      if (
        JSON.stringify(lastFilter) !==
        JSON.stringify(params.request.filterModel)
      ) {
        lastFilter = params.request.filterModel;
        lastFilterStatus = true;
      } else {
        lastFilterStatus = false;
      }

      if (params.request.startRow === 0) {
        lastFilterStatus = true;
      }
      console.log(params.request.filterModel);

      if (Object.keys(filterData).length !== 0) {
        params.request.filterModel = filterData;
      }

      var sDate = getDateFormatYearMonthDay(filterState.startDate);
      var eDate = getDateFormatYearMonthDay(filterState.endDate);
      console.log(
        "[Datasource] - rows requested by grid: ",
        filterState.company
      );
      console.log(params.request);
      console.log(sDate, eDate);
      var response = await axios.post(endPoint, {
        data: {
          ...params.request,
          lastFilterStatus: lastFilterStatus,
          company: filterState.company,
          startDate: sDate,
          endDate: eDate,
          kyn:
            tableTypeList.duzeltmeList === tableType
              ? "D"
              : tableTypeList.virmanList === tableType
              ? "V"
              : "",
        },
      });
      if (lastFilterStatus) {
        setFooter((s) => {
          return {
            ...s,

            [tableType === tableTypeList.axabtaList
              ? "axabtaKayitTtl"
              : tableType === tableTypeList.duzeltmeList
              ? "duzeltmeKayitTtl"
              : tableType === tableTypeList.virmanList
              ? "virmanKayitTtl"
              : tableType === tableTypeList.reelList
              ? "reelKayitTtl"
              : ""]: response.data?.footerTotal[0],
          };
        });
      }

      // adding delay to simulate real server call
      setTimeout(function () {
        if (response.data.success) {
          // const lastRow =
          // response.data.rows.length <= params.request.endRow
          //   ? response.data.rows.length
          //   : -1;
          // call the success callback
          console.log(response.data);
          params.success({
            rowData: response.data.rows,
            rowCount:
              response.data.lastRow === null ? 0 : response.data.lastRow,
          });
        } else {
          // inform the grid request failed
          params.fail();
        }
      }, 1000);
    },
  };
};

const GridExample = ({
  endPoint,
  tableType,
  title,
  date,
  button_name,
  filterDisable = true,
  filterData = {},
}) => {
  console.log(filterData);
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "80%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const setFooterTotalState = useSetRecoilState(footerTotalAtom);
  const getMainFilterState = useRecoilValue(mainFilterAtom);
  const setVirmanSelectState = useSetRecoilState(virmanSelectAtom);

  const [columnDefs, setColumnDefs] = useState(
    mizanColumn(tableType, filterDisable)
  );

  function onSelectionChanged() {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setVirmanSelectState(selectedRows);
  }

  //custom aggregate function
  const aggFuncs = {
    //for group
    // mySum: (params) => {
    //   let sum = 0;
    //   params.values.forEach((value) => (sum += value));
    //   return Math.round(sum * 100) / 100;
    // },
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 80,
      resizable: true,
      floatingFilter: filterDisable ? true : false,
      filter: true,
      sortable: true,
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
      // supplies 'country' values to the filter
      filterValueGetter: (params) =>
        _optionalChain([
          params,
          "access",
          (_) => _.data,
          "optionalAccess",
          (_2) => _2.hesapKodu,
        ]),
    };
  }, []);

  // optional as 'singleColumn' is the default group display type
  const groupDisplayType = "singleColumn";

  // const onGridReady = useCallback((params) => {
  //   setRowData(tableData);
  // }, []);

  const onGridReady = useCallback((params) => {
    // create datasource with a reference to the fake server
    var datasource = getServerSideDatasource(
      endPoint,
      tableType,
      setFooterTotalState,
      getMainFilterState,
      filterData
    );
    // register the datasource with the grid
    params.api.setServerSideDatasource(datasource);
  }, []);

  const rowClassRules = useMemo(() => {
    return {
      // row style function
      amendmentEntry: (params) => {
        //console.log(params.data?.kyn);
        return params.data?.kyn === "D";
      },
      transferEntry: (params) => {
        //console.log(params.data?.kyn);
        return params.data?.kyn === "V";
      },
    };
  }, []);

  return (
    <div style={{ height: "350px" }}>
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">
          <SuiBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <SuiTypography
              variant="h5"
              textTransform="capitalize"
              fontWeight="bold"
              color="dark"
            >
              <FormattedMessage id={title} />
            </SuiTypography>
            {/**
             * TODO : onclick event inside suidatepicker component must be callback function
             */}
            <SuiBox display="flex">
              {/**
               * If there is a date, then we show the date picker, otherwise we show only the button
               * If we give button_name as "", then we don't show the button
               * otherwise we show the button with the given name
               */}
              {date ? (
                <SuiBox mr={2}>
                  <SuiDatePicker label="date" button_name={button_name} />{" "}
                </SuiBox>
              ) : (
                button_name !== "" && (
                  <SuiButton variant="gradient" color="dark">
                    <FormattedMessage id={button_name} />
                  </SuiButton>
                )
              )}
            </SuiBox>
          </SuiBox>
          <AgGridReact
            ref={gridRef}
            aggFuncs={aggFuncs}
            // rowData={rowData}
            // columnDefs={columnDefs}
            // defaultColDef={defaultColDef}
            // autoGroupColumnDef={autoGroupColumnDef}
            // groupDisplayType={groupDisplayType}
            // animateRows={true}
            // onGridReady={onGridReady}
            // rowModelType={"serverSide"}
            // serverSideInfiniteScroll={true}
            // suppressAggFuncInHeader={true}
            // cacheBlockSize={5}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowClassRules={rowClassRules}
            rowModelType={"serverSide"}
            serverSideInfiniteScroll={true}
            onGridReady={onGridReady}
            //checkbox selection
            rowSelection="multiple"
            onSelectionChanged={onSelectionChanged}
            suppressRowClickSelection
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

/**
 * default values
 */
GridExample.defaultProps = {
  endPoint: "",
  tableType: "",
  title: "title",
  button_name: "",
};

export default GridExample;
