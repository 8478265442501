import moment from "moment";
import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";

// react-router components
import { useNavigate } from "react-router-dom";

//language
import { FormattedMessage } from "react-intl";

// @mui material components
import Icon from "@mui/material/Icon";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//state
import { mainFilterAtom, activeTabAtom, isLoadingAtom } from "recoil/atoms";
import { useRecoilState, useSetRecoilState } from "recoil";

// Soft UI component
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiDatePicker from "components/SuiDatePicker";
import SuiSelect from "components/SuiSelect";

//utils
import { getFixedNumber } from "utils/helpers/avs";
//localization
import { FormattedNumber, useIntl } from "react-intl";
// utils
import { v4 as uuidv4 } from "uuid";
import { getFisNo } from "utils/helpers/avs/index";
import { getDateFormatCurrencyRate } from "utils/helpers/dateFormat/index";
import { getCurrencyListForSelect } from "utils/constants/currency";
import { recordTtype } from "utils/constants/recordType";
import { hesabTipi } from "utils/constants/other";

//service
import axios from "services/axios/index";
import {
  endPointMizan,
  getEndPointCurrencyRate,
  getEndPointCurrencyRateSgd,
} from "services/axios/endpoint";

//actions
import { useTableActions } from "actions/tableAction";
import AvsSwal from "components/avs/AvsSwal";
import SwalTypes from "components/avs/AvsSwal/types";

function AvsDataEntryOneItem({ isVirman, isTransferBalance }) {
  const intl = useIntl();
  // dynamic row state
  const [rows, setRows] = useState([]);
  // dynamic row total state
  const [rowsTotal, setRowsTotal] = useState({
    tutarUsd: 0,
    tutar: 0,
    borcTry: 0,
    alacakTry: 0,
    tutarTry: 0,
    borcUsd: 0,
    alacakUsd: 0,
    tutarUsd: 0,
  });
  const { formatMessage } = useIntl();
  //global state
  const setIsLoading = useSetRecoilState(isLoadingAtom);
  const [activeTab, setActiveTab] = useRecoilState(activeTabAtom);
  const [mainFilterState, setMainFilterState] = useRecoilState(mainFilterAtom);

  // date state
  const [startDate, setStartDate] = useState(mainFilterState?.startDate);

  //Hesab kodu list
  const [hesabKoduList, setHesabKoduList] = useState([]);
  const [hesabKoduListSelect, setHesabKoduListSelect] = useState([]);

  //currency rate
  const [currency, setCurrency] = useState();
  const [currencyRate, setCurrencyRate] = useState(0);
  const [customCurrencyRate, setCustomCurrencyRate] = useState(0);
  const [crossRate, setCrossRate] = useState(0);
  const [currencyRateUsd, setCurrencyRateUsd] = useState(0);

  //fishno
  const [fishNo, setFishNo] = useState("");
  const [dataArea, setDateArea] = useState("");

  //use table action
  const tableAction = useTableActions();

  //navigate
  const navigate = useNavigate();

  //use effect working when row date and currency type change
  useEffect(() => {
    // get currency rate from api
    async function fetchDataCurrencyRate() {
      setIsLoading(true);
      const formatedRateDate = moment(startDate)
        .subtract(1, "days")
        .format("DD/MM/YYYY");

      const endPoint = await getEndPointCurrencyRate(
        formatedRateDate,
        currency === "TRY" ? "USD" : currency
      );

      //currency sgd date for rate
      const sgdDate = moment(startDate).format("YYYY-MM-DD");
      //sgd url for rate
      const endPointSgd = await getEndPointCurrencyRateSgd(sgdDate);
      let request;
      try {
        request =
          currency === "SGD"
            ? await fetch(endPointSgd).then((res) => res.json())
            : await axios.get(endPoint);

        if (currency === "TRY") {
          // must usd rate for db usd currency when we selected TRY
          setCurrencyRateUsd(request.data.data.BanknoteSelling);
          setCurrencyRate(request.data.data.BanknoteSelling);
          setCrossRate(request.data.data.CrossRateOther);
        } else if (currency === "SGD") {
          setCurrencyRate(request.rates[sgdDate].TRY);
          setCrossRate(request.rates[sgdDate].USD);
        } else {
          setCurrencyRate(request.data.data.BanknoteSelling);
          setCrossRate(request.data.data.CrossRateOther);
          console.log(request.data.data.CrossRateOther);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        AvsSwal({
          data: {
            type: SwalTypes.error,
            intl,
            props: { title: "error_title", text: error.response.data.message },
          },
        });
      }
    }
    if (currency !== undefined) {
      fetchDataCurrencyRate();
    } else {
      const newRows = [...rows];
      newRows.map((item) => {
        item.tarih = startDate;
      });
      setRows(newRows);
      setIsLoading(false);
    }
  }, [currency, startDate]);

  //this working when currency rate change (generally working manunel rate enter)
  useEffect(() => {
    const newRows = [...rows];
    newRows.map((item) => {
      let tryNum, usdNum;
      let initVal = item.tutar;

      if (currency === "TRY") {
        tryNum = parseFloat(initVal);
        usdNum = parseFloat(initVal) / currencyRateUsd;
      } else if (currency === "USD") {
        tryNum = parseFloat(initVal) * currencyRate;
        usdNum = parseFloat(initVal);
      } else {
        tryNum = parseFloat(initVal) * currencyRate;
        usdNum = parseFloat(initVal) * crossRate;
      }
      const borcTryResult = initVal > 0 ? tryNum : 0;
      item.borcTry = getFixedNumber(borcTryResult);

      const alacakTryResult = initVal > 0 ? 0 : -1 * tryNum;
      item.alacakTry = getFixedNumber(alacakTryResult);

      const borcUsdResult = initVal > 0 ? usdNum : 0;
      item.borcUsd = getFixedNumber(borcUsdResult);

      const alacakUsdResult = initVal > 0 ? usdNum : 0;
      item.alacakUsd = getFixedNumber(alacakUsdResult);
      item.tutarTry = getFixedNumber(tryNum);
      item.tutarUsd = getFixedNumber(usdNum);
      item.tarih = startDate;
    });
    setRows(newRows);
  }, [currencyRate]);

  //one to many currency rate change
  useEffect(() => {
    let newArr = rows.map((item) => {
      return { ...item, kur: currencyRate };
    });

    setRows(newArr);
  }, [currencyRate]);

  //customCurrency change
  useEffect(() => {
    const newRows = [...rows];
    newRows.map(async (item) => {
      let tryNum, usdNum;
      let initVal = item.tutar;
      // }
      const newCrossRate = (crossRate * customCurrencyRate) / currencyRate;

      if (currency === "TRY") {
        tryNum = parseFloat(initVal);
        usdNum = parseFloat(initVal) / customCurrencyRate;
      } else if (currency === "USD") {
        tryNum = parseFloat(initVal) * customCurrencyRate;
        usdNum = parseFloat(initVal);
      } else {
        tryNum = parseFloat(initVal) * customCurrencyRate;
        usdNum = parseFloat(initVal) * newCrossRate;
      }

      const borcTryResult = initVal > 0 ? tryNum : 0;
      item.borcTry = getFixedNumber(borcTryResult);

      const alacakTryResult = initVal > 0 ? 0 : -1 * tryNum;
      item.alacakTry = getFixedNumber(alacakTryResult);

      const borcUsdResult = initVal > 0 ? usdNum : 0;
      item.borcUsd = getFixedNumber(borcUsdResult);

      const alacakUsdResult = initVal > 0 ? 0 : -1 * usdNum;
      item.alacakUsd = getFixedNumber(alacakUsdResult);

      item.tutarTry = getFixedNumber(tryNum);
      item.tutarUsd = getFixedNumber(usdNum);

      // item.kur = getFixedNumber(customCurrencyRate);
      item.kur = customCurrencyRate;
    });
    setRows(newRows);
  }, [customCurrencyRate]);

  //create fishNo
  useEffect(() => {
    if (isTransferBalance) {
      const check = moment(startDate, "YYYY/MM/DD");

      var year = check.format("YYYY");

      const transferBalanceFisNo = `AAA${year}`;
      setFishNo(transferBalanceFisNo);
    } else {
      //fishno
      const fNo = getFisNo();
      setFishNo(fNo);
    }

    setDateArea(mainFilterState?.company);
  }, []);

  useEffect(() => {
    setRows(
      [...rows].map((object) => {
        return {
          ...object,
          fis: fishNo,
        };
      })
    );
  }, [fishNo]);

  // get mizan list from api
  useEffect(() => {
    async function fetchDataMizanAciklama() {
      const request = await axios.get(endPointMizan.mainAccount);
      let selectHesabKodu = [];

      request.data.data.map((item) => {
        selectHesabKodu.push({
          value: item.value,
          label: `${item.value} | ${item.label}`,
          /*
           * user can select only 11 charachter account for example 120-001-002
           * user can not select main account for example  120,  120-001
           */
          disabled: item.value.length < 11 ? true : false,
        });
      });
      setHesabKoduListSelect(selectHesabKodu);
      setHesabKoduList(request.data.data);
      return request;
    }
    fetchDataMizanAciklama();
  }, []);

  // input change and update to row state
  const handleChange = (id) => (e) => {
    const { name, value } = e.target;
    const newRows = [...rows];
    const index = newRows.findIndex((o) => o.id === id);
    //console.log(value);
    newRows[index] = {
      ...newRows[index],
      [name]: value,
    };

    let tryNum, usdNum;
    //update tutarTry and TutarUsd
    if (name === "tutar") {
      if (currency === "TRY") {
        tryNum = parseFloat(value);
        usdNum = parseFloat(value) / currencyRate;
      } else if (currency === "USD") {
        tryNum = parseFloat(value) * currencyRate;
        usdNum = parseFloat(value);
      } else {
        tryNum = parseFloat(value) * currencyRate;
        usdNum = parseFloat(value) * crossRate;
      }

      const borcTryResult = value > 0 ? tryNum : 0;
      const alacakTryResult = value > 0 ? 0 : -1 * tryNum;
      const borcUsdResult = value > 0 ? usdNum : 0;
      const alacakUsdResult = value > 0 ? 0 : -1 * usdNum;

      newRows[index] = {
        ...newRows[index],
        ["borcTry"]: getFixedNumber(borcTryResult),
        ["alacakTry"]: getFixedNumber(alacakTryResult),
        ["borcUsd"]: getFixedNumber(borcUsdResult),
        ["alacakUsd"]: getFixedNumber(alacakUsdResult),
        ["tutarTry"]: getFixedNumber(tryNum),
        ["tutarUsd"]: getFixedNumber(usdNum),
      };
    }

    if (name === "kur") {
      const floatCustomCurrency = parseFloat(value);
      setCustomCurrencyRate(value);
    } else {
      setRows(newRows);
    }
  };

  //will be change all of tarih  when one row tarih change
  const handleTarih = (newDate, id) => {
    setStartDate(newDate[0]);
  };

  //select get data when change
  const handleSelect = (value, idx, selectType) => {
    const newRows = [...rows];

    if (selectType === "hesapKodu") {
      const arr = value.label.split("|");
      newRows[idx] = {
        ...newRows[idx],
        ["hesapKodu"]: arr[0].trim(),
        ["name"]: arr[1].trim(),
      };
    }
    // else if (selectType === "currency") {
    //   // newRows.forEach((item) => {
    //   //   item[selectType] = value;
    //   // });

    // }
    else {
      newRows[idx] = {
        ...newRows[idx],
        [selectType]: value,
      };
    }

    if (selectType === "currency") {
      setCurrency(value);
    }

    setRows(newRows);
  };

  //generate row
  const generateRow = () => {
    console.log("generate row");
    const item = {
      id: uuidv4(),
      tarih: startDate,
      //gunlukNo  create in back end
      fis: fishNo,
      kyn: isVirman ? recordTtype.virman : recordTtype.duzeltme,
      hesapTipi: hesabTipi.genelMuhasebe,
      hesapKodu: "",
      name: "",
      txt: "",
      currency: currency,
      tutar: 0,
      borcUsd: 0,
      alacakUsd: 0,
      tutarUsd: 0,
      borcTry: 0,
      alacakTry: 0,
      tutarTry: 0,
      kur: currencyRate && currencyRate.BanknoteBuying,
      dataAreaId: mainFilterState?.company,
      isOpening: isTransferBalance ? true : false,
      createdBy: null,
    };
    return item;
  };

  //when currency rate fetch and company change
  useEffect(() => {
    setRows([]);
  }, [mainFilterState.company]);

  // add new row
  useEffect(() => {
    if (rows.length == 0) {
      console.log("rows.length == 0");
      const addRow = async () => {
        const item1 = await generateRow();

        setRows([item1]);
      };
      addRow();
    }
  }, [rows]);

  //add row to list
  const handleAddRow = () => {
    const addRow = async () => {
      const item = await generateRow();
      setRows([...rows, item]);
    };
    addRow();
  };

  // total row for some column
  const handleRowTotal = useCallback(() => {
    const totalTutar = rows.reduce((n, { tutar }) => {
      return parseFloat(n) + parseFloat(tutar);
    }, 0);

    const totalBorcTry = rows.reduce((n, { borcTry }) => {
      return parseFloat(n) + parseFloat(borcTry);
    }, 0);

    const totalAlacakTry = rows.reduce((n, { alacakTry }) => {
      return parseFloat(n) + parseFloat(alacakTry);
    }, 0);

    const totalTutarTry = rows.reduce((n, { tutarTry }) => {
      return parseFloat(n) + parseFloat(tutarTry);
    }, 0);

    const totalBorcUsd = rows.reduce((n, { borcUsd }) => {
      return parseFloat(n) + parseFloat(borcUsd);
    }, 0);

    const totalAlacakUsd = rows.reduce((n, { alacakUsd }) => {
      return parseFloat(n) + parseFloat(alacakUsd);
    }, 0);

    const totalTutarUsd = rows.reduce((n, { tutarUsd }) => {
      return parseFloat(n) + parseFloat(tutarUsd);
    }, 0);
    setRowsTotal({
      ...rowsTotal,
      tutar: totalTutar,
      borcTry: totalBorcTry,
      alacakTry: totalAlacakTry,
      tutarTry: totalTutarTry,
      borcUsd: totalBorcUsd,
      alacakUsd: totalAlacakUsd,
      tutarUsd: totalTutarUsd,
    });
  }, [rows]);

  //total change when rows data change
  useEffect(() => {
    handleRowTotal();
  }, [rows]);

  //remowe speasifik row
  const handleRemoveSpecificRow = (id) => () => {
    const newRows = [...rows];

    const index = newRows.findIndex((o) => o.id === id);
    if (index !== -1) newRows.splice(index, 1);
    setRows(newRows);
  };

  //data entry submit
  const handleSubmit = async () => {
    var customValidation = await validatorForm();

    //form valdiator
    if (customValidation.status) {
      return AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_form_validator",
            text: customValidation.msg,
          },
        },
      });
    }

    //total checking
    // if (
    //   rowsTotal.tutar === 0 &&
    //   rowsTotal.tutarUsd === 0 &&
    //   rowsTotal.tutarTry === 0 &&
    //   rowsTotal.alacakTry === 0 &&
    //   rowsTotal.borcTry === 0 &&
    //   rowsTotal.alacakUsd === 0 &&
    //   rowsTotal.borcUsd === 0
    // ) {
    //   return AvsSwal({
    //     data: {
    //       type: SwalTypes.error,
    //       intl,
    //       props: {
    //         title: "error_bad_job",
    //         text: "error_check_subtotal",
    //       },
    //     },
    //   });
    // }

    //total checking
    // if (
    //   rowsTotal.tutar !== 0 ||
    //   rowsTotal.tutarUsd !== 0 ||
    //   rowsTotal.tutarTry !== 0
    // ) {
    //   return AvsSwal({
    //     data: {
    //       type: SwalTypes.error,
    //       intl,
    //       props: {
    //         title: "error_bad_job",
    //         text: "error_check_subtotal",
    //       },
    //     },
    //   });
    // }
    AvsSwal({
      data: {
        type: SwalTypes.avsdataentry,
        intl,
        props: {
          mainFilterState,
          rows,
          endPointMizan,
          getFisNo,
          setFishNo,
          setRows,
          activeTab,
          setActiveTab,
          fishNo,
          isTransferBalance: true,
        },
      },
    });
  };

  //manuel validator form
  const validatorForm = () => {
    var msg = "",
      isError = false;
    rows.map((row, i) => {
      if (row.hesapKodu === "") {
        msg += `\nItem ${i + 1} :Hesab Kodu is required!`;
        isError = true;
      }

      if (row.name === "") {
        msg += `\nItem ${i + 1} :Hesab Adi is required!`;
        isError = true;
      }

      if (row.currency === "") {
        msg += `\nItem ${i + 1} :Currency Type is required!`;
        isError = true;
      }
    });
    return {
      msg: msg,
      status: isError,
    };
  };

  const handleCancel = () => {
    AvsSwal({
      data: {
        type: SwalTypes.cancelQ,
        intl,
        props: {
          text: "virman_record",
          navigateTo: "mizan",
          navigate,
        },
      },
    });
  };

  return (
    <SuiBox mt={1} bgColor="#fff">
      <Table aria-label="simple table">
        <TableHead style={{ display: "table-header-group" }}>
          <TableRow>
            <TableCell
              width="50px"
              style={{
                padding: "2px",
                fontSize: "12px",
              }}
            >
              <FormattedMessage id="date" />
            </TableCell>
            {/* <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Günlük No
            </TableCell> */}
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              <FormattedMessage id="voucher_no" />
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              <FormattedMessage id="account_type" />
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              <FormattedMessage id="account_code_name" />
            </TableCell>
            {/* <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Hesap Adı
            </TableCell> */}
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              <FormattedMessage id="description" />
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              <FormattedMessage id="currency_unit_short" />
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              <FormattedMessage id="amount" />
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              <FormattedMessage id="debit_try" />
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              <FormattedMessage id="credit_try" />
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              <FormattedMessage id="amount_try" />
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              <FormattedMessage id="debit_usd" />
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              <FormattedMessage id="credit_usd" />
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              <FormattedMessage id="amount_usd" />
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              <FormattedMessage id="currency_rate_short" />
            </TableCell>
            <TableCell width="40px">
              {/* <SuiBox
                bgColor="success"
                borderRadius="8px"
                width="1.625rem"
                height="1.625rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="white"
                mx="auto"
                variant="gradient"
                sx={{ cursor: "pointer" }}
                onClick={handleAddRow}
              >
                <Icon fontSize="small">add</Icon>
              </SuiBox> */}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((item, idx) => (
            <TableRow
              key={item.id + idx}
              id={idx}
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderBottom: "none",
                },
              }}
            >
              <TableCell
                component="th"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiBox width="100px">
                  <SuiDatePicker
                    name="tarih"
                    value={rows[idx].tarih}
                    onChange={(newDate) => handleTarih(newDate, item.id)}
                  />
                </SuiBox>
              </TableCell>
              {/* <TableCell
                align="right"
                width="140px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="gunlukNo"
                  value={rows[idx].gunlukNo}
                  disabled={true}
                />
              </TableCell> */}
              <TableCell
                align="right"
                width="140px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="fis"
                  value={rows[idx].fis}
                  disabled={true}
                />
              </TableCell>
              <TableCell
                width="130px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiBox bgColor="white">
                  <SuiInput
                    type="text"
                    name="hesapTipi"
                    disabled={true}
                    value={rows[idx].hesapTipi}
                  />
                </SuiBox>
              </TableCell>
              <TableCell
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiSelect
                  options={hesabKoduListSelect}
                  isOptionDisabled={(option) => option.disabled}
                  onChange={(data) => handleSelect(data, idx, "hesapKodu")}
                />
              </TableCell>
              {/* <TableCell
                align="right"
                width="auto"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiSelect
                  options={hesabAdiListSelect}
                  onChange={(data) => handleSelect(data.label, idx, "name")}
                />
              </TableCell> */}
              <TableCell
                align="right"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="txt"
                  onChange={handleChange(item.id)}
                />
              </TableCell>
              <TableCell
                align="right"
                width="40px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                {isTransferBalance ? (
                  <SuiSelect
                    options={getCurrencyListForSelect()}
                    onChange={(data) =>
                      handleSelect(data.value, idx, "currency")
                    }
                  />
                ) : idx === 0 ? (
                  <SuiSelect
                    options={getCurrencyListForSelect()}
                    onChange={(data) =>
                      handleSelect(data.value, idx, "currency")
                    }
                  />
                ) : (
                  <SuiInput
                    type="text"
                    name="currency"
                    disabled={true}
                    value={rows[idx].currency}
                  />
                )}
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="tutar"
                  value={rows[idx].tutar}
                  onChange={handleChange(item.id)}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="borcTry"
                  disabled={true}
                  value={rows[idx].borcTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="alacakTry"
                  disabled={true}
                  value={rows[idx].alacakTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="name"
                  disabled={true}
                  value={rows[idx].tutarTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="borcUsd"
                  disabled={true}
                  value={rows[idx].borcUsd}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="alacakUsd"
                  disabled={true}
                  value={rows[idx].alacakUsd}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="tutarUsd"
                  disabled={true}
                  value={rows[idx].tutarUsd}
                />
              </TableCell>
              <TableCell
                align="right"
                width="70px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="kur"
                  value={rows[idx].kur}
                  onChange={handleChange(item.id)}
                />
              </TableCell>
              <TableCell
                width="40px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                {idx > 0 && (
                  <SuiBox
                    bgColor="error"
                    borderRadius="8px"
                    width="1.625rem"
                    height="1.625rem"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    color="white"
                    mx="auto"
                    variant="gradient"
                    sx={{ cursor: "pointer" }}
                    onClick={handleRemoveSpecificRow(item.id)}
                  >
                    <Icon fontSize="small">delete</Icon>
                  </SuiBox>
                )}
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            style={{
              padding: "0",
            }}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row" width="auto" />
            <TableCell component="th" scope="row" width="auto" />
            <TableCell component="th" scope="row" width="auto" />
            <TableCell component="th" scope="row" width="auto" />
            <TableCell component="th" scope="row" width="auto" />
            <TableCell component="th" scope="row" width="auto" />
            <TableCell component="th" scope="row" width="auto" />
            {/* <TableCell
              align="left"
              width="auto"
              style={{
                padding: "0",
                paddingLeft: "6px",
                color: rowsTotal.tutar !== 0 ? "red" : "green",
              }}
            >
              <FormattedNumber value={rowsTotal.tutar} currency="USD" />
            </TableCell> */}
            <TableCell
              align="left"
              width="auto"
              style={{
                padding: "0",
                paddingLeft: "6px",
                color: rowsTotal.borcTry <= 0 ? "red" : "green",
              }}
            >
              <FormattedNumber value={rowsTotal.borcTry} currency="USD" />
            </TableCell>
            <TableCell
              align="left"
              width="auto"
              style={{
                padding: "0",
                paddingLeft: "6px",
                color: rowsTotal.alacakTry <= 0 ? "red" : "green",
              }}
            >
              <FormattedNumber value={rowsTotal.alacakTry} currency="USD" />
            </TableCell>
            <TableCell
              align="left"
              width="auto"
              style={{
                padding: "0",
                paddingLeft: "6px",
                color: rowsTotal.tutarTry === 0 ? "red" : "green",
              }}
            >
              <FormattedNumber value={rowsTotal.tutarTry} currency="USD" />
            </TableCell>
            <TableCell
              align="left"
              width="auto"
              style={{
                padding: "0",
                paddingLeft: "6px",
                color: rowsTotal.borcUsd <= 0 ? "red" : "green",
              }}
            >
              <FormattedNumber value={rowsTotal.borcUsd} currency="USD" />
            </TableCell>
            <TableCell
              align="left"
              width="auto"
              style={{
                padding: "0",
                paddingLeft: "6px",
                color: rowsTotal.alacakUsd <= 0 ? "red" : "green",
              }}
            >
              <FormattedNumber value={rowsTotal.alacakUsd} currency="USD" />
            </TableCell>
            <TableCell
              align="left"
              width="auto"
              style={{
                padding: "0",
                paddingLeft: "6px",
                color: rowsTotal.tutarUsd === 0 ? "red" : "green",
              }}
            >
              <FormattedNumber value={rowsTotal.tutarUsd} currency="USD" />
            </TableCell>
            <TableCell width="50px" />
          </TableRow>
        </TableBody>
      </Table>
      <SuiBox display="flex" justifyContent="center" pb={2}>
        <SuiBox mr={1}>
          <SuiButton variant="gradient" color="dark" onClick={handleSubmit}>
            <FormattedMessage id="confirm" />
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

// AvsDataEntry default values for the props of AvsDataEntry
AvsDataEntryOneItem.defaultProps = {
  isVirman: false,
  isTransferBalance: false,
};

// Typechecking props for the AvsDataEntry
AvsDataEntryOneItem.propTypes = {
  isVirman: PropTypes.bool,
  isTransferBalance: PropTypes.bool,
};

export default AvsDataEntryOneItem;
