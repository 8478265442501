/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DefaultNavbar from "components/Navbars/DefaultNavbar";

// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "page.routes";

// Images
import waves from "assets/images/shapes/waves-white.svg";

function Header({ tabValue, tabHandler }) {
  return (
    <>
      <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
          label: "buy now",
          color: "dark",
        }}
        transparent
        light
      />
      <SuiBox
        position="relative"
        height="50vh"
        borderRadius="xl"
        overflow="hidden"
        bgColor="info"
        variant="gradient"
        m={2}
        pt={12}
        pb={20}
      >
        <SuiBox
          component="img"
          src={waves}
          alt="waves"
          width="105rem"
          position="absolute"
          top={0}
        />
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ textAlign: "center" }}
        >
          <Grid item xs={10} lg={5}>
            <SuiBox mb={1}>
              <SuiTypography variant="h3" color="white" fontWeight="bold">
                See our pricing
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={2}>
              <SuiTypography variant="body2" color="white" fontWeight="regular">
                You have Free Unlimited Updates and Premium Support on each
                package.
              </SuiTypography>
            </SuiBox>
            <Grid
              container
              item
              xs={12}
              sm={10}
              md={8}
              lg={7}
              sx={{ mx: "auto" }}
            >
              <SuiBox width="100%" mt={6}>
                <AppBar position="static">
                  <Tabs value={tabValue} onChange={tabHandler}>
                    <Tab
                      id="monthly"
                      label={
                        <SuiBox py={0.5} px={2}>
                          Monthly
                        </SuiBox>
                      }
                    />
                    <Tab
                      id="annual"
                      label={
                        <SuiBox py={0.5} px={2}>
                          Annual
                        </SuiBox>
                      }
                    />
                  </Tabs>
                </AppBar>
              </SuiBox>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
}

// Typechecking props for the Header
Header.propTypes = {
  tabValue: PropTypes.number.isRequired,
  tabHandler: PropTypes.func.isRequired,
};

export default Header;
