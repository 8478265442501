/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import React from "react";
import yup from "yup";
import { FormattedMessage } from "react-intl";


function FormField({ label, name, ...rest }) {
  return (
    <>
      {label && (
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            <FormattedMessage id={label} />
          </SuiTypography>
        </SuiBox>
      )}
      <Field {...rest} name={name} as={SuiInput} />
      <SuiBox mt={0.75}>
        <SuiTypography component="div" variant="caption" color="error">
          <ErrorMessage render={msg => <div>{msg}</div>} name={name} />
        </SuiTypography>
      </SuiBox>
    </>
  );
}

FormField.defaultProps = {};

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default React.memo(FormField);
