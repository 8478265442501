import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_NODE_ENV === "production"
      ? process.env.REACT_APP_BASE_URL_PROD
      : process.env.REACT_APP_BASE_URL_DEV_TEST,

  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
  },
});

export default instance;
