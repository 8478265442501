import React from "react";
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI component
import AVSDefaultInfoCard from "components/Cards/AVSFinanceCard/AVSDefaultInfoCard";
import SuiBox from "components/SuiBox";

function MainMenu() {
  return (
    <SuiBox style={{ minHeight: "calc(100vh - 170px)" }}>
      <SuiBox display="flex" justifyContent="center" p={10}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          rowSpacing={10}
          style={{ maxWidth: "1000px" }}
        >
          <Grid item xs={12} sm={4} md={4}>
            <AVSDefaultInfoCard
              icon="account_balance"
              titleFirstLine="credit"
              titleSecondLine="debit"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <AVSDefaultInfoCard
              icon="account_balance"
              titleFirstLine="sales"
              titleSecondLine="purchase"
              href="/sales"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <AVSDefaultInfoCard
              icon="account_balance"
              titleFirstLine="trial_balance_balance_sheet"
              titleSecondLine="income_statement"
              href="/mizan"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <AVSDefaultInfoCard
              icon="account_balance"
              titleFirstLine="receiving"
              titleSecondLine="payment"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <AVSDefaultInfoCard
              icon="account_balance"
              titleFirstLine="cash"
              titleSecondLine="bank"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <AVSDefaultInfoCard
              icon="account_balance"
              titleFirstLine="payment_plan_1"
              titleSecondLine="payment_plan_2"
            />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default MainMenu;
