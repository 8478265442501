import React, { useState, useEffect, createRef } from "react";
// react-router components
import { useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// Soft UI component
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiDatePicker from "components/SuiDatePicker";

// Avs UI component
import AvsBreadcrumbs from "components/avs/AvsBreadcrumbs";
import GridExample from "components/avs/AvsAgGrid/ServerSide";
import AvsDataTable from "components/avs/AvsDataTable";

// utils
import { getCurrencyListForSelect } from "utils/constants/currency";
import { getFisNo } from "utils/helpers/avs/index";
import { recordTtype, tableTypeList } from "utils/constants/recordType";

//service
import axios from "services/axios/index";
import { endPointMizan } from "services/axios/endpoint";

// translation
import { useIntl } from "react-intl";
//global state
import {
  mainFilterAtom,
  reelKayitAtom,
  virmanSelectAtom,
  isLoadingAtom,
} from "recoil/atoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import AvsSwal from "components/avs/AvsSwal";
import SwalTypes from "components/avs/AvsSwal/types";

function VirmanMutiAdd() {
  const breadcrumbRoutes = [
    {
      route: "mizan",
      label: "trial_balance",
    },
    {
      route: "menu",
      label: "finance",
    },
  ];
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const intl = useIntl();
  //global state
  const setIsLoading = useSetRecoilState(isLoadingAtom);
  const [mainFilterState, setMainFilterState] = useRecoilState(mainFilterAtom);
  const [reelKayit, setReelnKayit] = useRecoilState(reelKayitAtom);
  const [virmanSelectState, setVirmanSelectState] =
    useRecoilState(virmanSelectAtom);

  // date state
  //var sDate = moment(mainFilterState.startDate).format("MM-DD-YYYY");
  const [startDate, setStartDate] = useState(mainFilterState.startDate);

  //fishno
  const [fishNo, setFishNo] = useState("");
  const [cikanHesabSearch, setCikanHesabSearch] = useState("");
  const [cikanHesab, setCikanHesab] = useState("");
  const [girenHesab, setGirenHesab] = useState("");
  const [currency, setCurrency] = useState("");

  // switch state
  const [visible, setVisible] = useState(true);
  const item = {
    tarih: startDate,
    //gunlukNo  create in back end
    fis: fishNo,
    isCredit: false,
    kyn: recordTtype.virman,
    hesapTipi: "",
    hesapKodu: "",
    name: "",
    txt: "",
    currency: currency,
    tutar: 0,
    borcUsd: 0,
    alacakUsd: 0,
    tutarUsd: 0,
    borcTry: 0,
    alacakTry: 0,
    tutarTry: 0,
    kur: 0,
    dataAreaId: mainFilterState?.company,
    createdBy: null,
  };

  //for scrool bottom
  const gridTableEndRef = createRef();
  const scrollToBottom = () => {
    gridTableEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  //record
  const [cikanKayit, setCikanKayit] = useState(item);
  const [girenKayit, setGirenKayit] = useState(item);

  //Hesab kodu list
  const [hesabTypeList, setHesabTypeList] = useState([]);
  const [hesabKoduList, setHesabKoduList] = useState([]);
  const [hesabKoduListSelect, setHesabKoduListSelect] = useState([]);

  //tableData
  const [reelAxabta, setReelAxabta] = useState([]);

  //filter data
  const [filterData, setFilterData] = useState([]);

  //totals
  const [totaGirenKayit, setTotaGirenKayit] = useState({
    borc: 0,
    alacak: 0,
    tutar: 0,
    borcTry: 0,
    alacakTry: 0,
    tutarTry: 0,
    borcUsd: 0,
    alacakUsd: 0,
    tutarUsd: 0,
    kur: 0,
  });

  useEffect(() => {
    setGirenKayit((prevState) => ({
      ...girenKayit,
      tarih: startDate,
    }));
    setCikanKayit((prevState) => ({
      ...prevState,
      tarih: startDate,
    }));
  }, [startDate]);

  useEffect(() => {
    async function getNo() {
      //fishno
      const fNo = await getFisNo();
      setFishNo(fNo);
      setGirenKayit({
        ...girenKayit,
        fis: fNo,
        isCredit: false,
      });
      setCikanKayit({
        ...cikanKayit,
        fis: fNo,
        isCredit: true,
      });
    }
    getNo();
  }, []);

  const handleSetVisible = () => setVisible(!visible);

  // useEffect(() => {
  //   if (visible) {
  //     setCurrency("");
  //   }
  // }, [visible]);

  useEffect(() => {
    if (cikanHesabSearch !== "" && currency !== "") {
      setIsLoading(true);
      setVisible(false);
      setFilterData({
        currency: {
          filterType: "text",
          type: "contains",
          filter: currency,
        },
        hesapKodu: {
          filterType: "text",
          type: "contains",
          filter: cikanHesabSearch,
        },
        kyn: {
          filterType: "text",
          type: "contains",
          filter: "A",
        },
      });
    }
    setTimeout(() => {
      setVisible(true);
      setIsLoading(false);
      scrollToBottom();
    }, 3000);
  }, [cikanHesabSearch, currency]);
  // useEffect(() => {
  //   var data = reelKayit.filter(
  //     (item) =>
  //       item.kyn === "A" &&
  //       item.currency === currency &&
  //       item.hesapKodu.includes(cikanHesabSearch)
  //   );
  //   setReelAxabta(data);
  // }, [reelKayit, cikanHesabSearch, currency]);

  useEffect(() => {
    let borc = 0,
      alacak = 0,
      tutar = 0,
      borcTry = 0,
      alacakTry = 0,
      tutarTry = 0,
      borcUsd = 0,
      alacakUsd = 0,
      tutarUsd = 0,
      kur = 0;
    console.log(virmanSelectState);
    virmanSelectState.forEach((item, index) => {
      var data = item;

      borc += data?.borc;
      alacak += data?.alacak;
      tutar += data?.alacak - data?.borc;
      borcTry += data?.borcTry;
      alacakTry += data?.alacakTry;
      tutarTry += data?.alacakTry - data?.borcTry;
      borcUsd += data?.borcUsd;
      alacakUsd += data?.alacakUsd;
      tutarUsd += data?.alacakUsd - data?.borcUsd;
      kur += data?.kur;
    });
    kur = virmanSelectState.length === 0 ? 0 : kur / virmanSelectState.length;
    setGirenKayit({
      ...girenKayit,
      kur,
      borc,
      alacak,
      borcTry,
      alacakTry,
      borcUsd,
      alacakUsd,
      tutar,
      tutarTry,
      tutarUsd,
    });
    setCikanKayit({
      ...cikanKayit,
      kur,
      borc: alacak,
      alacak: borc,
      alacakTry: borcTry,
      borcTry: alacakTry,
      alacakUsd: borcUsd,
      borcUsd: alacakUsd,
      tutar: tutar * -1,
      tutarTry: tutarTry * -1,
      tutarUsd: tutarUsd * -1,
    });
  }, [virmanSelectState]);

  // get mizan list form api
  useEffect(() => {
    async function fetchDataMizanAciklama() {
      const request = await axios.get(endPointMizan.mainAccount);
      let selectHesabKodu = [],
        selectHesabAdi = [];

      request.data.data.map((item) => {
        selectHesabKodu.push({
          value: item.value,
          label: `${item.value} | ${item.label}`,
          /*
           * user can select only 11 charachter account for example 120-001-002
           * user can not select main account for example  120,  120-001
           */
          disabled: item.value.length < 11 ? true : false,
        });
      });
      setHesabKoduListSelect(selectHesabKodu);
      setHesabKoduList(request.data.data);
      return request;
    }
    fetchDataMizanAciklama();

    async function getAccountType() {
      try {
        const request = await axios.get(endPointMizan.postingType);

        setHesabTypeList([]);
        request.data.data.map((item) => {
          setHesabTypeList((data) => [
            ...data,
            {
              value: item.descriptionTR,
              label: item.descriptionTR,
            },
          ]);
        });
      } catch (err) {
        console.log(err);
      }
    }

    getAccountType();
  }, []);

  const handleChange = (type, e) => {
    var data = e.target.value;
    if (type === "cikan") {
      setCikanKayit({
        ...cikanKayit,
        txt: data,
      });
    } else if (type === "giren") {
      setGirenKayit({
        ...girenKayit,
        txt: data,
      });
    }
  };

  const handleSelectCurrency = (data) => {
    setCurrency(data.value);
    setCikanKayit({
      ...cikanKayit,
      currency: data.value,
      kur: 0,
      borc: 0,
      alacak: 0,
      alacakTry: 0,
      borcTry: 0,
      alacakUsd: 0,
      borcUsd: 0,
      tutar: 0,
      tutarTry: 0,
      tutarUsd: 0,
    });
    setGirenKayit({
      ...girenKayit,
      currency: data.value,
      kur: 0,
      borc: 0,
      alacak: 0,
      alacakTry: 0,
      borcTry: 0,
      alacakUsd: 0,
      borcUsd: 0,
      tutar: 0,
      tutarTry: 0,
      tutarUsd: 0,
    });
  };

  const handleSelectHesapTipi = (type, data) => {
    if (type === "cikan") {
      setCikanKayit({
        ...cikanKayit,
        hesapTipi: data,
      });
    } else if (type === "giren") {
      setGirenKayit({
        ...girenKayit,
        hesapTipi: data,
      });
    }
  };
  const handleSelectHesapKodu = (type, data) => {
    const arr = data.label.split("|");

    if (type === "giren") {
      setGirenHesab(data.label);
      setGirenKayit((prevData) => ({
        ...prevData,
        ["hesapKodu"]: arr[0].trim(),
        ["name"]: arr[1].trim(),
        ["txt"]: `${cikanKayit.hesapKodu} > ${arr[0].trim()} | virman`,
      }));

      setCikanKayit((prevData) => ({
        ...prevData,
        ["txt"]: `${cikanKayit.hesapKodu} > ${arr[0].trim()} | virman`,
      }));
    } else if (type === "cikan") {
      setCikanHesabSearch(arr[0].trim());
      setCikanHesab(data.label);
      setCikanKayit((prevData) => ({
        ...prevData,
        ["hesapKodu"]: arr[0].trim(),
        ["name"]: arr[1].trim(),
        ["txt"]: `${arr[0].trim()} > ${girenKayit.hesapKodu} | virman`,
      }));
      setGirenKayit((prevData) => ({
        ...prevData,
        ["txt"]: `${arr[0].trim()} > ${girenKayit.hesapKodu} | virman`,
      }));
    }
  };

  const handleTarih = (newDate) => {
    console.log(newDate[0]);
    setStartDate(newDate[0]);
  };

  const handleNakil = async () => {
    if (girenKayit.hesapTipi === "" || cikanKayit.hesapTipi === "") {
      return AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_title",
            text: "error_select_account_type",
          },
        },
      });
    }

    if (girenKayit.txt === "" || cikanKayit.txt === "") {
      return AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_title",
            text: "error_enter_record_description",
          },
        },
      });
    }

    if (virmanSelectState.length <= 0) {
      return AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_title",
            text: "error_select_record",
          },
        },
      });
    }

    var sendData = {
      transaction: [cikanKayit, girenKayit],
      selectIdList: virmanSelectState.map((item) => item.id),
    };
    AvsSwal({
      data: {
        type: SwalTypes.createVirmanMultipleEditQ,
        intl,
        props: {
          text: "virman_record",
          postTo: endPointMizan.mizanVirmanMultiSingle,
          sendData,
          navigate,
          setIsLoading,
        },
      },
    });
  };

  return (
    <SuiBox>
      {/* Section Breadcrumb */}
      <AvsBreadcrumbs
        title="create_virement"
        currentRoute="create_virement"
        otherRoutes={breadcrumbRoutes}
      />
      {/* Section Form */}
      <SuiBox display="flex" justifyContent="flex-start" mt={1}>
        <SuiBox display="flex">
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            mr={1}
          >
            <SuiBox display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Fiş No
              </SuiTypography>
            </SuiBox>
            <SuiInput
              value={fishNo}
              disabled
              //   onChange={handeSetFormValue}
            />
          </SuiBox>
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            style={{ width: "300px" }}
            mr={1}
          >
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Çıkan Hesap Kodu
              </SuiTypography>
            </SuiBox>
            <SuiBox
              bgColor="white"
              style={{ position: "relative", zIndex: 100 }}
            >
              {/* <SuiInput onChange={(e) => setCikanHesabSearch(e.target.value)} /> */}
              <SuiSelect
                // defaultValue={{ value: "avs", label: "200-100-100" }}
                options={hesabKoduListSelect}
                onChange={(data) => handleSelectHesapKodu("cikan", data)}
                isOptionDisabled={(option) => option.disabled}
              />
            </SuiBox>
          </SuiBox>
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            mr={1}
            style={{ width: "300px" }}
          >
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Giren Hesap Kodu
              </SuiTypography>
            </SuiBox>
            <SuiBox
              bgColor="white"
              style={{ position: "relative", zIndex: 100 }}
            >
              <SuiSelect
                // defaultValue={{ value: "avs", label: "200-100-100" }}
                options={hesabKoduListSelect}
                onChange={(data) => handleSelectHesapKodu("giren", data)}
                isOptionDisabled={(option) => option.disabled}
              />
            </SuiBox>
          </SuiBox>
          <SuiBox
            width="60px"
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            mr={1}
          >
            <SuiBox display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                PB
              </SuiTypography>
            </SuiBox>
            {/* {visible ? (
              <SuiInput
                disabled
                //   onChange={handeSetFormValue}
              />
            ) : ( */}
            <SuiSelect
              options={getCurrencyListForSelect()}
              onChange={(data) => handleSelectCurrency(data)}
            />
            {/* )} */}
          </SuiBox>
          {/* <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            mr={1}
          >
            <SuiBox display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Çoklu Kayıt
              </SuiTypography>
            </SuiBox>
            <Switch checked={visible} onChange={handleSetVisible} />
          </SuiBox> */}
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
          >
            <SuiButton variant="gradient" color="dark" onClick={handleNakil}>
              Nakil
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>

      <SuiBox pt={1}>
        <SuiTypography
          component="span"
          variant="h6"
          fontWeight="bold"
          color="dark"
          sx={{ lineHeight: 0 }}
        >
          Giren Kayıt
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1}>
        <Table aria-label="simple table">
          <TableHead style={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell
                width="50px"
                style={{
                  padding: "2px",
                  fontSize: "12px",
                }}
              >
                Tarih
              </TableCell>
              {/* <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Günlük No
            </TableCell> */}
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Fiş No
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Hesap Tipi
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Hesap Kodu - Hesap Adı
              </TableCell>
              {/* <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Hesap Adı
            </TableCell> */}
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Açıklama
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                PB
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Tutar
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                BorcTRY
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Alacak TRY
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Tutar TRY
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Borc USD
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Alacak USD
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Tutar USD
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Kur
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderBottom: "none",
                },
              }}
            >
              <TableCell
                component="th"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiBox width="100px">
                  <SuiDatePicker
                    name="tarih"
                    value={startDate}
                    onChange={(newDate) => handleTarih(newDate)}
                  />
                </SuiBox>
              </TableCell>
              <TableCell
                align="right"
                width="140px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="fis"
                  value={fishNo}
                  disabled={true}
                />
              </TableCell>
              <TableCell
                width="130px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiBox bgColor="white">
                  <SuiSelect
                    options={hesabTypeList}
                    onChange={(data) =>
                      handleSelectHesapTipi("giren", data.value)
                    }
                  />
                </SuiBox>
              </TableCell>
              <TableCell
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="girenHesab"
                  value={girenHesab}
                  disabled={true}
                />
              </TableCell>
              <TableCell
                align="right"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  value={girenKayit.txt}
                  onChange={(e) => handleChange("giren", e)}
                />
              </TableCell>
              <TableCell
                align="right"
                width="60px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                {/* <SuiInput
                  type="text"
                  name="pb"
                  onChange={handleChange(item.pb)}
                  
                /> */}
                <SuiInput
                  type="text"
                  name="currency"
                  disabled={true}
                  value={girenKayit.currency}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="tutar"
                  disabled={true}
                  value={girenKayit.tutar}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="borcTry"
                  disabled={true}
                  value={girenKayit.borcTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="alacakTry"
                  disabled={true}
                  value={girenKayit.alacakTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="name"
                  disabled={true}
                  value={girenKayit.tutarTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="borcUsd"
                  disabled={true}
                  value={girenKayit.borcUsd}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="alacakUsd"
                  disabled={true}
                  value={girenKayit.alacakUsd}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="tutarUsd"
                  disabled={true}
                  value={girenKayit.tutarUsd}
                />
              </TableCell>
              <TableCell
                align="right"
                width="70px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="kur"
                  disabled={true}
                  value={girenKayit.kur}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </SuiBox>

      <SuiBox pt={1}>
        <SuiTypography
          component="span"
          variant="h6"
          fontWeight="bold"
          color="dark"
          sx={{ lineHeight: 0 }}
        >
          Çıkan Kayıt
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1}>
        <Table aria-label="simple table">
          <TableHead style={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell
                width="50px"
                style={{
                  padding: "2px",
                  fontSize: "12px",
                }}
              >
                Tarih
              </TableCell>
              {/* <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Günlük No
            </TableCell> */}
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Fiş No
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Hesap Tipi
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Hesap Kodu - Hesap Adı
              </TableCell>
              {/* <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Hesap Adı
            </TableCell> */}
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Açıklama
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                PB
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Tutar
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                BorcTRY
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Alacak TRY
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Tutar TRY
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Borc USD
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Alacak USD
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Tutar USD
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Kur
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderBottom: "none",
                },
              }}
            >
              <TableCell
                component="th"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiBox width="100px">
                  <SuiDatePicker
                    name="tarih"
                    value={startDate}
                    onChange={(newDate) => handleTarih(newDate)}
                  />
                </SuiBox>
              </TableCell>
              <TableCell
                align="right"
                width="140px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="fis"
                  value={fishNo}
                  disabled={true}
                />
              </TableCell>
              <TableCell
                width="130px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiBox bgColor="white">
                  <SuiSelect
                    options={hesabTypeList}
                    onChange={(data) =>
                      handleSelectHesapTipi("cikan", data.value)
                    }
                  />
                </SuiBox>
              </TableCell>
              <TableCell
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="cikanHesab"
                  value={cikanHesab}
                  disabled={true}
                />
              </TableCell>
              <TableCell
                align="right"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="txt"
                  value={cikanKayit.txt}
                  onChange={(e) => handleChange("cikan", e)}
                />
              </TableCell>
              <TableCell
                align="right"
                width="60px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                {/* <SuiInput
                  type="text"
                  name="pb"
                  onChange={handleChange(item.pb)}
                  
                /> */}
                <SuiInput
                  type="text"
                  name="currency"
                  disabled={true}
                  value={cikanKayit.currency}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="tutar"
                  disabled={true}
                  value={cikanKayit.tutar}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="borcTry"
                  disabled={true}
                  value={cikanKayit.borcTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="alacakTry"
                  disabled={true}
                  value={cikanKayit.alacakTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="name"
                  disabled={true}
                  value={cikanKayit.tutarTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="borcUsd"
                  disabled={true}
                  value={cikanKayit.borcUsd}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="alacakUsd"
                  disabled={true}
                  value={cikanKayit.alacakUsd}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="tutarUsd"
                  disabled={true}
                  value={cikanKayit.tutarUsd}
                />
              </TableCell>
              <TableCell
                align="right"
                width="70px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="kur"
                  disabled={true}
                  value={cikanKayit.kur}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </SuiBox>

      {/* <AvsDataTable
        isCheck={true}
        isInitalGroup={false}
        tableData={reelAxabta}
        isKyn={false}
        tableType={tableTypeList.virmanListMultiFalse}
      /> */}
      <SuiBox my={2} ref={gridTableEndRef}>
        {visible && (
          <GridExample
            endPoint={endPointMizan.mizanReel}
            tableType={tableTypeList.virmanMultiList}
            filterDisable={true}
            filterData={filterData}
          />
        )}
      </SuiBox>
    </SuiBox>
  );
}

export default VirmanMutiAdd;
