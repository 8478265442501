import React, { useState, useEffect } from "react";
// react-router-dom components
import { Link } from "react-router-dom";

//language
import { FormattedMessage, useIntl } from "react-intl";

// Soft UI component
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiButton from "components/SuiButton";
import SuiDatePicker from "components/SuiDatePicker";
import SuiSnackbar from "components/SuiSnackbar";

//service axios
import axios from "services/axios/index";
import {
  getEndPointReelKayitDeleteAndCreateBulk,
  getEndPointReelAxaptaDiff,
  getEndPointReelDataCount,
} from "services/axios/endpoint";

// utils
import { recordTtype } from "utils/constants/recordType";

//state
import {
  mainFilterAtom,
  buttonActionAtom,
  activeTabAtom,
  reelKayitAtom,
  axabtaMizanAtom,
} from "recoil/atoms";
import { useRecoilState, useSetRecoilState } from "recoil";

//actions
import { useTableActions } from "actions/tableAction";
import AvsSwal from "components/avs/AvsSwal";
import SwalTypes from "components/avs/AvsSwal/types";

function AvsSalesFilter() {
  //select value list
  const companyList = [
    // { value: "all", label: "All" },
    { value: "avs", label: "AVS" },
    { value: "alb", label: "Albatros" },
    { value: "edm", label: "Edmar" },
    { value: "ekol", label: "Ekol" },
    { value: "ram", label: "Rammi" },
  ];

  const mainAccountList = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
  ];

  const { formatMessage } = useIntl();
  const intl = useIntl();
  //state
  // const setMainFilter = useSetMainFilter();
  const [infoSB, setInfoSB] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [diffItemNumber, setDiffItemNumber] = useState(0);

  //global state
  const [activeTab, setActiveTab] = useRecoilState(activeTabAtom);
  const [mainFilterState, setMainFilterState] = useRecoilState(mainFilterAtom);
  const [reelKayitListState, setReelKayitListState] =
    useRecoilState(reelKayitAtom);
  const setButtonActionState = useSetRecoilState(buttonActionAtom);

  const [axabtaMizanListState, setAxabtaMizanListState] =
    useRecoilState(axabtaMizanAtom);

  //state
  // date state
  const [startDate, setStartDate] = useState(mainFilterState.startDate);
  const [endDate, setEndDate] = useState(mainFilterState.endDate);
  const [company, setCompany] = useState(mainFilterState.company);
  const [accountPlan, setAccountPlan] = useState([]);
  const [companySelectItem, setCompanySelectItem] = useState(
    companyList.filter((a) => {
      if (a.value == mainFilterState.company) {
        return a;
      }
    })
  );

  //deleted this part
  //action
  const tableActions = useTableActions();

  const handleSetStartDate = (newDate) => setStartDate(newDate[0]);
  const handleSetEndDate = (newDate) => setEndDate(newDate[0]);
  const handleSetCompany = (company) => {
    setCompany(company.value);
  };

  //deleted this part
  // const handleSetMainAccount = (data) => {
  //   console.log(data);
  //   let newArr = [];
  //   data.map((item) => {
  //     newArr.push(parseInt(item.value));
  //   });
  //   setAccountPlan((s) => [...newArr]);
  // };

  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  //deleted this part
  //default account plan function
  // const handleDefaultAccount = () => {
  //   return mainAccountList.filter((codeItem) =>
  //     mainFilterState.accountPlan.includes(parseInt(codeItem.value))
  //   );
  // };

  //axabta and reel db diff for guncel kayit button
  useEffect(() => {
    async function fetchAxabtaRecordCount() {
      const reelAxaptaDiffUrl = await getEndPointReelAxaptaDiff(
        mainFilterState.company,
        mainFilterState.startDate,
        mainFilterState.endDate,
        recordTtype.axabta
      );

      const data = await axios
        .get(reelAxaptaDiffUrl)
        .then((res) => res.data.data);

      setDiffItemNumber(data);
      return data;
    }
    fetchAxabtaRecordCount();
  }, [mainFilterState]);

  //export data function
  const handleExport = () => {
    setButtonActionState((s) => {
      return {
        ...s,
        exportTable: true,
      };
    });
  };

  const renderInfoSB = (
    <SuiSnackbar
      icon="autorenew"
      title="Filter Data"
      content="Data started to load for all tables."
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderSuccessSB = (
    <SuiSnackbar
      color="success"
      icon="check"
      title="Filter Data"
      content="All tables data loaded."
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  //update main filter state
  const handleOnayla = async () => {
    //open notification
    openInfoSB();
    //set main filter atom
    await setMainFilterState({
      company: company,
      startDate: startDate,
      endDate: endDate,
      accountPlan: accountPlan,
    });
    //open success notification
    openSuccessSB();
  };

  // from axabta to reel db transfer start
  let timerInterval;
  let dataLength = 0;
  let transferIsFinished = false;

  // show sweetalert
  const showAlert = async (dataCount) => {
    AvsSwal({
      data: {
        type: SwalTypes.timeInterval,
        intl,
        props: {
          title: "start_data_transfer",
          text: "data_transfer_text",
          dataCount,
          timerInterval,
          getDiff,
          dataLength,
          transferIsFinished,
        },
      },
    });
  };

  // get axabta data count from reel db
  const getDiff = async () => {
    const reelAxaptaDiffUrl = await getEndPointReelAxaptaDiff(
      mainFilterState.company,
      mainFilterState.startDate,
      mainFilterState.endDate,
      recordTtype.axabta
    );

    const data = await axios
      .get(reelAxaptaDiffUrl)
      .then((res) => res.data.data);

    dataLength = data;
  };

  const handleGuncelKayit = async () => {
    if (diffItemNumber === 0) {
      return AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_title",
            text: "error_no_new_data_in_axabta",
          },
        },
      });
    }

    AvsSwal({
      data: {
        type: SwalTypes.updateRecord,
        intl,
        props: {
          getEndPointReelDataCount,
          mainFilterState,
          recordTtype,
          showAlert,
          getEndPointReelKayitDeleteAndCreateBulk,
          setActiveTab,
          getEndPointReelAxaptaDiff,
          setDiffItemNumber,
          transferIsFinished,
        },
      },
    });
  };

  return (
    <SuiBox
      display="flex"
      flexDirection={{ md: "column", lg: "row" }}
      justifyContent={{ md: "center", lg: "space-between" }}
    >
      {renderInfoSB}
      {renderSuccessSB}
      <SuiBox display="flex" justifyContent="space-between">
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          mr={1}
        >
          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              <FormattedMessage id="firm" />
            </SuiTypography>
          </SuiBox>
          <SuiBox bgColor="white" style={{ position: "relative", zIndex: 100 }}>
            <SuiSelect
              options={companyList}
              defaultValue={companySelectItem}
              onChange={(value) => handleSetCompany(value)}
            />
          </SuiBox>
        </SuiBox>
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          mr={1}
        >
          <SuiBox display="inline-block">
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              <FormattedMessage id="start_date" />
            </SuiTypography>
          </SuiBox>
          <SuiDatePicker value={startDate} onChange={handleSetStartDate} />
        </SuiBox>
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          mr={1}
        >
          <SuiBox display="inline-block">
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              <FormattedMessage id="end_date" />
            </SuiTypography>
          </SuiBox>
          <SuiDatePicker value={endDate} onChange={handleSetEndDate} />
        </SuiBox>
        {/* <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          mr={1}
        >
          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              <FormattedMessage id="account_plan" />
            </SuiTypography>
          </SuiBox>
          <SuiBox bgColor="white" style={{ position: "relative", zIndex: 100 }}>
            <SuiSelect
              isMulti
              options={mainAccountList}
              defaultValue={handleDefaultAccount}
              onChange={(value) => handleSetMainAccount(value)}
            />
          </SuiBox>
        </SuiBox> */}
        <SuiBox display="flex" flexDirection="column" justifyContent="flex-end">
          <SuiButton variant="gradient" color="dark" onClick={handleOnayla}>
            <FormattedMessage id="confirm" />
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <SuiBox display="flex" justifyContent="flex-end">
        {/* {activeTab === 3 && (
          <SuiBox
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            ml={1}
          >
            <SuiBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
            >
              <SuiButton
                variant="gradient"
                color="dark"
                component={Link}
                to="/mutiple-virman-create"
              >
                <FormattedMessage id="create_multiple_virement" />
              </SuiButton>
            </SuiBox>
          </SuiBox>
        )} */}
        
      </SuiBox>
    </SuiBox>
  );
}

export default AvsSalesFilter;
