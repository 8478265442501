import React, { useEffect, useState, useMemo } from "react";
// import { Card, CardImg, CardText, CardBody, CardTitle } from "reactstrap";
import TableContainer from "components/avs/AvsDataTable/TableContainer";
import Moment from "react-moment";

//language
import { FormattedMessage } from "react-intl";

// utils
import { tableTypeList } from "utils/constants/recordType";

import PropTypes from "prop-types";

//formatters
import { FormattedNumber } from "react-intl";

//state
import { virmanSelectAtom } from "recoil/atoms";
import { useRecoilState } from "recoil";

const AvsDataTable = ({
  tableData,
  isCheck,
  isInitalGroup,
  isKyn,
  tableType,
}) => {
  const [data, setData] = useState([]);

  const [virmanSelectState, setVirmanSelectState] =
    useRecoilState(virmanSelectAtom);

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setData([]);
    setData([...tableData]);
  }, [tableData]);

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        disableSortBy: true,
        canGroupBy: false,
      },
      {
        Header: "TrsNo",
        accessor: "trsNo",
        disableSortBy: true,
        canGroupBy: false,
      },
      {
        Header: "Is Credit",
        accessor: "isCredit",
        disableSortBy: true,
        canGroupBy: false,
      },
      {
        Header: "IsVirmanRelation",
        accessor: "isVirmanRelation",
        disableSortBy: true,
        canGroupBy: false,
      },
      {
        Header: <FormattedMessage id="source_short" />,
        accessor: "kyn",
        disableSortBy: true,
        canGroupBy: false,
      },
      {
        Header: <FormattedMessage id="date" />,
        accessor: "tarih",
        canGroupBy: false,
        width: 200,
        Cell: (props) => <Moment format="DD-MM-YYYY">{props.value}</Moment>,
        aggregate: "sum",
        Aggregated: ({ value }) => <div></div>,
      },
      {
        Header: <FormattedMessage id="log_number" />,
        accessor: "gunlukNo",
        disableSortBy: true,
        canGroupBy: false,
      },
      {
        Header: <FormattedMessage id="voucher_no" />,
        accessor: "fis",
        disableSortBy: true,
        canGroupBy: false,
      },
      {
        Header: "is Main Account",
        accessor: "isMainAccount",
        disableSortBy: true,
        canGroupBy: false,
      },
      {
        Header: <FormattedMessage id="account_type" />,
        accessor: "hesapTipi",
        canGroupBy: false,
      },
      {
        Header: <FormattedMessage id="account_code" />,
        accessor: "hesapKodu",
        isGrouped: true,
        canGroupBy: true,
      },
      {
        Header: <FormattedMessage id="account_name" />,
        accessor: "name",
        canGroupBy: false,
      },
      {
        Header: <FormattedMessage id="description" />,
        accessor: "txt",
        canGroupBy: false,
      },
      {
        Header: <FormattedMessage id="currency_unit_short" />,
        accessor: "currency",
        canGroupBy: false,
      },
      {
        Header: <FormattedMessage id="amount" />,
        accessor: "tutar",
        canGroupBy: false,
        Cell: ({ value }) => (
          <div style={{ textAlign: "right" }}>
            <FormattedNumber value={value} />
          </div>
        ),
        aggregate: "sum",
        Aggregated: ({ value }) => <div></div>,
      },
      {
        Header: <FormattedMessage id="debit_try_short" />,
        accessor: "borcTry",
        canGroupBy: false,
        Cell: ({ value }) => (
          <div style={{ textAlign: "right" }}>
            <FormattedNumber value={value} />
          </div>
        ),
        aggregate: "sum",
        Aggregated: ({ value }) => (
          <div style={{ textAlign: "right", fontWeight: "bold" }}>
            <FormattedNumber value={value} />
          </div>
        ),
      },
      {
        Header: <FormattedMessage id="credit_try_short" />,
        accessor: "alacakTry",
        canGroupBy: false,
        Cell: ({ value }) => (
          <div style={{ textAlign: "right" }}>
            <FormattedNumber value={value} />
          </div>
        ),
        aggregate: "sum",
        Aggregated: ({ value }) => (
          <div style={{ textAlign: "right", fontWeight: "bold" }}>
            <FormattedNumber value={value} />
          </div>
        ),
      },
      {
        Header: <FormattedMessage id="amount_try_short" />,
        accessor: "tutarTry",
        canGroupBy: false,
        Cell: ({ value }) => (
          <div style={{ textAlign: "right" }}>
            <FormattedNumber value={value} />
          </div>
        ),
        aggregate: "sum",
        Aggregated: ({ value }) => (
          <div style={{ textAlign: "right", fontWeight: "bold" }}>
            <FormattedNumber value={value} />
          </div>
        ),
      },

      {
        Header: <FormattedMessage id="debit_usd_short" />,
        accessor: "borcUsd",
        canGroupBy: false,
        Cell: ({ value }) => (
          <div style={{ textAlign: "right" }}>
            <FormattedNumber value={value} />
          </div>
        ),
        aggregate: "sum",
        Aggregated: ({ value }) => (
          <div style={{ textAlign: "right", fontWeight: "bold" }}>
            <FormattedNumber value={value} />
          </div>
        ),
      },
      {
        Header: <FormattedMessage id="credit_usd_short" />,
        accessor: "alacakUsd",
        canGroupBy: false,
        Cell: ({ value }) => (
          <div style={{ textAlign: "right" }}>
            <FormattedNumber value={value} />
          </div>
        ),
        aggregate: "sum",
        Aggregated: ({ value }) => (
          <div style={{ textAlign: "right", fontWeight: "bold" }}>
            <FormattedNumber value={value} />
          </div>
        ),
      },
      {
        Header: <FormattedMessage id="amount_usd_short" />,
        accessor: "tutarUsd",
        canGroupBy: false,
        Cell: ({ value }) => (
          <div style={{ textAlign: "right" }}>
            <FormattedNumber value={value} />
          </div>
        ),
        aggregate: "sum",
        Aggregated: ({ value }) => (
          <div style={{ textAlign: "right", fontWeight: "bold" }}>
            <FormattedNumber value={value} />
          </div>
        ),
      },
      {
        Header: <FormattedMessage id="currency_rate_short" />,
        accessor: "kur",
        canGroupBy: false,
        Cell: ({ value }) => (
          <div style={{ textAlign: "right" }}>
            <FormattedNumber value={value} />
          </div>
        ),
        aggregate: "sum",
        Aggregated: ({ value }) => <div></div>,
      },
      // {
      //   Header: "Hemisphere",
      //   accessor: (values) => {
      //     const { latitude, longitude } = values.location.coordinates;
      //     const first = Number(latitude) > 0 ? "N" : "S";
      //     const second = Number(longitude) > 0 ? "E" : "W";
      //     return first + "/" + second;
      //   },
      //   disableSortBy: true,
      //   Filter: SelectColumnFilter,
      //   filter: "equals",
      //   Cell: ({ cell }) => {
      //     const { value } = cell;

      //     const pickEmoji = (value) => {
      //       let first = value[0]; // N or S
      //       let second = value[2]; // E or W
      //       const options = ["⇖", "⇗", "⇙", "⇘"];
      //       let num = first === "N" ? 0 : 2;
      //       num = second === "E" ? num + 1 : num;
      //       return options[num];
      //     };

      //     return (
      //       <div style={{ textAlign: "center", fontSize: 18 }}>
      //         {pickEmoji(value)}
      //       </div>
      //     );
      //   },
      // },
    ],
    []
  );

  return (
    <TableContainer
      columns={columns}
      tableData={data}
      isCheck={isCheck}
      isInitalGroup={isInitalGroup}
      isKyn={isKyn}
      tableType={tableType}
      setVirmanSelect={setVirmanSelectState}
      selectedRows={selectedRows}
      onSelectedRowsChange={setSelectedRows}
    />
  );
};

AvsDataTable.defaultProps = {
  isCheck: false,
  isInitalGroup: false,
  isKyn: false,
  tableType: tableTypeList.axabtaList,
};

// Typechecking props for the EventCard
AvsDataTable.propTypes = {
  isCheck: PropTypes.bool,
  isInitalGroup: PropTypes.bool,
  isKyn: PropTypes.bool,
  tableType: PropTypes.string,
};

export default AvsDataTable;
