import React, { useState } from "react";

import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
import { Formik, Form, FieldArray } from "formik";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Check from "@mui/icons-material/Check";

import GridExample from "components/avs/AvsAgGrid/ServerSide";

import ReverseRecord from "components/avs/AvsTransferBalance/ReverseRecord";
import NewRecord from "components/avs/AvsTransferBalance/NewRecord";
import AvsDataEntryOneItem from "components/avs/AvsDataEntry/AvsDataEntryOneItem";
// Soft UI component
import initialValues from "components/avs/AvsTransferBalance/NewRecord/initialValues";

import AvsBreadcrumbs from "components/avs/AvsBreadcrumbs";
import { FormattedMessage } from "react-intl";

//service
import axios from "services/axios/index";
import { endPointMizan } from "services/axios/endpoint";

import { tableTypeList } from "utils/constants/recordType";

function getSteps() {
  return ["reverse_record", "new_record"];
}

const breadcrumbRoutes = [
  {
    route: "mizan",
    label: "trial_balance",
  },
  {
    route: "menu",
    label: "finance",
  },
];

const init = {
  records: [
    /**
     * 2 times initialValues
     */
    {
      ...initialValues,
    },
    {
      ...initialValues,
    },
  ],
};

function TransferBalance() {
  const [activeStep, setActiveStep] = useState(0);
  //filter data
  const [filterData, setFilterData] = useState({
    kyn: {
      filterType: "text",
      type: "contains",
      filter: "V",
    },
    fis: {
      filterType: "text",
      type: "contains",
      filter: "AAA",
    },
    isOpening: {
      filterType: "text",
      type: "equals",
      filter: "True",
    },
  });
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    //actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
  };
  function getStepContent(stepIndex, values, insert, remove, push) {
    switch (stepIndex) {
      case 0: 
        return <ReverseRecord />;
      case 1:
        return (
          <>
            <AvsDataEntryOneItem isVirman={true} isTransferBalance={true} />
            <GridExample
              endPoint={endPointMizan.mizanReel}
              tableType={tableTypeList.transferBalance}
              filterDisable={true}
              filterData={filterData}
            />
          </>
          // <NewRecord
          //   values={values}
          //   insert={insert}
          //   remove={remove}
          //   push={push}
          // />
        );
      default:
        return null;
    }
  }

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      console.log(values);
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      //actions.setTouched({});
      //actions.setSubmitting(false);
    }
  };
  return (
    <>
      <AvsBreadcrumbs
        title="transfer_balance"
        currentRoute="transfer_balance"
        otherRoutes={breadcrumbRoutes}
      />
      <SuiBox mt={1}>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>
                    <FormattedMessage id={label} />
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Formik initialValues={init} onSubmit={handleSubmit}>
              {({ values }) => (
                <Form autoComplete="off">
                  <FieldArray name="records">
                    {({ insert, remove, push }) => (
                      <SuiBox p={3}>
                        <SuiBox>
                          {getStepContent(
                            activeStep,
                            values,
                            insert,
                            remove,
                            push
                          )}
                          <SuiBox
                            mt={2}
                            py={4}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            {activeStep === 0 ? (
                              <SuiBox />
                            ) : (
                              <SuiButton
                                variant="gradient"
                                color="light"
                                onClick={handleBack}
                              >
                                <FormattedMessage id="back" />
                              </SuiButton>
                            )}
                            {!isLastStep ? (
                              <SuiButton
                                type="submit"
                                variant="gradient"
                                color="dark"
                              >
                                <FormattedMessage id="next" />
                              </SuiButton>
                            ) : (
                              <SuiBox />
                            )}

                            {/* <SuiButton
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? (
                                // <FormattedMessage id="create" />
                                <FormattedMessage id="create" />
                              ) : (
                                <FormattedMessage id="next" />
                              )}
                            </SuiButton> */}
                          </SuiBox>
                        </SuiBox>
                      </SuiBox>
                    )}
                  </FieldArray>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
}

export default TransferBalance;
