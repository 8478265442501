import React, { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";

// Soft UI component
import AVSRequestCard from "components/Cards/AVSFinanceCard/AVSRequestCard";
import AvsBreadcrumbs from "components/avs/AvsBreadcrumbs";

//service
import axios from "services/axios/index";

function VirmanRequest() {
  const breadcrumbRoutes = [
    {
      route: "mizan",
      label: "trial_balance",
    },
    {
      route: "menu",
      label: "finance",
    },
  ];

  const [data, setData] = useState([]);

  //use effect working when row date and currency type change
  useEffect(() => {
    // get currency rate from api
    async function fetchDataRequestVirman() {
      const request = await axios
        .get("/mizan/reel/virman/request-sales")
        .then((res) => res.data);
      setData(request.data);
      console.log(request);
    }
    fetchDataRequestVirman();
  }, []);
  return (
    <>
      <AvsBreadcrumbs
        title="virement_wish_list"
        currentRoute="virement_wish_list"
        otherRoutes={breadcrumbRoutes}
      />
      <SuiBox display="flex" justifyContent="center" p={5}>
        <Grid
          container
          display="flex"
          justifyContent="center"
          spacing={{ xs: 2, md: 3 }}
        >
          {
            /**
             * for loop that creates 10 cards that start with number 1
             */
            data.map((item, index) => (
              <Grid item key={index}>
                <AVSRequestCard
                  voucherCount={item.voucherCount}
                  color={
                    item.isReverseStep && item.isNewStep
                      ? "success"
                      : item.isReverseStep || item.isNewStep
                      ? "info"
                      : "error"
                  }
                  href={
                    item.isReverseStep && item.isNewStep
                      ? `/virement/sales-request/edit/${item.id}`
                      : `/virement/sales-request/create/${item.id}`
                  }
                  description={item.description}
                  createDate={item.createDate}
                />
              </Grid>
            ))
          }
        </Grid>
      </SuiBox>
    </>
  );
}

export default VirmanRequest;
