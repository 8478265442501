import React, { useEffect } from "react";

//language
import { FormattedMessage } from "react-intl";

// Soft UI component
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { FormattedNumber } from "react-intl";

function AvsFooter({
  isShow,
  totalBorcTry,
  totalAlacakTry,
  totalTry,
  totalBorcUsd,
  totalAlacakUsd,
  totalUsd,
}) {
  return (
    <SuiBox
      style={{
        position: "fixed",
        zIndex: 100,
        bottom: "0",
        right: "24px",
        display: isShow ? "block" : "none",
      }}
    >
      <SuiBox
        display="flex"
        bgColor="dark"
        p={1}
        style={{
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <SuiBox bgColor="dark" textAlign="right">
          <SuiTypography
            component="p"
            variant="button"
            color="light"
            fontWeight="medium"
          >
            <FormattedMessage id="total_debit_try" />
          </SuiTypography>
          <SuiTypography
            component="p"
            variant="button"
            color="white"
            fontWeight="bold"
          >
            <FormattedNumber value={totalBorcTry} />
          </SuiTypography>
        </SuiBox>
        <SuiBox
          mx={1}
          style={{
            borderLeft: "1px solid",
            borderColor: "#F8F9FA",
          }}
        />
        <SuiBox bgColor="dark" textAlign="right">
          <SuiTypography
            component="p"
            variant="button"
            color="light"
            fontWeight="medium"
          >
            <FormattedMessage id="total_credit_try" />
          </SuiTypography>
          <SuiTypography
            component="p"
            variant="button"
            color="white"
            fontWeight="bold"
          >
            <FormattedNumber value={totalAlacakTry} />
          </SuiTypography>
        </SuiBox>
        <SuiBox
          mx={1}
          style={{
            borderLeft: "1px solid",
            borderColor: "#F8F9FA",
          }}
        />
        <SuiBox bgColor="dark" textAlign="right">
          <SuiTypography
            component="p"
            variant="button"
            color="light"
            fontWeight="medium"
          >
            <FormattedMessage id="total_try" />
          </SuiTypography>
          <SuiTypography
            component="p"
            variant="button"
            color="white"
            fontWeight="bold"
          >
            <FormattedNumber value={totalTry} />
          </SuiTypography>
        </SuiBox>
        <SuiBox
          mx={1}
          style={{
            borderLeft: "1px solid",
            borderColor: "#F8F9FA",
          }}
        />
        <SuiBox bgColor="dark" textAlign="right">
          <SuiTypography
            component="p"
            variant="button"
            color="light"
            fontWeight="medium"
          >
            <FormattedMessage id="total_debit_usd" />
          </SuiTypography>
          <SuiTypography
            component="p"
            variant="button"
            color="white"
            fontWeight="bold"
          >
            <FormattedNumber value={totalBorcUsd} currency="USD" />
          </SuiTypography>
        </SuiBox>
        <SuiBox
          mx={1}
          style={{
            borderLeft: "1px solid",
            borderColor: "#F8F9FA",
          }}
        />
        <SuiBox bgColor="dark" textAlign="right">
          <SuiTypography
            component="p"
            variant="button"
            color="light"
            fontWeight="medium"
          >
            <FormattedMessage id="total_credit_usd" />
          </SuiTypography>
          <SuiTypography
            component="p"
            variant="button"
            color="white"
            fontWeight="bold"
          >
            <FormattedNumber value={totalAlacakUsd} currency="USD" />
          </SuiTypography>
        </SuiBox>
        <SuiBox
          mx={1}
          style={{
            borderLeft: "1px solid",
            borderColor: "#F8F9FA",
          }}
        />
        <SuiBox
          bgColor="dark"
          textAlign="right"
          style={{
            borderTopRightRadius: "8px",
          }}
        >
          <SuiTypography
            component="p"
            variant="button"
            color="light"
            fontWeight="medium"
          >
            <FormattedMessage id="total_usd" />
          </SuiTypography>
          <SuiTypography
            component="p"
            variant="button"
            color="white"
            fontWeight="bold"
          >
            <FormattedNumber value={totalUsd} currency="USD" />
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

// Setting default values for the props of AvsFooter
AvsFooter.defaultProps = {
  isShow: true,
  totalBorcTry: 0.0,
  totalAlacakTry: 0.0,
  totalTry: 0.0,
  totalBorcUsd: 0.0,
  totalAlacakUsd: 0.0,
  totalUsd: 0.0,
};

// Typechecking props for the AvsFooter
AvsFooter.propTypes = {
  isShow: PropTypes.bool,
  totalBorcTry: PropTypes.number,
  totalAlacakTry: PropTypes.number,
  totalTry: PropTypes.number,
  totalBorcUsd: PropTypes.number,
  totalAlacakUsd: PropTypes.number,
  totalUsd: PropTypes.number,
};

export default AvsFooter;
