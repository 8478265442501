import React from "react";
import moment from "moment";

//formatters
import { FormattedNumber } from "react-intl";

// utils
import { tableTypeList } from "utils/constants/recordType";

const mizanReportColumn = () => {
  let mainColumn = [
    {
      headerName: "PARENTID",
      // sorting and other feature not showing from custom header
      // headerComponent: HeaderRenderer,
      field: "PARENTID",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      headerName: "MAINACCOUNTID",
      // sorting and other feature not showing from custom header
      // headerComponent: HeaderRenderer,
      field: "MAINACCOUNTID",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      // headerName: "log_number",
      // headerComponent: HeaderRenderer,
      headerName: "Account Name",
      field: "NAME",
      filter: "agTextColumnFilter",
    },
    {
      field: "TOTALBORCTRY",
      headerName: "Dept TRY",
      // headerName: "voucher_no",
      // headerComponent: HeaderRenderer,
      filter: "agTextColumnFilter",
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      field: "TOTALALACAKTRY",
      headerName: "Credit TRY",
      // headerName: "account_type",
      // headerComponent: HeaderRenderer,
      filter: "agTextColumnFilter",
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "Dept Balance TRY",
      // headerName: "account_code",
      // headerComponent: HeaderRenderer,
      field: "TOTALBORCBALANCETRY",
      // rowGroup: true,
      filter: "agTextColumnFilter",
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "Credit Balance TRY",
      // headerName: "account_code",
      // headerComponent: HeaderRenderer,
      field: "TOTALALACAKBALANCETRY",
      // rowGroup: true,
      filter: "agTextColumnFilter",
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      field: "TOTALBORCUSD",
      headerName: "Dept USD",
      // headerName: "voucher_no",
      // headerComponent: HeaderRenderer,
      filter: "agTextColumnFilter",
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      field: "TOTALALACAKUSD",
      headerName: "Credit USD",
      // headerName: "account_type",
      // headerComponent: HeaderRenderer,
      filter: "agTextColumnFilter",
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "Dept Balance USD",
      // headerName: "account_code",
      // headerComponent: HeaderRenderer,
      field: "TOTALBORCBALANCEUSD",
      // rowGroup: true,
      filter: "agTextColumnFilter",
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "Credit Balance USD",
      // headerName: "account_code",
      // headerComponent: HeaderRenderer,
      field: "TOTALALACAKBALANCEUSD",
      // rowGroup: true,
      filter: "agTextColumnFilter",
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
  ];
  return mainColumn;
};

export { mizanReportColumn };
