const types = {
  avsdataentryedit: 1,
  avsdataentry: 2,
  buttonRenderer: 3,
  error: 4,
  success: 5,
  cancelQ: 6,
  fisDelete: 7,
  timeInterval: 8,
  updateRecord: 9,
  sendToReel: 10,
  createVirmanMultipleEditQ: 11,
  delete: 12,
  virementrequestdetailedit: 13,
  buttonRendererSalesDelete: 14,
  buttonRendererTransferBalanceDelete: 14,
};

export default types;
