/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DefaultLineChart from "components/Charts/LineCharts/DefaultLineChart";
import GradientLineChart from "components/Charts/LineCharts/GradientLineChart";
import VerticalBarChart from "components/Charts/BarCharts/VerticalBarChart";
import HorizontalBarChart from "components/Charts/BarCharts/HorizontalBarChart";
import MixedChart from "components/Charts/MixedChart";
import BubbleChart from "components/Charts/BubbleChart";
import DefaultDoughnutChart from "components/Charts/DoughnutCharts/DefaultDoughnutChart";
import PieChart from "components/Charts/PieChart";
import RadarChart from "components/Charts/RadarChart";
import PolarChart from "components/Charts/PolarChart";

// Data
import defaultLineChartData from "components/layouts/pages/charts/data/defaultLineChartData";
import gradientLineChartData from "components/layouts/pages/charts/data/gradientLineChartData";
import verticalBarChartData from "components/layouts/pages/charts/data/verticalBarChartData";
import horizontalBarChartData from "components/layouts/pages/charts/data/horizontalBarChartData";
import mixedChartData from "components/layouts/pages/charts/data/mixedChartData";
import bubbleChartData from "components/layouts/pages/charts/data/bubbleChartData";
import defaultDoughnutChartData from "components/layouts/pages/charts/data/defaultDoughnutChartData";
import pieChartData from "components/layouts/pages/charts/data/pieChartData";
import radarChartData from "components/layouts/pages/charts/data/radarChartData";
import polarChartData from "components/layouts/pages/charts/data/polarChartData";

function Charts() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox my={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} sx={{ lineHeight: 0 }}>
              <SuiTypography variant="h5">Charts</SuiTypography>
              <SuiTypography variant="button" fontWeight="regular" color="text">
                Charts on this page use Chart.js - Simple yet flexible
                JavaScript charting for designers & developers.
              </SuiTypography>
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <DefaultLineChart
                title="Line chart"
                chart={defaultLineChartData}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GradientLineChart
                title="Line chart with gradient"
                chart={gradientLineChartData}
              />
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <VerticalBarChart
                title="Bar chart"
                chart={verticalBarChartData}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <HorizontalBarChart
                title="Bar chart horizontal"
                chart={horizontalBarChartData}
              />
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MixedChart
                title="Mixed chart"
                height="19.75rem"
                chart={mixedChartData}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BubbleChart title="Bubble chart" chart={bubbleChartData} />
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <DefaultDoughnutChart
                title="Doughnut chart"
                chart={defaultDoughnutChartData}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PieChart title="Pie chart" chart={pieChartData} />
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <RadarChart title="Radar chart" chart={radarChartData} />
            </Grid>
            <Grid item xs={12} md={6}>
              <PolarChart title="Polar chart" chart={polarChartData} />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Charts;
