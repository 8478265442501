/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import Link from "@mui/material/Link";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Icon from "@mui/material/Icon";
import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DefaultNavbarMobile from "components/Navbars/DefaultNavbar/DefaultNavbarMobile";
import SingleNavbarLink from "components/Navbars/DefaultNavbar/SingleNavbarLink";
import Header from "components/Header";
import MainBanner from "components/Banner";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
} from "components/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setTransparentNavbar } from "context";

function DashboardNavbar({ absolute, light, transparent }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { transparentNavbar, fixedNavbar } = controller;

  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const openMobileNavbar = ({ currentTarget }) =>
    setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar,
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SuiBox sx={{ display: "flex" }}>
          <MainBanner />
          <SuiBox
            sx={{ alignSelf: "center" }}
            // display={{ xs: "none", lg: "none", xl: "flex" }}
            component={Link}
            href="/"
            py={transparent ? 1.5 : 0.75}
            lineHeight={1}
          >
            <SuiTypography
              variant="button"
              marginLeft=".5rem"
              alignSelf="center"
              fontWeight="bold"
              color={light ? "white" : "dark"}
            >
              AVS Global Supply
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox
          color="inherit"
          display={{ xs: "none", lg: "flex" }}
          m={0}
          p={0}
        >
          <SingleNavbarLink name="Finance" route="/menu" light={light} />
        </SuiBox>
        <SuiBox sx={(theme) => navbarRow(theme)}>
          <SuiBox sx={{ alignSelf: "center" }} display={{ lg: "inline-block" }}>
            <Header email="AuthUser.email" signOut={() => {}} />
          </SuiBox>
        </SuiBox>
        <SuiBox
          display={{
            xs: "inline-block",
            lg: "none",
            position: "absolute",
            right: "1rem",
          }}
          lineHeight={0}
          py={1.5}
          pl={1.5}
          color="inherit"
          customClass="cursor-pointer"
          onClick={openMobileNavbar}
        >
          <Icon className="" fontSize="default">
            {mobileNavbar ? "close" : "menu"}
          </Icon>
        </SuiBox>
        {mobileView && (
          <DefaultNavbarMobile open={mobileNavbar} close={closeMobileNavbar}>
            Hello
          </DefaultNavbarMobile>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  transparent: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  transparent: PropTypes.bool,
};

export default DashboardNavbar;
