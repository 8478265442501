import React, { useState } from "react";

// Soft UI component
import AvsBreadcrumbs from "components/avs/AvsBreadcrumbs";
import AvsMainFilter from "components/avs/AvsMainFilter";
import AvsTab from "components/avs/AvsTab";
function Mizan() {
  const breadcrumbRoutes = [
    {
      route: "menu",
      label: "finance",
    },
  ];
  return (
    <>
      {/* Section Breadcrumb */}
      <AvsBreadcrumbs
        title="trial_balance_balance_sheet_income_statement"
        currentRoute="trial_balance"
        otherRoutes={breadcrumbRoutes}
      />
      {/* Section Filter */}
      <AvsMainFilter />
      {/* Section Tab */}
      <AvsTab setT mizan />
      {/* Section Fixed Footer */}
    </>
  );
}

export default Mizan;
