import React from "react";

// Soft UI component
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";

// Avs UI Component
import AvsBreadcrumbs from "components/avs/AvsBreadcrumbs";

function UpdateData() {
  const breadcrumbRoutes = [
    {
      route: "mizan",
      label: "mizan",
    },
    {
      route: "menu",
      label: "Finance",
    },
  ];
  return (
    <SuiBox>
      {/* Section Breadcrumb */}
      <AvsBreadcrumbs
        title="Güncel Kayıtlar"
        currentRoute="Güncel Kayıtlar"
        otherRoutes={breadcrumbRoutes}
      />
      {/* Section Button */}
      <SuiBox display="flex" justifyContent="flex-end" mt={1}>
        <SuiBox display="flex" flexDirection="row" justifyContent="flex-end">
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
          >
            <SuiButton variant="gradient" color="success">
              Güncelle
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default UpdateData;
