import React, { useCallback, useMemo, useRef, useState } from 'react'; 
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './styles.css';

//service axios
import axios from "services/axios/index";

//endpoint
import { 
  getEndPointReelMizanDescription ,
  getEndPointReelIncomeStatement ,
  getEndPointReelBalanceSheet
} from "services/axios/endpoint";

//utils
import { getYearStartFromDate } from "utils/helpers/dateFormat"; 
import { tableTypeList } from "utils/constants/recordType";
import { balanceSheetStatus } from "utils/constants/enum";

//table column
import { mizanReportColumn } from "./column";

import { useRecoilValue } from "recoil";

//state
import {
  mainFilterAtom
} from "recoil/atoms"; 

const createFakeServer = (fakeServerData) => {
  const fakeServer = {
    getData: (request) => {
      function extractRowsFromData(groupKeys, data) {
        if (groupKeys.length === 0) {
          return data.map(function (d) {
            return {
              ...d,
              group: !!d.children, 
            };
          });
        }
        var key = groupKeys[0];
        for (var i = 0; i < data.length; i++) {
          if (data[i].MAINACCOUNTID === key) {
            return extractRowsFromData(
              groupKeys.slice(1),
              data[i].children.slice()
            );
          }
        }
      }
      return extractRowsFromData(request.groupKeys, fakeServerData);
    },
  };
  return fakeServer;
};

const createServerSideDatasource = (fakeServer) => {
  //  console.log(fakeServer);
  const dataSource = {
    getRows: (params) => {
      console.log('ServerSideDatasource.getRows: params = ', params);
      var request = params.request;
      var allRows = fakeServer.getData(request);
      var doingInfinite = request.startRow != null && request.endRow != null;
      console.log(allRows);
      var result = doingInfinite
        ? {
            rowData: allRows.slice(request.startRow, request.endRow),
            rowCount: allRows.length,
          }
        : { rowData: allRows };
      //console.log('getRows: result = ', result);
      setTimeout(function () {
        params.success(result);
      }, 500);
    },
  };
  return dataSource;
};
 
const GridTreeViewExample = ({gtype,activeGrid="tb"}) => {
  console.log("GridTreeViewExample ",gtype);
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  const mainFilterState = useRecoilValue(mainFilterAtom);

  const [activeTab, setActiveTab] = useState(0);
  const [activeCurrency, setActiveCurrency] = useState(0);
  // const [mizanType, setMizanType] = useState(gtype);
  const [columnDefs, setColumnDefs] = useState(mizanReportColumn());
  const defaultColDef = useMemo(() => {
    return {
      width: 235,
      resizable: true,
      flex: 1,
      floatingFilter:  false ,
      filter: false,
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      field: 'MAINACCOUNTID',
    };
  }, []);
  

  const isServerSideGroupOpenByDefault = useCallback((params) => {
    var isKathrynPowers =
      params.rowNode.level == 0 && params.data.employeeName == 'Kathryn Powers';
    var isMabelWard =
      params.rowNode.level == 1 && params.data.employeeName == 'Mabel Ward';
    return isKathrynPowers || isMabelWard;
  }, []);
  
  const isServerSideGroup = useCallback((dataItem) => {
    // indicate if node is a group
    return dataItem.group;
  }, []);

  const getServerSideGroupKey = useCallback((dataItem) => {
    // specify which group key to use
    return dataItem.MAINACCOUNTID;
  }, []);

  const onGridReady = useCallback(async (params) => {
    gridRef.current.api.showLoadingOverlay(); 
    let endPoint ;
    const startDate =getYearStartFromDate(mainFilterState.endDate);
    console.log(startDate);
      if(activeGrid==="tb"){
        endPoint = await getEndPointReelMizanDescription(  
          mainFilterState.company, 
          startDate,
          mainFilterState.endDate,
          gtype
          );
      }else if(activeGrid==="is"){
        endPoint = await getEndPointReelIncomeStatement(  
          mainFilterState.company, 
          startDate,
          mainFilterState.endDate,
          gtype
          );
      }else if(activeGrid===tableTypeList.balanceSheetAxaptaActive){
        endPoint = await getEndPointReelBalanceSheet(  
          mainFilterState.company, 
          startDate,
          mainFilterState.endDate,
          gtype,
          balanceSheetStatus.active
          ); 
      }else if(activeGrid===tableTypeList.balanceSheetAxaptaPassive){
        endPoint = await getEndPointReelBalanceSheet(  
          mainFilterState.company, 
          startDate,
          mainFilterState.endDate,
          gtype,
          balanceSheetStatus.passive
          );
      } else if(activeGrid===tableTypeList.balanceSheetReelActive){
        endPoint = await getEndPointReelBalanceSheet(  
          mainFilterState.company, 
          startDate,
          mainFilterState.endDate,
          gtype,
          balanceSheetStatus.active
          ); 
      }else if(activeGrid===tableTypeList.balanceSheeReelPassive){
        endPoint = await getEndPointReelBalanceSheet(  
          mainFilterState.company, 
          startDate,
          mainFilterState.endDate,
          gtype,
          balanceSheetStatus.passive
          );
      }
 
    axios.get(endPoint) 
    .then((resp) => resp.data)
    .then((res) => { 
      var fakeServer = createFakeServer(res.data);
      console.log(fakeServer);
      var datasource = createServerSideDatasource(fakeServer);
    
      params.api.setServerSideDatasource(datasource);
      gridRef.current.api.hideOverlay();
    }); 
  }, []);

  const rowClassRules = useMemo(() => {
    return {
      // row style function
      textWeightBold: (params) => { 
        return params.data?.MAINACCOUNTID.length <= 3;
      }, 
    };
  }, []);

  const expandAll = useCallback(() => {
    gridRef.current.api.expandAll();
  }, []);

  const collapseAll = useCallback(() => {
    gridRef.current.api.collapseAll();
   
  }, []);

  const onBtnFark = useCallback(() => {
    // setMizanType('F');
  }, []);
  const onBtnReel = useCallback(() => {
    // setMizanType('R');
  }, []);
 
  return (
    <div style={{ height: "550px" }}> 
    <div style={{ display:"flex",direction:"row",justifyContent:"space-between" }}>
    <div>
          {/* <a onClick={onBtnAxapta} style={{ cursor: "pointer" ,fontSize: "12px",marginRight:"10px",color: activeTab === 0 ? "red":"black"}}>Axabta</a> */}
          {/* <a onClick={onBtnFark} style={{ cursor: "pointer" ,fontSize: "12px",marginRight:"10px",color: activeTab === 1 ? "red":"black"}}>Fark</a>
          <a onClick={onBtnReel} style={{ cursor: "pointer" ,fontSize: "12px",marginRight:"10px",color: activeTab === 2 ? "red":"black"}}>Reel</a> */}
      </div>
      {/*< div>
          <a onClick={onBtnAllCurrency} style={{ cursor: "pointer" ,fontSize: "12px",marginRight:"10px",color: activeCurrency === 0 ? "red":"black"}}>All</a>
          <a onClick={onBtnUSD} style={{ cursor: "pointer" ,fontSize: "12px",marginRight:"10px",color: activeCurrency === 1 ? "red":"black"}}>USD</a>
          <a onClick={onBtnTRY} style={{ cursor: "pointer" ,fontSize: "12px",marginRight:"10px",color: activeCurrency === 2 ? "red":"black"}}>TRY</a>
      </div> */}
      <div>
          <a onClick={expandAll} style={{ cursor: "pointer" ,fontSize: "12px",marginRight:"10px"}}>Expand All</a>
          <a onClick={collapseAll} style={{ cursor: "pointer" ,fontSize: "12px"}}>Collapse All</a>
      </div>

    </div>
 
    <div style={containerStyle}>
      <div className="example-wrapper" style={{ height: "500px" }}>  
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef} 
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            rowModelType={'serverSide'}
            serverSideInfiniteScroll={true}
            treeData={true}
            animateRows={true}
            cacheBlockSize={10} 
            // isServerSideGroupOpenByDefault={isServerSideGroupOpenByDefault}
            isServerSideGroup={isServerSideGroup}
            getServerSideGroupKey={getServerSideGroupKey}
            onGridReady={onGridReady}
            overlayLoadingTemplate={
                '<span class="ag-overlay-loading-center">Please wait while your trial balance calculate</span>'
              }
              rowClassRules={rowClassRules}
          ></AgGridReact>
        </div>
        </div>
      </div>
    </div>
  );
};

export default GridTreeViewExample;