import React, { useState } from "react";

// Soft UI component
import AvsBreadcrumbs from "components/avs/AvsBreadcrumbs";
import AvsSalesFilter from "components/avs/AvsSalesFilter";
import AvsTab from "components/avs/AvsTab";
function Sales() {
  const breadcrumbRoutes = [
    {
      route: "menu",
      label: "finance",
    },
  ];
  return (
    <>
      {/* Section Breadcrumb */}
      <AvsBreadcrumbs
        title="sales"
        currentRoute="sales"
        otherRoutes={breadcrumbRoutes}
      />
      {/* Section Filter */}
      <AvsSalesFilter  />
      {/* Section Tab */}
      <AvsTab setT sales/>
      {/* Section Fixed Footer */}
    </>
  );
}

export default Sales;
