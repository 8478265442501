/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import SalesTable from "components/Tables/SalesTable";
import ReportsBarChart from "components/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "components/Charts/LineCharts/GradientLineChart";
import Globe from "components/Globe";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import breakpoints from "assets/theme/base/breakpoints";

// Data
import salesTableData from "components/layouts/dashboards/default/data/salesTableData";
import reportsBarChartData from "components/layouts/dashboards/default/data/reportsBarChartData";
import gradientLineChartData from "components/layouts/dashboards/default/data/gradientLineChartData";

function Default({ stickyNavbar, children }) { 

  return (
    <DashboardLayout>
      <DashboardNavbar absolute={!stickyNavbar}/>
      <SuiBox mt={stickyNavbar ? 3 : 10}>{children}</SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
