import React from "react";
import moment from "moment";

//formatters
import { FormattedNumber } from "react-intl";

//table button
import ButtonRenderer from "components/avs/AvsAgGrid/AvsVirmanGrid/buttonRenderer";

//table header
import HeaderRenderer from "components/avs/AvsAgGrid/AvsVirmanGrid/headerRenderer";

// utils
import { tableTypeList } from "utils/constants/recordType";

const virmanColumn = () => {
  let mainColumn = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,

      pinned: "left",
      width: "50",
      field: "RECID",
      filter: false,
      sort: false,
    },
    {
      headerName: "Tarih",
      // sorting and other feature not showing from custom header
      // headerComponent: HeaderRenderer,
      field: "TRANSDATE",
      filter: "agTextColumnFilter",
      cellRenderer: (data) => {
        return moment(data.value).format("YYYY-MM-DD");
      },
    },
    {
      // headerName: "log_number",
      // headerComponent: HeaderRenderer,
      headerName: "Hesap Kodu",
      field: "ACCOUNTNUM",
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "Hesap Adı",
      field: "NAME",
      // headerName: "voucher_no",
      // headerComponent: HeaderRenderer,
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "Grup",
      field: "GRUP",
      // headerName: "account_type",
      // headerComponent: HeaderRenderer,
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "Gemi Adı",
      field: "GEMIADI",
      // rowGroup: true,
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "Fiş",
      // headerName: "account_name",
      // headerComponent: HeaderRenderer,
      field: "VOUCHER",
      filter: "agTextColumnFilter",
      sortable: true,
    },
    // {
    //   headerName: "Fiş",
    //   // headerName: "description",
    //   // headerComponent: HeaderRenderer,
    //   field: "INVOICE",
    //   filter: "agTextColumnFilter",
    //   sortable: true,
    // },
    {
      headerName: "Dosya No",
      // headerName: "currency_unit_short",
      // headerComponent: HeaderRenderer,
      field: "DOSYANO",
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "PB",
      // headerName: "amount",
      // headerComponent: HeaderRenderer,
      field: "CURRENCYCODE",
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "Tutar",
      // headerName: "debit_try_short",
      // headerComponent: HeaderRenderer,
      field: "AMOUNTCUR",
      filter: "agNumberColumnFilter",
      // aggFunc: "mySum",
      sortable: true,
    },
  ];

  return mainColumn;
};

const mizanColumn = () => {
  let mainColumn = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,

      pinned: "left",
      width: "50",
      field: "RECID",
      filter: false,
      sort: false,
    },
    {
      headerName: "Date",
      // sorting and other feature not showing from custom header
      // headerComponent: HeaderRenderer,
      field: "tarih",
      filter: "agTextColumnFilter",
      cellRenderer: (data) => {
        return moment(data.value).format("YYYY-MM-DD");
      },
    },
    {
      // headerName: "log_number",
      // headerComponent: HeaderRenderer,
      headerName: "Log Number",
      field: "gunlukNo",
      filter: "agTextColumnFilter",
    },
    {
      field: "fis",
      headerName: "Voucher No",
      // headerName: "voucher_no",
      // headerComponent: HeaderRenderer,
      filter: "agTextColumnFilter",
    },
    {
      field: "hesapTipi",
      headerName: "Account Type",
      // headerName: "account_type",
      // headerComponent: HeaderRenderer,
      filter: "agTextColumnFilter",
      hide: false,
    },
    {
      headerName: "Account Code",
      // headerName: "account_code",
      // headerComponent: HeaderRenderer,
      field: "hesapKodu",
      // rowGroup: true,
      filter: "agTextColumnFilter",
      // filterParams: {
      //   values: ["120-001-001"],
      // },
      sortable: true,
    },
    {
      headerName: "Account Name",
      // headerName: "account_name",
      // headerComponent: HeaderRenderer,
      field: "name",
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "Description",
      // headerName: "description",
      // headerComponent: HeaderRenderer,
      field: "txt",
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "Currency",
      // headerName: "currency_unit_short",
      // headerComponent: HeaderRenderer,
      field: "currency",
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "Amount",
      // headerName: "amount",
      // headerComponent: HeaderRenderer,
      field: "tutar",
      filter: false,
      // aggFunc: "mySum",
      sortable: false,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "D.Try",
      // headerName: "debit_try_short",
      // headerComponent: HeaderRenderer,
      field: "borcTry",
      filter: "agNumberColumnFilter",
      // aggFunc: "mySum",
      sortable: true,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "C.Try",
      // headerName: "credit_try_short",
      // headerComponent: HeaderRenderer,
      field: "alacakTry",
      filter: "agNumberColumnFilter",
      // aggFunc: "mySum",
      sortable: true,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "A.Try",
      // headerName: "amount_try_short",
      // headerComponent: HeaderRenderer,
      field: "tutarTry",
      filter: false,
      // aggFunc: "mySum",
      sortable: false,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "D.Usd",
      // headerName: "debit_usd_short",
      // headerComponent: HeaderRenderer,
      field: "borcUsd",
      filter: "agNumberColumnFilter",
      // aggFunc: "mySum",
      sortable: true,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "C.Usd",
      // headerName: "credit_usd_short",
      // headerComponent: HeaderRenderer,
      field: "alacakUsd",
      filter: "agNumberColumnFilter",
      // aggFunc: "mySum",
      sortable: true,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "A.Usd",
      // headerName: "amount_usd_short",
      // headerComponent: HeaderRenderer,
      field: "tutarUsd",
      filter: false,
      // aggFunc: "mySum",
      sortable: false,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "Rate",
      // headerName: "currency_rate_short",
      // headerComponent: HeaderRenderer,
      field: "kur",
      filter: false,
      // aggFunc: "mySum",
      sortable: true,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
  ];

  return mainColumn;
};
export { virmanColumn, mizanColumn };
