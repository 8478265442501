import React, { useCallback, useEffect, useState } from "react";
// Soft UI component
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import ReverseRecordGrid from "./components/ReverseRecordGrid";
import CheckboxRenderer from "./components/ReverseRecordGrid/CheckboxRenderer";
import { useIntl } from "react-intl";

import GridExample from "components/avs/AvsAgGrid/ServerSide";
// utils
import { tableTypeList } from "utils/constants/recordType";

//endpoint
import { endPointMizan } from "services/axios/endpoint";

// react-router-dom components
import { Link } from "react-router-dom";

//language
import { FormattedMessage } from "react-intl";

//state
import {
  virmanSelectAtom,
  transferPeriodSelectAtom,
  isLoadingAtom,
} from "recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";

import AvsVirmanGrid from "components/avs/AvsAgGrid/AvsVirmanGrid";

//service
import axios from "services/axios/index";

import AvsSwal from "components/avs/AvsSwal";
import SwalTypes from "components/avs/AvsSwal/types";

function ReverseRecord() {
  const { formatMessage } = useIntl();
  const intl = useIntl();
  //global state
  const setIsLoading = useSetRecoilState(isLoadingAtom);
  const getTransferPeriodSelectAtom = useRecoilValue(transferPeriodSelectAtom);
  const [tableData, setTableData] = useState([]);
  const [reverseCount, setReverseCount] = useState(0);
  const [cloneCount, setCloneCount] = useState(0);

  const [state, setState] = useState({
    columnDefs: [
      {
        headerName: formatMessage({ id: "registered" }),
        field: "registered",
        cellRenderer: "checkboxRenderer",
      },
      {
        headerName: formatMessage({ id: "date" }),
        field: "date",
      },
      {
        headerName: formatMessage({ id: "dailyNo" }),
        field: "dailyNo",
      },
      {
        headerName: formatMessage({ id: "receipt_number" }),
        field: "receipt_number",
      },
      {
        headerName: formatMessage({ id: "account_type" }),
        field: "account_type",
      },
      {
        headerName: formatMessage({ id: "account_code" }),
        field: "account_code",
      },
      {
        headerName: formatMessage({ id: "account_name" }),
        field: "account_name",
      },
      {
        headerName: formatMessage({ id: "explanation" }),
        field: "explanation",
      },
      {
        headerName: formatMessage({ id: "currency_unit_short" }),
        field: "currency_unit_short",
      },
      {
        headerName: formatMessage({ id: "total" }),
        field: "total",
      },
      {
        headerName: formatMessage({ id: "debit_try" }),
        field: "debit_try",
      },
      {
        headerName: formatMessage({ id: "credit_try" }),
        field: "credit_try",
      },
      {
        headerName: formatMessage({ id: "amount_try" }),
        field: "amount_try",
      },
      {
        headerName: formatMessage({ id: "debit_usd" }),
        field: "debit_usd",
      },
      {
        headerName: formatMessage({ id: "credit_usd" }),
        field: "credit_usd",
      },
      {
        headerName: formatMessage({ id: "amount_usd_short" }),
        field: "amount_usd_short",
      },
      {
        headerName: formatMessage({ id: "currency_rate" }),
        field: "currency_rate",
      },
    ],
    defaultColDef: {
      flex: 1,
    },
    frameworkComponents: {
      checkboxRenderer: CheckboxRenderer,
    },
    rowData: [],
  });
  /**
   * rerender ReverseRecordGrid component when language changed
   */
  useEffect(() => {
    setState({
      ...state,
      columnDefs: state.columnDefs.map((col) => ({
        ...col,
        headerName: formatMessage({
          id: col.field,
          defaultMessage: col.headerName,
        }),
      })),
    });
  }, [formatMessage]);

  useEffect(() => {
    async function fetchData() {
      const postData = {
        startDate: "2022-01-01",
        endDate: "2022-01-31",
        company: "avs",
        kyn: "A",
      };
      const data = await axios
        .post(endPointMizan.mizanReelPeriodOpening, { data: postData })
        .then((res) => res.data.data);
      console.log(data);
      setTableData(data);

      const reverseCountResult = await axios
        .get("mizan/reel/mizan-count-transfer-record/avs/AAA2022/V/1/0")
        .then((res) => res.data.data);
      setReverseCount(reverseCountResult);

      const cloneCountResult = await axios
        .get("mizan/reel/mizan-count-transfer-record/avs/AAA2022/V/0/1")
        .then((res) => res.data.data);

      setCloneCount(cloneCountResult);
    }
    fetchData();
  }, []);

  const handleCreateReverse = async () => {
    setIsLoading(true);
    console.log(getTransferPeriodSelectAtom.length);
    if (getTransferPeriodSelectAtom.length === 0) {
      return AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_bad_job",
            text: "error_create_selected_item",
            setIsLoading,
          },
        },
      });
    }

    const request = await axios
      .post("/mizan/reel/create/reverse", {
        data: {
          recordIdlist: getTransferPeriodSelectAtom,
        },
      })
      .then((res) => res.data);
    if (request.success) {
      AvsSwal({
        data: {
          type: SwalTypes.success,
          intl,
          props: {
            title: "success_good_job",
            text: "success_create_reverse",
            setIsLoading,
          },
        },
      });
    } else {
      AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_bad_job",
            text: "error_create_reverse",
            setIsLoading,
          },
        },
      });
    }
  };
  const handleCreateClone = async () => {
    setIsLoading(true);
    const request = await axios
      .get(`mizan/reel/mizan-clone-transfer-record/avs/2022-01-01/2022-01-31`)
      .then((res) => res.data);
    if (request.success) {
      AvsSwal({
        data: {
          type: SwalTypes.success,
          intl,
          props: {
            title: "success_good_job",
            text: "success_create_reverse",
            setIsLoading,
          },
        },
      });
    } else {
      AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_bad_job",
            text: "error_create_reverse",
            setIsLoading,
          },
        },
      });
    }
  };

  return (
    <SuiBox>
      {/* <ReverseRecordGrid state={state} /> */}

      <SuiBox
        style={{ marginBottom: "5px" }}
        display="flex"
        justifyContent="end"
      >
        <SuiButton
          variant="gradient"
          color="info"
          style={{ marginRight: "5px" }}
          onClick={handleCreateClone}
          disabled={cloneCount > 0 ? true : false}
          // to="/updateData"
        >
          <FormattedMessage id="create_clone_record" />
        </SuiButton>
        <SuiButton
          variant="gradient"
          color="error"
          style={{ marginRight: "5px" }}
          onClick={handleCreateReverse}
          disabled={reverseCount > 0 ? true : false}
          // to="/updateData"
        >
          <FormattedMessage id="create_reverse" />
        </SuiButton>
      </SuiBox>
      <SuiBox>
        {tableData.length && (
          <AvsVirmanGrid
            data={tableData}
            tableType={"transferBalanceReverse"}
          />
        )}
      </SuiBox>
      {/* <GridExample endPoint={endPointMizan.mizanReel} tableType={"reelDonem"} /> */}
    </SuiBox>
  );
}

export default ReverseRecord;
