const recordTtype = {
  axabta: "A",
  duzeltme: "D",
  virman: "V",
};
const reportDataType = {
  axabta: "A",
  reel: "R",
};

const tableTypeList = {
  axabtaList: "AxabtaList",
  duzeltmeList: "DuzeltmeList",
  virmanList: "VirmanList",
  virmanMultiList: "VirmanMultiList",
  transferBalance: "TransferBalance",
  reelList: "ReelList",
  salesList: "SalesList",

  virmanListMultiFalse: "VirmanListMultiFalse",

  trialBalance: 0,
  balanceSheetAxaptaActive: 1,
  balanceSheetAxaptaPassive: 2,
  balanceSheetReelActive: 3,
  balanceSheeReelPassive: 4,
  incomeStatementAxapta: 5,
  incomeStatementReel: 6,
};

export { reportDataType, recordTtype, tableTypeList };
