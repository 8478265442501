/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

//intl
import { IntlProvider } from "react-intl";

//global state
import { localeAtom, mainFilterAtom } from "recoil/atoms";
import { useRecoilValue } from "recoil";

//loading spinner
import Loader from "components/avs/LoadingSpinner";

// Soft UI component
import BaseLayout from "components/LayoutContainers/BaseLayout";

// Soft UI Dashboard PRO React routes
import routes from "routes";

//localization
import { LOCALES } from "i18n/locales";
// some variable for language
import { messages } from "i18n/messages";

export default function App() {
  const { pathname } = useLocation();
  const locale = LOCALES.TURKISH;

  const globalLocale = useRecoilValue(localeAtom);
  const mainFilterState = useRecoilValue(mainFilterAtom);
  const isLoading = mainFilterState.isLoading;

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
            authed={true}
          />
        );
      }

      return null;
    });

  return (
    <IntlProvider
      locale={globalLocale}
      defaultLocale={LOCALES.ENGLISH}
      messages={messages[globalLocale]}
    >
      <Loader />
      <BaseLayout stickyNavbar>
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/menu" />} />
        </Routes>
      </BaseLayout>
    </IntlProvider>
  );
}
