import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI component
import SuiBox from "components/SuiBox";
import SuiDatePicker from "components/SuiDatePicker";
import AvsBreadcrumbs from "components/avs/AvsBreadcrumbs";
import AvsVirementDetailGrid from "components/avs/AvsAgGrid/AvsVirementDetailGrid";
import GridExample from "components/avs/AvsAgGrid/ClientSide";
import Card from "@mui/material/Card";

import { useParams, useNavigate } from "react-router-dom";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

import AvsDataTable from "components/avs/AvsDataTable";
import { useIntl } from "react-intl";
// utils
import { tableTypeList } from "utils/constants/recordType";
import { getDateFormatYearMonthDay } from "utils/helpers/dateFormat";

//service
import axios from "services/axios/index";

//state managment
import { isLoadingAtom, virmanRequestChangeDateAtom } from "recoil/atoms";
import { useSetRecoilState, useRecoilState } from "recoil";

//swal
import SwalTypes from "components/avs/AvsSwal/types";
import AvsSwal from "components/avs/AvsSwal";

function VirementRequestDetail() {
  const id = useParams().id;
  const navigate = useNavigate();
  const intl = useIntl();
  const { formatMessage } = useIntl();

  const breadcrumbRoutes = [
    {
      route: "virement/sales-request",
      label: "virement_wish_list",
    },
    {
      route: "mizan",
      label: "trial_balance",
    },
    {
      route: "menu",
      label: "finance",
    },
  ];

  //global state
  const setIsLoading = useSetRecoilState(isLoadingAtom);
  const [getVirmanRequestChangeDateState, setVirmanRequestChangeDateState] =
    useRecoilState(virmanRequestChangeDateAtom);

  const cardStyle = {
    height: "40px",
    margin: "auto",
    cursor: "pointer",
    justifyContent: "center",
  };

  const [recordDate, setRecordDate] = useState(null);
  const [vocuherList, setVocuherList] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [reverseData, setReverseData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [data, setData] = useState([]);
  const [isReverseStep, setIsReverseStep] = useState(false);
  const [isNewStep, setIsNewStep] = useState(false);

  const handleSetRecordDate = async (newDate) => setRecordDate(newDate[0]);

  useEffect(() => {
    showNewData();
  }, [recordDate]);

  useEffect(() => {
    async function fetchDataRequestVirman() {
      const request = await axios
        .get(`/mizan/reel/virman/request-sales/${id}`)
        .then((res) => res.data);
      setData(request.data);
      const vouchers = request.data.voucherList.split(",");
      setVocuherList(vouchers);
      setIsNewStep(request.data.isNewStep);
      setIsReverseStep(request.data.isReverseStep);
    }
    fetchDataRequestVirman();
  }, []);

  const handleGetMizan = async (vocuherNo) => {
    setIsLoading(true);
    setNewData([]);

    //null value get all data for every vocuher
    if (vocuherNo !== null) {
      var rdata = {
        vocuherList: [vocuherNo],
        startDate: data.startDate,
        endDate: data.endDate,
      };
      const request = await axios
        .post("/mizan/reel/mizan-list", {
          data: rdata,
        })
        .then((res) => res.data);
      setMainData([]);
      setTimeout(() => setMainData(request.data), 100);

      if (request.data.length === 0) {
        AvsSwal({
          data: {
            type: SwalTypes.error,
            intl,
            props: {
              title: "error_title",
              text: "error_receipt_number_not_found",
            },
          },
        });
      }

      let rvsData = [];
      await Promise.all(
        request.data.map((item) => {
          const newObj = {
            ...item,
            alacak: item.borc,
            alacakTry: item.borcTry,
            alacakUsd: item.borcUsd,
            borc: item.alacak,
            borcTry: item.alacakTry,
            borcUsd: item.alacakUsd,
            tutar: -1 * item.tutar,
            tutarTry: -1 * item.tutarTry,
            tutarUsd: -1 * item.tutarUsd,
          };
          rvsData.push(newObj);
        })
      );
      console.log(rvsData);
      setReverseData([]);
      setTimeout(() => setReverseData(rvsData), 100);
      setIsLoading(false);
    } else {
      var rdata = {
        vocuherList: vocuherList,
        startDate: data.startDate,
        endDate: data.endDate,
      };
      const request = await axios
        .post("/mizan//reel/mizan-list", {
          data: rdata,
        })
        .then((res) => res.data);
      console.log(request.data);
      if (request.data.length === 0) {
        AvsSwal({
          data: {
            type: SwalTypes.error,
            intl,
            props: {
              title: "error_title",
              text: "error_receipt_number_not_found",
            },
          },
        });
      }
      setMainData([]);
      setTimeout(() => setMainData(request.data), 100);

      let rvsData = [];
      await Promise.all(
        request.data.map((item) => {
          const newObj = {
            ...item,
            alacak: item.borc,
            alacakTry: item.borcTry,
            alacakUsd: item.borcUsd,
            borc: item.alacak,
            borcTry: item.alacakTry,
            borcUsd: item.alacakUsd,
            tutar: -1 * item.tutar,
            tutarTry: -1 * item.tutarTry,
            tutarUsd: -1 * item.tutarUsd,
          };
          rvsData.push(newObj);
        })
      );
      console.log(rvsData);
      setReverseData([]);
      setTimeout(() => setReverseData(rvsData), 100);
      setIsLoading(false);
    }
  };

  async function handleUpdateRequestStep(rstep, nstep) {
    console.log("handleUpdateRequestStep");
    const request = await axios
      .post("/mizan/reel/virman/update/reqeustSalesStep", {
        data: {
          id: data.id,
          isReverseStep: rstep,
          isNewStep: nstep,
        },
      })
      .then((res) => res.data);
    if (request.success) {
      AvsSwal({
        data: {
          type: SwalTypes.success,
          intl,
          props: {
            title: "success_good_job",
            text: "success_virement_update",
          },
        },
      });
    } else {
      AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_title",
            text: "error_virement_update",
          },
        },
      });
    }
    setIsLoading(false);
    console.log("handleReverseRecord");
  }

  const handleReverseRecord = async () => {
    console.log("handleReverseRecord");
    setIsLoading(true);
    const array = reverseData.map((item) => item.id);

    const request = await axios
      .post("/mizan/reel/create/reverse", {
        data: { recordIdlist: array, requestSalesId: data.id },
      })
      .then((res) => res.data);
    if (request.success) {
      setIsReverseStep(true);
      handleUpdateRequestStep(true, isNewStep);
      AvsSwal({
        data: {
          type: SwalTypes.success,
          intl,
          props: {
            title: "success_good_job",
            text: "success_reverse_record_create",
          },
        },
      });
      AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_title",
            text: "success_reverse_record_create",
          },
        },
      });
    }
    setIsLoading(false);
    console.log("handleReverseRecord");
  };
  const handleNewRecord = async () => {
    if (recordDate === null)
      return AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_title",
            text: "error_select_date",
          },
        },
      });

    if (!isReverseStep)
      return AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_title",
            text: "error_create_reverse_record_first",
          },
        },
      });
    const array = mainData.map((item) => item.id);

    const newRecord = {
      newDate: getDateFormatYearMonthDay(recordDate),
      // we can delete because change account code because we will send all data
      idList: array,
      requestSalesId: data.id,
      newDataList: getVirmanRequestChangeDateState,
    };
    console.log(newRecord);
    console.log("create sales request");
    const request = await axios
      .post("/mizan/reel/virman/create/newdate", {
        data: newRecord,
      })
      .then((res) => res.data);
    if (request.success) {
      setIsNewStep(true);
      handleUpdateRequestStep(isReverseStep, true);
      AvsSwal({
        data: {
          type: SwalTypes.success,
          intl,
          props: {
            title: "success_good_job",
            text: "success_new_record_create",
          },
        },
      });
      setTimeout(() => {
        navigate(`/virement/sales-request/edit/${id}`);
      }, 1000);
    } else {
      AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_title",
            text: "error_new_record_create",
          },
        },
      });
    }
    setIsLoading(false);
    console.log("handleReverseRecord");
  };
  const showNewData = async () => {
    setIsLoading(true);
    let nData = [];
    await Promise.all(
      mainData.map((item) => {
        const newObj = {
          ...item,
          tarih: getDateFormatYearMonthDay(recordDate),
        };
        nData.push(newObj);
      })
    );
    setNewData([]);
    setTimeout(() => {
      setNewData(nData);
      setVirmanRequestChangeDateState(nData);
    }, 100);
    setIsLoading(false);
  };

  return (
    <>
      {/* Section Breadcrumb */}
      <AvsBreadcrumbs
        title="virement_request_detail"
        currentRoute="virement_request_detail"
        otherRoutes={breadcrumbRoutes}
      />
      <SuiBox mb={10}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <SuiBox>
              <SuiInput
                label="description"
                value={data.description}
                disabled
                multiline
                rows={3}
              />
            </SuiBox>
            <SuiBox>
              <SuiTypography
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                color="dark"
                sx={{ lineHeight: 0 }}
              >
                {data.startDate} | {data.endDate}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <SuiBox display="flex" justifyContent={"center"} my={1}>
              <SuiTypography
                variant="caption"
                textTransform="capitalize"
                fontWeight="bold"
              >
                <FormattedMessage id={"receipt_number"} />
              </SuiTypography>
            </SuiBox>
            <Grid
              container
              spacing={1}
              px={2}
              display="flex"
              justifyContent="center"
            >
              {
                /**
                 * create 10 boxes
                 */

                vocuherList.map((item, index) => {
                  return (
                    <Grid item xs={6} sm={3} lg={3} key={index}>
                      <Card
                        style={cardStyle}
                        onClick={
                          !isReverseStep || !isNewStep
                            ? () => handleGetMizan(item)
                            : () => {}
                        }
                      >
                        <SuiBox px={2} textAlign="center">
                          <SuiTypography
                            variant="h6"
                            fontWeight="regular"
                            textTransform="uppercase"
                          >
                            {item}
                          </SuiTypography>
                        </SuiBox>
                      </Card>{" "}
                    </Grid>
                  );
                })
              }
              {/**
               * Modal has to be add to that component
               */}
            </Grid>
            <SuiBox display="flex" justifyContent={"center"} my={1}>
              {(!isReverseStep || !isNewStep) && (
                <SuiButton
                  variant="gradient"
                  color="dark"
                  onClick={() => handleGetMizan(null)}
                >
                  <FormattedMessage id="list_all" />
                </SuiButton>
              )}
            </SuiBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {mainData.length > 0 && (
              <>
                <SuiBox
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <SuiTypography
                    variant="h5"
                    textTransform="capitalize"
                    fontWeight="bold"
                    color="dark"
                  >
                    <FormattedMessage id={"main_record"} />
                  </SuiTypography>

                  <SuiBox display="flex"></SuiBox>
                </SuiBox>
                <GridExample
                  data={mainData}
                  height="400"
                  setT
                  tableType={tableTypeList.axabtaList}
                />
              </>
            )}

            {reverseData.length > 0 && (
              <SuiBox mt={2}>
                <SuiBox
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <SuiTypography
                    variant="h5"
                    textTransform="capitalize"
                    fontWeight="bold"
                    color="dark"
                  >
                    <FormattedMessage id={"reverse_record"} />
                  </SuiTypography>
                  {/**
                   * TODO : onclick event inside suidatepicker component must be callback function
                   */}
                  <SuiBox display="flex">
                    {!isReverseStep && (
                      <SuiButton
                        variant="gradient"
                        color="dark"
                        onClick={handleReverseRecord}
                      >
                        <FormattedMessage id={"create"} />
                      </SuiButton>
                    )}
                  </SuiBox>
                </SuiBox>
                <GridExample
                  setT
                  data={reverseData}
                  height="400"
                  tableType={tableTypeList.axabtaList}
                />
              </SuiBox>
            )}

            {reverseData.length > 0 && (
              <SuiBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  mb: 2,
                }}
              >
                <SuiTypography
                  variant="h5"
                  textTransform="capitalize"
                  fontWeight="bold"
                  color="dark"
                >
                  <FormattedMessage id={"virman_record"} />
                </SuiTypography>
                {/**
                 * TODO : onclick event inside suidatepicker component must be callback function
                 */}
                {!isNewStep && (
                  <SuiBox display="flex" alignItems="end">
                    {/**
                     * If there is a date, then we show the date picker, otherwise we show only the button
                     * If we give buttonName as "", then we don't show the button
                     * otherwise we show the button with the given name
                     */}
                    <SuiBox mr={2}>
                      <SuiDatePicker
                        label="date"
                        buttonName={"create"}
                        value={recordDate}
                        onChange={handleSetRecordDate}
                      />
                    </SuiBox>
                    <SuiButton
                      variant="gradient"
                      color="dark"
                      style={{ height: "40.8px" }}
                      onClick={handleNewRecord}
                    >
                      <FormattedMessage id={"create"} />
                    </SuiButton>
                  </SuiBox>
                )}
              </SuiBox>
            )}
            {newData.length > 0 && (
              <GridExample
                setT
                data={newData}
                height="400"
                tableType={tableTypeList.axabtaList}
              />
            )}
            {/* <AvsVirementDetailGrid
            title="reverse_record"
            button_name="create"
            setT
          />
          <AvsVirementDetailGrid
            title="virman_record"
            button_name="create"
            date
            setT
          /> */}
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
}

export default VirementRequestDetail;
