import moment from "moment";

//localization
import { LOCALES } from "i18n/locales";

import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

//end date must be add one day . fo to day record filter
let today = moment();
let tomorrow = moment().add(1, "days");

export const localeAtom = atom({
  key: "localeState",
  default: LOCALES.ENGLISH,
  effects_UNSTABLE: [persistAtom],
});

export const axabtaMizanAtom = atom({
  key: "axabtaMizanState",
  default: [],
  // effects_UNSTABLE: [persistAtom],
});
export const duzeltmeKayitAtom = atom({
  key: "duzeltmeKayitState",
  default: [],
  effects_UNSTABLE: [persistAtom],
});
export const virmanKayitAtom = atom({
  key: "virmanState",
  default: [],
  // effects_UNSTABLE: [persistAtom],
});
export const virmanSelectAtom = atom({
  key: "virmanSelectState",
  default: [],
  effects_UNSTABLE: [persistAtom],
});
export const virmanRequestChangeDateAtom = atom({
  key: "virmanRequestChangeDateState",
  default: [],
  effects_UNSTABLE: [persistAtom],
});
export const transferPeriodSelectAtom = atom({
  key: "transferPeriodSelectState",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const reelKayitAtom = atom({
  key: "reelState",
  default: [],
  // effects_UNSTABLE: [persistAtom],
});

export const mainFilterAtom = atom({
  key: "mainFilterState",
  default: {
    company: "avs",
    startDate: today,
    endDate: tomorrow,
    accountPlan: [6],
  },
  effects_UNSTABLE: [persistAtom],
});
export const isLoadingAtom = atom({
  key: "isLoadingState",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const activeTabAtom = atom({
  key: "activeTabState",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const activeTabListAtom = atom({
  key: "activeTabListState",
  default: {
    mizan: 0,
    sales: 0,
  },
  effects_UNSTABLE: [persistAtom],
});

export const buttonActionAtom = atom({
  key: "buttonActionState",
  default: {
    exportTable: false,
  },
  effects_UNSTABLE: [persistAtom],
});
export const agGridServerSideAtom = atom({
  key: "agGridServerSide",
  default: {
    isRefresh: false,
  },
  effects_UNSTABLE: [persistAtom],
});

export const footerTotalAtom = atom({
  key: "footerTotalState",
  default: {
    duzeltmeKayitTtl: {
      totalBorcTry: 0,
      totalAlacakTry: 0,
      totalTry: 0,
      totalBorcUsd: 0,
      totalAlacakUsd: 0,
      totalUsd: 0,
    },
    virmanKayitTtl: {
      totalBorcTry: 0,
      totalAlacakTry: 0,
      totalTry: 0,
      totalBorcUsd: 0,
      totalAlacakUsd: 0,
      totalUsd: 0,
    },
    reelKayitTtl: {
      totalBorcTry: 0,
      totalAlacakTry: 0,
      totalTry: 0,
      totalBorcUsd: 0,
      totalAlacakUsd: 0,
      totalUsd: 0,
    },
    axabtaKayitTtl: {
      totalBorcTry: 0,
      totalAlacakTry: 0,
      totalTry: 0,
      totalBorcUsd: 0,
      totalAlacakUsd: 0,
      totalUsd: 0,
    },
  },
  effects_UNSTABLE: [persistAtom],
});
