import React from "react";

import { useParams, useNavigate } from "react-router-dom";

// Soft UI component
import SuiBox from "components/SuiBox";

// Avs  UI component
import AvsBreadcrumbs from "components/avs/AvsBreadcrumbs";
import AvsDataEntryEdit from "components/avs/AvsDataEntry/AvsDataEntryEdit";

function WithRecord() {
  const breadcrumbRoutes = [
    {
      route: "mizan",
      label: "trial_balance",
    },
    {
      route: "menu",
      label: "finance",
    },
  ];

  let { id } = useParams();
  const navigate = useNavigate();

  return (
    <SuiBox>
      {/* Section Breadcrumb */}
      <AvsBreadcrumbs
        title="create_axabta_virement"
        currentRoute="create_axabta_virement"
        otherRoutes={breadcrumbRoutes}
      />
      <AvsDataEntryEdit isVirman={true} id={id} />
    </SuiBox>
  );
}

export default WithRecord;
