import { getDateFormatYearMonthDay } from "utils/helpers/dateFormat";

const endPointMizan = {
  mainAccount: "/main_account",
  mizanReel: "/mizan/reel",
  mizanReelPeriodOpening: "/mizan/reel/mizan-list-period-opening",
  mizanReelMizanDescription: "/mizan/reel/mizan_description",
  mizanReelIncomeStatement: "/mizan/reel/income_statement",
  mizanReelBalanceSheet: "/mizan/reel/balance_sheet",
  mizanReelCreate: "/mizan/reel/create",
  mizanReelAxabtaDiff: "/mizan/reel-axabta-diff",
  mizanReelDataCount: "/mizan/reel/data-count",
  mizanReelDelCreate: "/mizan/reel/del_create_bulk",
  mizanAxabta: "/mizan/axabta",
  mizanAxabta2: "/mizan/axabta2",
  mizanVirman: "/mizan/reel/virman",
  mizanVirmanMultiSingle: "/mizan/reel/virman-multi-single",
  mizanTransferBalance: "/mizan/reel/tranfer-balance",
  postingType: "posting_type",
};

const endPointCurrencyRate = {
  currency_rate: "/currency_rate",
  currency_rate_sgd: "https://api.exchangerate.host/timeseries",
};
const getEndPointCurrencyRate = (rate_date, currency_type) => {
  return `${endPointCurrencyRate.currency_rate}?rate_date=${rate_date}&currency_type=${currency_type}`;
};
const getEndPointCurrencyRateSgd = (sgdDate) => {
  return `${endPointCurrencyRate.currency_rate_sgd}?start_date=${sgdDate}&end_date=${sgdDate}&symbols=USD,TRY&base=SGD`;
};
const getEndPointDuzeltmeKayitList = (
  company,
  startDate,
  endDate,
  accountPlan
) => {
  // var sDate = moment(startDate).format("YYYY-MM-DD");
  // var eDate = moment(endDate).format("YYYY-MM-DD");
  var sDate = getDateFormatYearMonthDay(startDate);
  var eDate = getDateFormatYearMonthDay(endDate);
  return `${endPointMizan.mizanReel}?company_name=${company}&start_date=${sDate}&end_date=${eDate}&kyn=D&account_plan=${accountPlan}`;
};

const getEndPointAxabtaKayitList = (
  company,
  startDate,
  endDate,
  accountPlan
) => {
  var sDate = getDateFormatYearMonthDay(startDate);
  var eDate = getDateFormatYearMonthDay(endDate);

  return `${endPointMizan.mizanAxabta}?company_name=${company}&start_date=${sDate}&end_date=${eDate}&account_plan=${accountPlan}`;
};

const getEndPointVirmanKayitList = (
  company,
  startDate,
  endDate,
  accountPlan
) => {
  var sDate = getDateFormatYearMonthDay(startDate);
  var eDate = getDateFormatYearMonthDay(endDate);

  return `${endPointMizan.mizanReel}?company_name=${company}&start_date=${sDate}&end_date=${eDate}&kyn=V&account_plan=${accountPlan}`;
};

const getEndPointReelKayitList = (company, startDate, endDate, accountPlan) => {
  var sDate = getDateFormatYearMonthDay(startDate);
  var eDate = getDateFormatYearMonthDay(endDate);

  return `${endPointMizan.mizanReel}?company_name=${company}&start_date=${sDate}&end_date=${eDate}&account_plan=${accountPlan}`;
};

const getEndPointReelKayitDeleteAndCreateBulk = (
  company,
  startDate,
  endDate,
  kyn
) => {
  var sDate = getDateFormatYearMonthDay(startDate);
  var eDate = getDateFormatYearMonthDay(endDate);

  return `${endPointMizan.mizanReelDelCreate}/${company}/${sDate}/${eDate}/${kyn}`;
};
const getEndPointReelAxaptaDiff = (company, startDate, endDate, kyn) => {
  var sDate = getDateFormatYearMonthDay(startDate);
  var eDate = getDateFormatYearMonthDay(endDate);
  return `${endPointMizan.mizanReelAxabtaDiff}/${company}/${sDate}/${eDate}/${kyn}`;
};

const getEndPointReelDataCount = (company, startDate, endDate, kyn) => {
  var sDate = getDateFormatYearMonthDay(startDate);
  var eDate = getDateFormatYearMonthDay(endDate);
  return `${endPointMizan.mizanReelDataCount}/${company}/${sDate}/${eDate}/${kyn}`;
};
const getEndPointReelMizanDescription = (
  company,
  startDate,
  endDate,
  mType
) => {
  var eDate = getDateFormatYearMonthDay(endDate);

  return `${endPointMizan.mizanReelMizanDescription}/${company}/${startDate}/${eDate}/${mType}`;
};
const getEndPointReelIncomeStatement = (company, startDate, endDate, mType) => {
  var eDate = getDateFormatYearMonthDay(endDate);
  return `${endPointMizan.mizanReelIncomeStatement}/${company}/${startDate}/${eDate}/${mType}`;
};
const getEndPointReelBalanceSheet = (
  company,
  startDate,
  endDate,
  mType,
  sts
) => {
  var eDate = getDateFormatYearMonthDay(endDate);
  return `${endPointMizan.mizanReelBalanceSheet}/${company}/${startDate}/${eDate}/${mType}/${sts}`;
};

const endPointSalesPurchase = {
  purchaseSalesGetReel: "/purchaseSales/getReel",
  purchaseSalesDelete: "/purchaseSales/delete",
  purchaseSalesDeleteToggle: "/purchaseSales/deleteToggle",
  purchaseSalesUpdateSingleOneItem: "/purchaseSales/updateSingleOneItem",
  purchaseSalesCreateSingle: "/purchaseSales/createSingle",
};

export {
  //mizan
  endPointMizan,
  getEndPointCurrencyRate,
  getEndPointCurrencyRateSgd,
  getEndPointDuzeltmeKayitList,
  getEndPointAxabtaKayitList,
  getEndPointVirmanKayitList,
  getEndPointReelKayitList,
  getEndPointReelKayitDeleteAndCreateBulk,
  getEndPointReelAxaptaDiff,
  getEndPointReelDataCount,
  getEndPointReelMizanDescription,
  getEndPointReelIncomeStatement,
  getEndPointReelBalanceSheet,
  //salespurchase
  endPointSalesPurchase,
};
