import React from "react";
// react-router components
import Link from "@mui/material/Link";

const MainBanner = () => (
  <div
    style={{
      display: "flex",
    //  position: "absolute",
      top: 0,
      left: 0,
      justifyContent: "center",
      alignSelf: "center",
    }}
  >
    <Link href="/">
      <img src="/logo_avs.png" alt="AVS Logo" height={"57px"} />
    </Link>
  </div>
);

export default MainBanner;
