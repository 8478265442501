import React from "react";

import { useState, useEffect, useCallback } from "react";

//xls
import * as XLSX from "xlsx";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//moment
import moment from "moment";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import AvsDropzone from "components/avs/AvsDropzone";
import SuiButton from "components/SuiButton";

// Avs  UI component
import AvsBreadcrumbs from "components/avs/AvsBreadcrumbs";
import AvsDataTable from "components/avs/AvsDataTable/index";

//state
import { mainFilterAtom } from "recoil/atoms";
import { useRecoilState } from "recoil";

// utils
import { recordTtype } from "utils/constants/recordType";

//service
import axios from "services/axios/index";
import { endPointMizan } from "services/axios/endpoint";

//actions
import { useTableActions } from "actions/tableAction";
import AvsSwal from "components/avs/AvsSwal";
import SwalTypes from "components/avs/AvsSwal/types";
import { useIntl } from "react-intl";

function DuzeltmeKayitImport() {
  //local state
  const [data, setData] = useState([]);
  // on change states
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);

  // submit
  const [excelData, setExcelData] = useState(null);

  //global state
  const [mainFilterState, setMainFilterState] = useRecoilState(mainFilterAtom);

  //use table action
  const tableAction = useTableActions();

  // handle File
  const fileType = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const { formatMessage } = useIntl();
  const intl = useIntl();

  const handleFile = (selectedFile) => {
    //let selectedFile = e.target.files[0];
    if (selectedFile) {
      // console.log(selectedFile.type);
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);
          setExcelFile(e.target.result);
        };
      } else {
        AvsSwal({
          data: {
            type: SwalTypes.error,
            intl,
            props: {
              title: "error_title",
              text: "error_select_only_excel_file_type",
            },
          },
        });
        setExcelFileError(
          formatMessage({ id: "error_select_only_excel_file_type" })
        );
        setExcelFile(null);
      }
    } else {
      console.log("plz select your file");
    }
  };

  // submit function
  const handleSubmit = (e) => {
    e.preventDefault();

    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      //console.log(data);
      //setData(data);
      setExcelData(data);
    } else {
      console.log("Please select file select");
      setExcelData(null);
    }
  };

  //handleSendToReel
  const handleSendToReel = async () => {
    AvsSwal({
      data: {
        type: SwalTypes.sendToReel,
        intl,
        props: {
          mainFilterState,
          endPointMizan,
          data,
          tableAction,
          setData,
          setExcelData,
          setExcelFile,
        },
      },
    });
  };

  useEffect(() => {
    let newData = [];
    excelData?.map((data) => {
      console.log(new Date(data.tarih));
      data["kyn"] = recordTtype.duzeltme;
      data["isImport"] = true;
      data["dataAreaId"] = mainFilterState?.company;
      data["tutar"] = data.borc - data.alacak;
      data["tutarTry"] = data.borcTry - data.alacakTry;
      data["tutarUsd"] = data.borcUsd - data.alacakUsd;
      data["tarih"] = moment(data.tarih, "DD.MM.YYYY").toDate();
      data["vadeTarihi"] = moment(data.vadeTarihi, "DD.MM.YYYY").toDate();
      newData.push(data);
    });
    setData(newData);
    console.log(newData);
  }, [excelData]);

  const handleOnDrop = useCallback(
    (file) => {
      handleFile(file[0]);
    },
    [excelFile]
  );

  //create breadcrumbs
  const breadcrumbRoutes = [
    {
      route: "mizan",
      label: "mizan",
    },
    {
      route: "menu",
      label: "Finance",
    },
  ];

  return (
    <SuiBox>
      {/* Section Breadcrumb */}
      <AvsBreadcrumbs
        title="Duzeltme Kayit Import"
        currentRoute="Duzeltme Kayit Import"
        otherRoutes={breadcrumbRoutes}
      />
      <SuiBox mt={2} mb={4}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card sx={{ overflow: "visible" }}>
              <SuiBox p={2} lineHeight={1}>
                <SuiBox>
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <AvsDropzone onDrop={handleOnDrop} />
                  </SuiBox>
                </SuiBox>
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  {/* <SuiBox mr={1}>
                    <SuiButton color="light">cancel</SuiButton>
                  </SuiBox> */}

                  <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={handleSubmit}
                  >
                    Show Table
                  </SuiButton>
                  {excelData && (
                    <SuiBox ml={3}>
                      <SuiButton
                        variant="gradient"
                        color="success"
                        onClick={handleSendToReel}
                      >
                        Send To Reel DB
                      </SuiButton>
                    </SuiBox>
                  )}
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <AvsDataTable isCheck={false} isInitalGroup={false} tableData={data} />
    </SuiBox>
  );
}

export default DuzeltmeKayitImport;
