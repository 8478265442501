import { useEffect } from "react";
import { useRecoilState } from "recoil";

//state
import {
  mainFilterAtom,
  footerTotalAtom,
  duzeltmeKayitAtom,
  axabtaMizanAtom,
  virmanKayitAtom,
  reelKayitAtom,
} from "recoil/atoms";

//service axios
import axios from "services/axios/index";
import {
  getEndPointDuzeltmeKayitList,
  getEndPointAxabtaKayitList,
  getEndPointVirmanKayitList,
  getEndPointReelKayitList,
} from "services/axios/endpoint";

export { useTableActions };

const useTableActions = () => {
  const [mainFilterState, setMainFilterState] = useRecoilState(mainFilterAtom);
  const [footerTotalState, setFooterTotalState] =
    useRecoilState(footerTotalAtom);

  //table atom
  const [duzeltmeKayitListState, setDuzeltmeKayitListState] =
    useRecoilState(duzeltmeKayitAtom);

  const [virmanKayitListState, setVirmanKayitAtomListState] =
    useRecoilState(virmanKayitAtom);
  const [reelKayitListState, setReelKayitListState] =
    useRecoilState(reelKayitAtom);

  const [axabtaMizanListState, setAxabtaMizanListState] =
    useRecoilState(axabtaMizanAtom);

  useEffect(() => {
    console.log("useTableActions useEffect run");
    // refreshDuzeltmeKayit();
    // refreshAxabtaKayit();
    // refreshVirmanKayit();
    // refreshReelKayit();
  }, [mainFilterState]);

  return {
    refreshDuzeltmeKayit,
    refreshAxabtaKayit,
    refreshVirmanKayit,
    refreshReelKayit,
  };

  async function refreshReelKayit() {
    //create endpoint
    const reelUrl = await getEndPointReelKayitList(
      mainFilterState.company,
      mainFilterState.startDate,
      mainFilterState.endDate,
      mainFilterState.accountPlan
    );

    //console.log(reelUrl);

    //get reel kayit list
    const response = await axios.get(reelUrl);
    const result = await response.data;
    const dataReelKayitList = result.data;
    console.log("refreshReelKayit");

    //set reel kayit atom
    setReelKayitListState([...dataReelKayitList]);

    //dataKey same in footerTotalAtom default value
    // footerTotal("reelKayitTtl", dataReelKayitList);
  }

  async function refreshDuzeltmeKayit() {
    //create endpoint
    const duzeltmeUrl = await getEndPointDuzeltmeKayitList(
      mainFilterState.company,
      mainFilterState.startDate,
      mainFilterState.endDate,
      mainFilterState.accountPlan
    );

    //console.log(duzeltmeUrl);

    //get duzeltme kayit list
    const response = await axios.get(duzeltmeUrl);
    const result = await response.data;
    const dataDuzletmeKayitList = result.data;
    // console.log("refreshDuzeltmeKayit");

    //set duzeltme kayit atom
    setDuzeltmeKayitListState([...dataDuzletmeKayitList]);

    //dataKey same in footerTotalAtom default value
    //footerTotal("duzeltmeKayitTtl", dataDuzletmeKayitList);
  }

  async function refreshVirmanKayit() {
    //create endpoint
    const virmanUrl = await getEndPointVirmanKayitList(
      mainFilterState.company,
      mainFilterState.startDate,
      mainFilterState.endDate,
      mainFilterState.accountPlan
    );

    //console.log(duzeltmeUrl);

    //get virman kayit list
    const response = await axios.get(virmanUrl);
    const result = await response.data;
    const dataVirmanKayitList = result.data;
    // console.log("refreshVirmanKayit");

    //set virman kayit atom
    setVirmanKayitAtomListState([...dataVirmanKayitList]);

    //dataKey same in footerTotalAtom default value
    //footerTotal("virmanKayitTtl", dataVirmanKayitList);
  }

  async function refreshAxabtaKayit() {
    //create endpoint
    const axabtaUrl = await getEndPointAxabtaKayitList(
      mainFilterState.company,
      mainFilterState.startDate,
      mainFilterState.endDate,
      mainFilterState.accountPlan
    );

    console.log(axabtaUrl);

    //get duzeltme kayit list
    const response = await axios.get(axabtaUrl);
    const result = await response.data;
    const dataAxabtaKayitList = result.data;
    // console.log("refresh Axabta Kayit");
    //set duzeltme kayit atom
    setAxabtaMizanListState([...dataAxabtaKayitList]);

    //dataKey same in footerTotalAtom default value
    //footerTotal("axabtaKayitTtl", dataAxabtaKayitList);
  }

  async function footerTotal(dKey, data) {
    // console.log("footerTotal : ", dKey);

    // const sumObj = await data.reduce(
    //   (a, o) => (
    //     Object.entries(o).forEach(
    //       ([k, v]) => (
    //         (a[k] = { ...(a[k] ?? { sum: 0, total: 0 }) }),
    //         (a[k]["sum"] += v),
    //         (a[k]["total"] += 1)
    //       ),
    //     ),
    //     a
    //   ),
    //   {},
    // );

    const totalBorcTry = await data.reduce(
      (total, obj) => obj.borcTry + total,
      0
    );
    const totalAlacakTry = await data.reduce(
      (total, obj) => obj.alacakTry + total,
      0
    );
    const totalTry = await data.reduce((total, obj) => obj.tutarTry + total, 0);
    const totalBorcUsd = await data.reduce(
      (total, obj) => obj.borcUsd + total,
      0
    );
    const totalAlacakUsd = await data.reduce(
      (total, obj) => obj.alacakUsd + total,
      0
    );
    const totalUsd = await data.reduce((total, obj) => obj.tutarUsd + total, 0);

    // console.log(totalBorcTry);
    setFooterTotalState((s) => {
      return {
        ...s,
        [dKey]: {
          totalBorcTry,
          totalAlacakTry,
          totalTry,
          totalBorcUsd,
          totalAlacakUsd,
          totalUsd,
        },
      };
    });
  }
};
