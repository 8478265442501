import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { LicenseManager } from "ag-grid-enterprise";

import "./styles.css";

//service axios
import axios from "services/axios/index";
import moment from "moment";

// //state
// import {
//   footerTotalAtom,
//   mainFilterAtom,
//   virmanSelectAtom,
// } from "recoil/atoms";
// import { useSetRecoilState, useRecoilValue } from "recoil";

//state
import { virmanSelectAtom, transferPeriodSelectAtom } from "recoil/atoms";
import { useSetRecoilState } from "recoil";

//table column
import {
  virmanColumn,
  mizanColumn,
} from "components/avs/AvsAgGrid/AvsVirmanGrid/column";

// utils
import { tableTypeList } from "utils/constants/recordType";
import { _ } from "ag-grid-community";

LicenseManager.setLicenseKey(
  "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-4_September_2022_[v2]_MTY2MjI0NjAwMDAwMA==a44b97b1205cafc9e866fb63f3524402"
);

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};

const GridExample = ({ data, tableType }) => {
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState(data);
  const [columnDefs, setColumnDefs] = useState(mizanColumn());

  const setVirmanSelect = useSetRecoilState(virmanSelectAtom);
  const setTransferPeriodSelect = useSetRecoilState(transferPeriodSelectAtom);

  useEffect(() => {
    switch (tableType) {
      case "requestVirman":
        setColumnDefs(virmanColumn());
        break;
      case "transferBalanceReverse":
        setColumnDefs(mizanColumn());
        break;
    }
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      resizable: true,
      floatingFilter: false,
      filter: true,
      sortable: true,
    };
  }, []);
  const isRowSelectable = useMemo(() => {
    return (params) => {
      return !!params.data && params.data.year === 2012;
    };
  }, []);

  const onGridReady = useCallback((params) => {
    setRowData(data);
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    // gridRef.current.api.forEachNode((node) =>
    //   node.setSelected(!!node.data && node.data.year === 2012)
    // );
  }, []);

  function onSelectionChanged() {
    switch (tableType) {
      case "requestVirman":
        const selectedRows = gridRef.current.api.getSelectedRows();
        const selectIdList = selectedRows.map((item) => item.VOUCHER);
        setVirmanSelect(selectIdList);
        break;
      case "transferBalanceReverse":
        const selectedRows2 = gridRef.current.api.getSelectedRows();
        const selectIdList2 = selectedRows2.map((item) => item.id);
        setTransferPeriodSelect(selectIdList2);
        break;
    }
  }

  return (
    <div style={{ height: "550px" }}>
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            // isRowSelectable={isRowSelectable}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            // onFirstDataRendered={onFirstDataRendered}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

export default GridExample;
