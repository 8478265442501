import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { isLoadingAtom } from "recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";

export default function Loader() {
  const getIsLoading = useRecoilValue(isLoadingAtom);
  const setIsLoading = useSetRecoilState(isLoadingAtom);
  const handleClose = () => {
    setIsLoading(false);
    console.log(getIsLoading);
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={getIsLoading}
        onClick={() => handleClose()}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
