import React, { Fragment, useEffect } from "react";

import { useExportData } from "react-table-plugins";
import * as XLSX from "xlsx";

//import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  useGroupBy,
  useRowSelect,
  usePagination,
  useMountedLayoutEffect,
} from "react-table";
// import { Table } from "reactstrap";
import {
  Filter,
  DefaultColumnFilter,
} from "components/avs/AvsDataTable/filters";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// @mui material components
import Icon from "@mui/material/Icon";

// utils
import { recordTtype, tableTypeList } from "utils/constants/recordType";

//axios
import axios from "services/axios/index";
import { endPointMizan } from "services/axios/endpoint";
import { useTableActions } from "actions/tableAction";

//state
import { buttonActionAtom } from "recoil/atoms";
import { useRecoilState } from "recoil";
import AvsSwal from "components/avs/AvsSwal";
import SwalTypes from "components/avs/AvsSwal/types";
import { useIntl } from "react-intl";

const Styles = styled.div`
  padding: 1rem 0;
  ${
    "" /* These styles are suggested for the table fill all available space in its containing element */
  }
  display: block;
  ${
    "" /* These styles are required for a horizontaly scrollable table overflow */
  }
  overflow: auto;

  table {
    border-spacing: 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 12px;

    thead {
      ${
        "" /* These styles are required for a scrollable body to align with the header properly */
      }
      overflow-y: auto;
      overflow-x: hidden;
    }

    tbody {
      ${"" /* These styles are required for a scrollable table body */}
      overflow-y: scroll;
      overflow-x: hidden;
      height: 250px;

      //for tbody scroll
      // height: calc(100vh - 450px); // Your prefered height here
      // table-layout: fixed;
      // display: block;
      // overflow-y: auto;
      // overflow-x: hidden;
    }
    //for tbody scroll
    // thead,
    // tbody tr {
    //   display: table;
    //   width: 100%;
    //   table-layout: fixed;
    // }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      border-bottom: 1px solid #ccc;
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      // border-right: 1px solid #ccc;
      font-size: 12px;
      ${
        "" /* In this example we use an absolutely position resizer,
       so this is required. */
      }
      position: relative;

      :last-child {
        border-right: 0;
      }

      .resizer {
        right: 0;
        background: blue;
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action :none;

        &.isResizing {
          background: red;
        }
      }
    }
  }
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const handleMultiVirmanShow = (navigate, trsNo) => {
  navigate(`/mutiple-virman-edit/${trsNo}`);
};

const handleChangeAxabtaRecordForVirman = (navigate, id) => {
  navigate(`/virman/with-record/${id}`);
};

/*
 * excel export . You must add header style
 */
function getExportFileBlob({ columns, data, fileType, fileName }) {
  // XLSX example

  const header = columns.map((c) => c.exportValue);
  const compatibleData = data.map((row) => {
    const obj = {};
    header.forEach((col, index) => {
      obj[col] = row[index];
    });
    return obj;
  });

  let wb = XLSX.utils.book_new();
  let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
    header,
  });
  XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
  XLSX.writeFile(wb, `${fileName}.xlsx`);

  // Returning false as downloading of file is already taken care of
  return false;
}

const TableContainer = ({
  columns,
  tableData,
  isCheck,
  isInitalGroup,
  isKyn,
  tableType,
  setVirmanSelect,
  selectedRows,
  onSelectedRowsChange,
}) => {
  const data = React.useMemo(() => tableData, [tableData]);
  const tableActions = useTableActions();
  const navigate = useNavigate();
  const intl = useIntl();
  //delete handle function . You must global
  const handleDeleteWithFis = (fisNo, tAction) => {
    //console.log(fisNo);
    AvsSwal({
      data: {
        type: SwalTypes.fisDelete,
        intl,
        props: {
          endPointMizan,
          fisNo,
          tAction,
        },
      },
    });
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    // visibleColumns,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    selectedFlatRows,
    setGroupBy,
    exportData,
    state: { groupBy, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      getExportFileBlob,
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
      initialState: {
        pageIndex: 0,
        pageSize: 100,
        groupBy: isInitalGroup ? ["hesapKodu"] : [],
        hiddenColumns: [
          "isMainAccount",
          "trsNo",
          "isCredit",
          "isVirmanRelation",
          "id",
          !isKyn ? "kyn" : "",
        ],
        selectedRowIds: selectedRows,
      },
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExportData,
    useExpanded,
    usePagination,
    useRowSelect,

    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        // Let's make a column for selection
        {
          id: "action",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: () => <div style={{ width: "50px" }}>#</div>,
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <>
              {/* {row.values.isCredit &&
              row.values.kyn === recordTtype.virman &&
              tableType === tableTypeList.virmanList ? (
                <Icon
                  fontSize="medium"
                  color="info"
                  onClick={() =>
                    handleDeleteWithFis(row.values.fis, tableActions)
                  }
                >
                  edit
                </Icon>
              ) : (
                ""
              )} */}
              {row.values.kyn === recordTtype.axabta &&
              tableType === tableTypeList.reelList ? (
                <Icon
                  fontSize="medium"
                  color="info"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleChangeAxabtaRecordForVirman(
                      navigate,
                      row.values.id,
                      tableData
                    )
                  }
                >
                  published_with_changes
                </Icon>
              ) : (
                ""
              )}
              {row.values.isVirmanRelation &&
              row.values.isCredit &&
              row.values.kyn === recordTtype.virman ? (
                <Icon
                  fontSize="medium"
                  color="info"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleMultiVirmanShow(navigate, row.values.trsNo)
                  }
                >
                  attachment
                </Icon>
              ) : (
                ""
              )}
              {row.values.isMainAccount &&
              row.values.kyn === recordTtype.duzeltme ? (
                <Icon
                  fontSize="medium"
                  color="error"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleDeleteWithFis(row.values.fis, tableActions)
                  }
                >
                  delete
                </Icon>
              ) : (
                ""
              )}
              {!row.values.isVirmanRelation &&
              row.values.isCredit &&
              tableType === tableTypeList.virmanList ? (
                <Icon
                  fontSize="medium"
                  color="error"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleDeleteWithFis(row.values.fis, tableActions)
                  }
                >
                  delete
                </Icon>
              ) : (
                ""
              )}
            </>
          ),
        },
      ]);
    },
    isCheck
      ? (hooks) => {
          hooks.visibleColumns.push((columns) => [
            // Let's make a column for selection
            {
              id: "selection",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ]);
        }
      : ""
  );
  //global state
  const [buttonActionState, setButtonActionState] =
    useRecoilState(buttonActionAtom);

  //excel export when global change state
  useEffect(() => {
    async function excelExport() {
      await exportData("xlsx", false);
      setButtonActionState((s) => {
        return {
          ...s,
          exportTable: false,
        };
      });
    }

    if (buttonActionState.exportTable) {
      excelExport();
    }
  }, [buttonActionState]);

  useMountedLayoutEffect(() => {
    const selectedIds = Object.keys(selectedRowIds);
    var selectedRowsData = selectedIds
      .map((x) => tableData[x]["id"])
      .filter(function (x) {
        return x != null;
      });
    setVirmanSelect(selectedRowsData);
    onSelectedRowsChange && onSelectedRowsChange(selectedRowIds);
  }, [onSelectedRowsChange, selectedRowIds]);

  // console.log(tableType, tableTypeList.virmanList);
  // console.log("React Table");
  //  group permission check
  // useEffect(() => {
  //   isInitalGroup ? setGroupBy(["phone"]) : setGroupBy([]);
  // }, [groupBy]);

  // const generateSortingIndicator = (column) => {
  //   return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  // };

  // const onChangeInSelect = (event) => {
  //   setPageSize(Number(event.target.value));
  // };

  // const onChangeInInput = (event) => {
  //   const page = event.target.value ? Number(event.target.value) - 1 : 0;
  //   gotoPage(page);
  // };
  /*
   * have two way excel export data . 1 - react table export and html to excel export
   * select one way after delete other way
   */
  return (
    <>
      {/* <button
        onClick={() => {
          exportData("xlsx", false);
        }}
      >
        Export Current View as xlsx
      </button>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename="tablexls"
        sheet="tablexls"
        buttonText="Download as XLS"
      /> */}
      <Styles>
        <table id="table-to-xls" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <div {...column.getSortByToggleProps()}>
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                      {/* {column.canGroupBy ? (
                      // If the column can be grouped, let's add a toggle
                      <span {...column.getGroupByToggleProps()}>
                        {column.isGrouped ? "🛑" : "👊"}
                      </span>
                    ) : null} */}
                      {column.render("Header")}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr
                    {...row.getToggleRowExpandedProps()}
                    {...row.getRowProps()}
                    style={{
                      cursor: "pointer",
                      color:
                        row.values.kyn === "D"
                          ? "red"
                          : row.values.kyn === "V"
                          ? "blue"
                          : "black",
                    }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          style={{
                            width: cell.isGrouped ? "150px" : "",
                            color: cell.isGrouped
                              ? "white"
                              : cell.isAggregated
                              ? "black"
                              : cell.isPlaceholder
                              ? "black"
                              : "black",
                            background: cell.isGrouped
                              ? "#155489"
                              : cell.isAggregated
                              ? "white"
                              : cell.isPlaceholder
                              ? "white"
                              : "white",
                          }}
                          {...cell.getCellProps()}
                        >
                          {cell.isGrouped ? (
                            // If it's a grouped cell, add an expander and row count
                            <>
                              {/* <span {...row.getToggleRowExpandedProps()}> */}
                              <span>
                                {row.isExpanded ? (
                                  <KeyboardArrowDownIcon />
                                ) : (
                                  <ChevronRightIcon />
                                )}
                              </span>{" "}
                              {cell.render("Cell")} ({row.subRows.length})
                            </>
                          ) : cell.isAggregated ? (
                            // If the cell is aggregated, use the Aggregated
                            // renderer for cell
                            cell.render("Aggregated")
                          ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                            // Otherwise, just render the regular cell
                            cell.render("Cell")
                          )}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </Styles>
    </>
  );
};

export default TableContainer;
