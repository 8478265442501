import React from "react";
import moment from "moment";

//formatters
import { FormattedNumber } from "react-intl";

//table button
import ButtonRenderer from "components/avs/AvsAgGrid/ServerSide/buttonRenderer";

//table header
import HeaderRenderer from "components/avs/AvsAgGrid/ServerSide/headerRenderer";

//table header
import NumericEditor from "components/avs/AvsAgGrid/ServerSide/NumericEditor";

// utils
import { tableTypeList } from "utils/constants/recordType";

import { useIntl } from "react-intl";

const mizanColumn = (tableType, filterDisable, filterData) => {
  const currencyFilter = filterData?.currency?.filter;
  const hesapKoduFilter = filterData?.hesapKodu?.filter;
  console.log(currencyFilter, hesapKoduFilter);

  const formatMessage = useIntl;

  let checkBox = {
    headerName: "",
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,

    pinned: "left",
    width: "50",
    field: "id",
    filter: false,
    sort: false,
  };

  let action = {
    headerName: "#",
    sortable: false,
    filter: false,
    pinned: "right",
    width: 50,
    cellRenderer: ButtonRenderer,
  };

  let kyn = {
    headerName: "source_short",
    headerComponent: HeaderRenderer,
    field: "kyn",
    filter: "agTextColumnFilter",
    editable: false,
  };

  let mainColumn = [
    {
      headerName: "Date",
      // sorting and other feature not showing from custom header
      // headerComponent: HeaderRenderer,
      field: "tarih",
      filter: "agTextColumnFilter",
      editable: false,
      cellRenderer: (data) => {
        return moment(data.value).format("YYYY-MM-DD");
      },
    },
    {
      // headerName: "log_number",
      // headerComponent: HeaderRenderer,
      headerName: "Log Number",
      field: "gunlukNo",
      filter: "agTextColumnFilter",
      editable: false,
    },
    {
      field: "fis",
      headerName: "Voucher No",
      // headerName: "voucher_no",
      // headerComponent: HeaderRenderer,
      filter: "agTextColumnFilter",
      editable: false,
    },
    {
      field: "hesapTipi",
      headerName: "Account Type",
      // headerName: "account_type",
      // headerComponent: HeaderRenderer,
      filter: "agTextColumnFilter",
      hide: tableTypeList.axabtaList === tableType ? true : false,
      editable: false,
    },
    {
      headerName: "Account Code",
      // headerName: "account_code",
      // headerComponent: HeaderRenderer,
      field: "hesapKodu",
      // rowGroup: true,
      filter: hesapKoduFilter === undefined ? "agTextColumnFilter" : false,
      // filterParams: {
      //   values: ["120-001-001"],
      // },
      sortable: true,
      editable: false,
    },
    {
      headerName: "Account Name",
      // headerName: "account_name",
      // headerComponent: HeaderRenderer,
      field: "name",
      filter: "agTextColumnFilter",
      sortable: true,
      editable: false,
    },
    {
      headerName: "Description",
      // headerName: "description",
      // headerComponent: HeaderRenderer,
      field: "txt",
      filter: "agTextColumnFilter",
      sortable: true,
      editable: false,
    },
    {
      headerName: "Currency",
      // headerName: "currency_unit_short",
      // headerComponent: HeaderRenderer,
      field: "currency",
      filter: currencyFilter === undefined ? "agTextColumnFilter" : false,
      sortable: true,
      editable: false,
    },
    {
      headerName: "Amount",
      // headerName: "amount",
      // headerComponent: HeaderRenderer,
      field: "tutar",
      filter: false,
      // aggFunc: "mySum",
      sortable: false,
      cellEditor: NumericEditor,
      cellEditorPopup: true,
      editable: true,
      editable: false,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "D.Try",
      // headerName: "debit_try_short",
      // headerComponent: HeaderRenderer,
      field: "borcTry",
      filter: "agNumberColumnFilter",
      // aggFunc: "mySum",
      sortable: true,
      cellEditor: NumericEditor,
      cellEditorPopup: true,
      editable: true,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "C.Try",
      // headerName: "credit_try_short",
      // headerComponent: HeaderRenderer,
      field: "alacakTry",
      filter: "agNumberColumnFilter",
      // aggFunc: "mySum",
      sortable: true,
      cellEditor: NumericEditor,
      cellEditorPopup: true,
      editable: true,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "A.Try",
      // headerName: "amount_try_short",
      // headerComponent: HeaderRenderer,
      field: "tutarTry",
      filter: false,
      // aggFunc: "mySum",
      sortable: false,
      editable: false,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "D.Usd",
      // headerName: "debit_usd_short",
      // headerComponent: HeaderRenderer,
      field: "borcUsd",
      filter: "agNumberColumnFilter",
      // aggFunc: "mySum",
      sortable: true,
      cellEditor: NumericEditor,
      cellEditorPopup: true,
      editable: true,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "C.Usd",
      // headerName: "credit_usd_short",
      // headerComponent: HeaderRenderer,
      field: "alacakUsd",
      filter: "agNumberColumnFilter",
      // aggFunc: "mySum",
      sortable: true,
      cellEditor: NumericEditor,
      cellEditorPopup: true,
      editable: true,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "A.Usd",
      // headerName: "amount_usd_short",
      // headerComponent: HeaderRenderer,
      field: "tutarUsd",
      filter: false,
      // aggFunc: "mySum",
      sortable: false,
      editable: false,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
    {
      headerName: "Rate",
      // headerName: "currency_rate_short",
      // headerComponent: HeaderRenderer,
      field: "kur",
      filter: false,
      // aggFunc: "mySum",
      sortable: true,
      cellEditor: NumericEditor,
      cellEditorPopup: true,
      editable: true,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
    },
  ];

  if (tableTypeList.virmanMultiList === tableType) {
    mainColumn.unshift(checkBox);
  }
  if ("reelDonem" === tableType) {
    mainColumn.unshift(checkBox);
  }

  if (tableTypeList.reelList === tableType) {
    mainColumn.unshift(kyn);
  }

  if (tableTypeList.transferBalance === tableType) {
    mainColumn.push(action);
  }
  if (tableTypeList.duzeltmeList === tableType) {
    mainColumn.push(action);
  }
  if (tableTypeList.virmanList === tableType) {
    mainColumn.push(action);
  }
  if (tableTypeList.reelList === tableType) {
    mainColumn.push(action);
  }

  return mainColumn;
};

export { mizanColumn };
