/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// Custom styles for SuiInput
import SuiInputRoot from "components/SuiInput/SuiInputRoot";
import SuiInputWithIconRoot from "components/SuiInput/SuiInputWithIconRoot";
import SuiInputIconBoxRoot from "components/SuiInput/SuiInputIconBoxRoot";
import SuiInputIconRoot from "components/SuiInput/SuiInputIconRoot";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "context";
import { useIntl } from "react-intl";

const SuiInput = forwardRef(
  (
    { size, icon, error, success, disabled, label, placeholder, ...rest },
    ref
  ) => {
    let template;
    const [controller] = useSoftUIController();
    const { direction } = controller;
    const iconDirection = icon.direction;
    const intl = useIntl();

    if (icon.component && icon.direction === "left") {
      template = (
        <SuiInputWithIconRoot
          ref={ref}
          ownerState={{ error, success, disabled }}
        >
          <SuiInputIconBoxRoot ownerState={{ size }}>
            <SuiInputIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </SuiInputIconRoot>
          </SuiInputIconBoxRoot>
          <SuiInputRoot
            {...rest}
            ownerState={{
              size,
              error,
              success,
              iconDirection,
              direction,
              disabled,
            }}
          />
        </SuiInputWithIconRoot>
      );
    } else if (icon.component && icon.direction === "right") {
      template = (
        <SuiInputWithIconRoot
          ref={ref}
          ownerState={{ error, success, disabled }}
        >
          <SuiInputRoot
            {...rest}
            ownerState={{
              size,
              error,
              success,
              iconDirection,
              direction,
              disabled,
            }}
          />
          <SuiInputIconBoxRoot ownerState={{ size }}>
            <SuiInputIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </SuiInputIconRoot>
          </SuiInputIconBoxRoot>
        </SuiInputWithIconRoot>
      );
    } else {
      template = (
        <>
          {label && (
            <SuiBox display="flex" alignItems="center" my={1}>
              <SuiTypography
                variant="caption"
                textTransform="capitalize"
                fontWeight="bold"
              >
                <FormattedMessage id={label} />
              </SuiTypography>
            </SuiBox>
          )}
          <SuiInputRoot
            {...rest}
            ref={ref}
            placeholder={
              placeholder ? intl.formatMessage({ id: `${placeholder}` }) : ""
            }
            ownerState={{ size, error, success, disabled }}
          />
        </>
      );
    }
    return template;
  }
);

// Setting default values for the props of SuiInput
SuiInput.defaultProps = {
  size: "medium",
  icon: {
    component: false,
    direction: "none",
  },
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the SuiInput
SuiInput.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  icon: PropTypes.shape({
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    direction: PropTypes.oneOf(["none", "left", "right"]),
  }),
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SuiInput;
