import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { FormattedMessage, useIntl } from "react-intl";
import CurrencyInput from "react-currency-input-field";

const AvsCurrencyInput = ({ props }) => {
  const { formatMessage } = useIntl();

  const handleChangeInput = (value, index) => {
    const oldData = [...props.datas];
    oldData[index].value = value;
    props.setDatas(oldData);
  };

  return (
    <SuiBox>
      <SuiBox display='flex' alignItems='center' my={1}>
        <SuiTypography
          variant='caption'
          textTransform='capitalize'
          fontWeight='bold'
        >
          <FormattedMessage id={props.data.name} />
        </SuiTypography>
      </SuiBox>
      <div className='MuiInputBase-root MuiInputBase-colorPrimary css-y9gdep-MuiInputBase-root'>
        <CurrencyInput
          id='currency-field'
          name={props.data.name}
          class='MuiInputBase-input css-10ukfau-MuiInputBase-input'
          style={{
            width: "100%",
          }}
          placeholder={formatMessage({ id: "amount_placeholder" })}
          //defaultValue={0}
          decimalsLimit={3}
          decimalSeparator='.'
          groupSeparator=','
          onValueChange={(value, name) => handleChangeInput(value, props.index)}
        />
      </div>
    </SuiBox>
  );
};

export default AvsCurrencyInput;
