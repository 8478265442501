import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// Soft UI component
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiDatePicker from "components/SuiDatePicker";

// Avs UI component
import AvsBreadcrumbs from "components/avs/AvsBreadcrumbs";
import AvsDataTable from "components/avs/AvsDataTable";

// utils
import { getCurrencyListForSelect } from "utils/constants/currency";
import { getFisNo } from "utils/helpers/avs/index";
import { recordTtype, tableTypeList } from "utils/constants/recordType";

//service
import axios from "services/axios/index";
import { endPointMizan } from "services/axios/endpoint";

//state
import { mainFilterAtom, isLoadingAtom } from "recoil/atoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import AvsSwal from "components/avs/AvsSwal";
import SwalTypes from "components/avs/AvsSwal/types";
import { useIntl } from "react-intl";

function VirmanMultiEdit() {
  const breadcrumbRoutes = [
    {
      route: "mizan",
      label: "trial_balance",
    },
    {
      route: "menu",
      label: "finance",
    },
  ];

  let { trsNo } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();

  //global state
  const setIsLoading = useSetRecoilState(isLoadingAtom);
  const [mainFilterState, setMainFilterState] = useRecoilState(mainFilterAtom);

  // date state
  //var sDate = moment(mainFilterState.startDate).format("MM-DD-YYYY");
  const [startDate, setStartDate] = useState(mainFilterState.startDate);

  //fishno
  const [fishNo, setFishNo] = useState("");
  const [cikanHesabSearch, setCikanHesabSearch] = useState("");
  const [cikanHesab, setCikanHesab] = useState("");
  const [girenHesab, setGirenHesab] = useState("");
  const [currency, setCurrency] = useState("");

  const item = {
    tarih: startDate,
    //gunlukNo  create in back end
    fis: fishNo,
    kyn: recordTtype.virman,
    hesapTipi: "",
    hesapKodu: "",
    name: "",
    txt: "",
    currency: currency,
    tutar: 0,
    borcUsd: 0,
    alacakUsd: 0,
    tutarUsd: 0,
    borcTry: 0,
    alacakTry: 0,
    tutarTry: 0,
    kur: 0,
    dataAreaId: mainFilterState?.company,
    createdBy: null,
  };

  //record
  const [cikanKayit, setCikanKayit] = useState(item);
  const [girenKayit, setGirenKayit] = useState(item);

  //tableData
  const [reelAxabta, setReelAxabta] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const response = await axios.get(
        `${endPointMizan.mizanVirmanMultiSingle}/${trsNo}`
      );
      const { main, relation } = response.data.data;
      console.log(main[0].tarih);
      setFishNo(main[0].fis);
      setCurrency(main[0].currency);
      setStartDate(main[0].tarih);

      setReelAxabta(relation);
      setCikanKayit(main[0]);
      setGirenKayit(main[1]);
      setCikanHesab(`${main[0].hesapKodu}|${main[0].name}`);
      setGirenHesab(`${main[1].hesapKodu}|${main[1].name}`);
      setIsLoading(false);
    }

    fetchData();
  }, [trsNo]);

  const handleDelete = async () => {
    setIsLoading(true);

    AvsSwal({
      data: {
        type: SwalTypes.delete,
        intl,
        props: {
          route: `${endPointMizan.mizanVirmanMultiSingle}/${trsNo}`,
          text: "virman_record",
          setIsLoading,
          navigate,
          navigateTo: "mizan",
        },
      },
    });
  };

  return (
    <SuiBox>
      {/* Section Breadcrumb */}
      <AvsBreadcrumbs
        title="create_virement"
        currentRoute="create_virement"
        otherRoutes={breadcrumbRoutes}
      />
      {/* Section Form */}
      <SuiBox display="flex" justifyContent="flex-start" mt={1}>
        <SuiBox display="flex">
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            mr={1}
          >
            <SuiBox display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Fiş No
              </SuiTypography>
            </SuiBox>
            <SuiInput
              value={fishNo}
              disabled
              //   onChange={handeSetFormValue}
            />
          </SuiBox>
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            mr={1}
            style={{ width: "300px" }}
          >
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Çıkan Hesap Kodu
              </SuiTypography>
            </SuiBox>
            <SuiBox
              bgColor="white"
              style={{ position: "relative", zIndex: 100 }}
            >
              {/* <SuiInput onChange={(e) => setCikanHesabSearch(e.target.value)} /> */}
              <SuiInput
                value={cikanHesab}
                disabled
                //   onChange={handeSetFormValue}
              />
            </SuiBox>
          </SuiBox>
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            mr={1}
            style={{ width: "300px" }}
          >
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Giren Hesap Kodu
              </SuiTypography>
            </SuiBox>
            <SuiBox
              bgColor="white"
              style={{ position: "relative", zIndex: 100 }}
            >
              <SuiInput
                value={girenHesab}
                disabled
                //   onChange={handeSetFormValue}
              />
            </SuiBox>
          </SuiBox>
          <SuiBox
            width="60px"
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            mr={1}
          >
            <SuiBox display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                PB
              </SuiTypography>
            </SuiBox>

            <SuiInput
              disabled
              value={currency}
              //   onChange={handeSetFormValue}
            />
          </SuiBox>
          {/* <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            mr={1}
          >
            <SuiBox display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Çoklu Kayıt
              </SuiTypography>
            </SuiBox>
            <Switch checked={visible} onChange={handleSetVisible} />
          </SuiBox> */}
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
          >
            <SuiButton variant="gradient" color="error" onClick={handleDelete}>
              Delete
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>

      <SuiBox pt={1}>
        <SuiTypography
          component="span"
          variant="h6"
          fontWeight="bold"
          color="dark"
          sx={{ lineHeight: 0 }}
        >
          Giren Kayıt
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1}>
        <Table aria-label="simple table">
          <TableHead style={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell
                width="50px"
                style={{
                  padding: "2px",
                  fontSize: "12px",
                }}
              >
                Tarih
              </TableCell>
              {/* <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Günlük No
            </TableCell> */}
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Fiş No
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Hesap Tipi
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Hesap Kodu - Hesap Adı
              </TableCell>
              {/* <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Hesap Adı
            </TableCell> */}
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Açıklama
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                PB
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Tutar
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                BorcTRY
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Alacak TRY
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Tutar TRY
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Borc USD
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Alacak USD
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Tutar USD
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Kur
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderBottom: "none",
                },
              }}
            >
              <TableCell
                component="th"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiBox width="100px">
                  <SuiDatePicker name="tarih" value={startDate} />
                </SuiBox>
              </TableCell>
              <TableCell
                align="right"
                width="140px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="fis"
                  value={fishNo}
                  disabled={true}
                />
              </TableCell>
              <TableCell
                width="130px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiBox bgColor="white">
                  <SuiInput
                    type="text"
                    name="hesapTipi"
                    value={girenKayit.hesapTipi}
                    disabled={true}
                  />
                </SuiBox>
              </TableCell>
              <TableCell
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="girenHesab"
                  value={girenHesab}
                  disabled={true}
                />
              </TableCell>
              <TableCell
                align="right"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="txt"
                  disabled={true}
                  value={girenKayit.txt}
                />
              </TableCell>
              <TableCell
                align="right"
                width="60px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                {/* <SuiInput
                  type="text"
                  name="pb"
                  onChange={handleChange(item.pb)}
                  
                /> */}
                <SuiInput
                  type="text"
                  name="currency"
                  disabled={true}
                  value={girenKayit.currency}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="tutar"
                  disabled={true}
                  value={girenKayit.tutar}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="borcTry"
                  disabled={true}
                  value={girenKayit.borcTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="alacakTry"
                  disabled={true}
                  value={girenKayit.alacakTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="name"
                  disabled={true}
                  value={girenKayit.tutarTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="borcUsd"
                  disabled={true}
                  value={girenKayit.borcUsd}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="alacakUsd"
                  disabled={true}
                  value={girenKayit.alacakUsd}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="tutarUsd"
                  disabled={true}
                  value={girenKayit.tutarUsd}
                />
              </TableCell>
              <TableCell
                align="right"
                width="70px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="kur"
                  disabled={true}
                  value={girenKayit.kur}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </SuiBox>

      <SuiBox pt={1}>
        <SuiTypography
          component="span"
          variant="h6"
          fontWeight="bold"
          color="dark"
          sx={{ lineHeight: 0 }}
        >
          Çıkan Kayıt
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1}>
        <Table aria-label="simple table">
          <TableHead style={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell
                width="50px"
                style={{
                  padding: "2px",
                  fontSize: "12px",
                }}
              >
                Tarih
              </TableCell>
              {/* <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Günlük No
            </TableCell> */}
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Fiş No
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Hesap Tipi
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Hesap Kodu - Hesap Adı
              </TableCell>
              {/* <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Hesap Adı
            </TableCell> */}
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Açıklama
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                PB
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Tutar
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                BorcTRY
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Alacak TRY
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Tutar TRY
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Borc USD
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Alacak USD
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Tutar USD
              </TableCell>
              <TableCell
                width="auto"
                style={{ padding: "2px", fontSize: "12px" }}
              >
                Kur
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderBottom: "none",
                },
              }}
            >
              <TableCell
                component="th"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiBox width="100px">
                  <SuiDatePicker name="tarih" value={startDate} />
                </SuiBox>
              </TableCell>
              <TableCell
                align="right"
                width="140px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="fis"
                  value={fishNo}
                  disabled={true}
                />
              </TableCell>
              <TableCell
                width="130px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiBox bgColor="white">
                  <SuiInput
                    type="text"
                    name="hesapTipi"
                    value={cikanKayit.hesapTipi}
                    disabled={true}
                  />
                </SuiBox>
              </TableCell>
              <TableCell
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="cikanHesab"
                  value={cikanHesab}
                  disabled={true}
                />
              </TableCell>
              <TableCell
                align="right"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="txt"
                  disabled={true}
                  value={cikanKayit.txt}
                />
              </TableCell>
              <TableCell
                align="right"
                width="60px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                {/* <SuiInput
                  type="text"
                  name="pb"
                  onChange={handleChange(item.pb)}
                  
                /> */}
                <SuiInput
                  type="text"
                  name="currency"
                  disabled={true}
                  value={cikanKayit.currency}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="tutar"
                  disabled={true}
                  value={cikanKayit.tutar}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="borcTry"
                  disabled={true}
                  value={cikanKayit.borcTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="alacakTry"
                  disabled={true}
                  value={cikanKayit.alacakTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="name"
                  disabled={true}
                  value={cikanKayit.tutarTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="borcUsd"
                  disabled={true}
                  value={cikanKayit.borcUsd}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="alacakUsd"
                  disabled={true}
                  value={cikanKayit.alacakUsd}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="tutarUsd"
                  disabled={true}
                  value={cikanKayit.tutarUsd}
                />
              </TableCell>
              <TableCell
                align="right"
                width="70px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="kur"
                  disabled={true}
                  value={cikanKayit.kur}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </SuiBox>

      <AvsDataTable
        isCheck={false}
        isInitalGroup={false}
        tableData={reelAxabta}
        isKyn={false}
        tableType={tableTypeList.virmanListMultiFalse}
      />
    </SuiBox>
  );
}

export default VirmanMultiEdit;
