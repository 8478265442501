import PropTypes from "prop-types";
import React, { useState, useEffect, forwardRef } from "react";
import { Formik, Form } from "formik";

//language
import { FormattedMessage } from "react-intl";

// @mui material components
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Switch from "@mui/material/Switch";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

//custom year-month picker
import AvsMonthPicker from "components/avs/AvsMonthPicker";

//currency Input
import CurrencyInput from "react-currency-input-field";

//language imports
import { useRecoilValue } from "recoil";
import { localeAtom } from "recoil/atoms";
import { LOCALES } from "i18n/locales";
import { enGB } from "date-fns/locale";
import { tr } from "date-fns/locale";
import { format } from "date-fns";

// Soft UI component
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import FormField from "components/FormField";
import SuiTypography from "components/SuiTypography";
import AvsDataEntry from "components/avs/AvsDataEntry";
import AvsDataTable from "components/avs/AvsDataTable";
import GridExample from "components/avs/AvsAgGrid/ServerSide";
import GridTreeViewExample from "components/avs/AvsAgGrid/TreeView";
import SwalTypes from "components/avs/AvsSwal/types";
// import GridExampleTree from "components/avs/AvsAgGrid/test";
import SelectEditor from "components/avs/AvsAgGrid/ServerSide/SelectEditor";

// utils
import { tableTypeList, reportDataType } from "utils/constants/recordType";
import selectData from "utils/helpers/data/selectData";

//footer
import AvsFooter from "components/avs/AvsFooter";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

//endpoint
import axios from "services/axios";
import { endPointMizan, endPointSalesPurchase } from "services/axios/endpoint";

//translate
import { useIntl } from "react-intl";

//state
import {
  mainFilterAtom,
  duzeltmeKayitAtom,
  virmanKayitAtom,
  reelKayitAtom,
  axabtaMizanAtom,
  footerTotalAtom,
  activeTabAtom,
  activeTabListAtom,
} from "recoil/atoms";
import { useRecoilState } from "recoil";
import SuiSelect from "components/SuiSelect";
import AvsSwal from "../AvsSwal";
import Dropzone from "react-dropzone";
import AvsCurrencyInput from "../AvsCurrencyInput";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <SuiBox>{children}</SuiBox>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function AvsTab(props) {
  // tab state
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabsOrientationBilanco, setTabsOrientationBilanco] =
    useState("horizontal");
  const [tabsOrientationIncomeStatement, setTabsOrientationIncomeStatement] =
    useState("horizontal");
  const [tabsOrientationMizan, setTabsOrientationMizan] =
    useState("horizontal");
  const [activeTabMizan, setActiveTabMizan] = useState(0);
  const [activeTabBilanco, setActiveTabBilanco] = useState(0);
  const [activeTabIncomeStatement, setActiveTabIncomeStatement] = useState(0);

  const { formatMessage } = useIntl();
  const intl = useIntl();
  const globalLocale = useRecoilValue(localeAtom);

  const [datas, setDatas] = useState(selectData.addList);
  const [attachments, setAttachments] = useState([]);

  const controlData = (newData) => {
    for (let i = 0; i < datas.length; i++) {
      if (
        datas[i].value === undefined ||
        datas[i].value === "" ||
        datas[i].value === " "
      ) {
        break;
      }
      newData.push({
        name: datas[i].name,
        value: datas[i].value,
      });
    }
  };

  const sendImportedData = () => {
    const _date = date["$d"];
    const formattedDate = format(_date ? _date : date, "yyyyMM", {
      locale: globalLocale === LOCALES.TURKISH ? tr : enGB,
    });
    console.log({ formattedDate }, { company }, { files });
  };

  const removeAllFiles = () => {
    setFiles([]);
  };

  const sendData = async () => {
    let newData = [];
    controlData(newData);
    console.log({ newData });
    if (newData.length === datas.length) {
      /**
       * parse array into object with name and value
       */
      const objData = newData.reduce((json, obj, key) => {
        json[obj.name] = parseFloat(obj.value);
        console.log(json[obj.name]);
        if (isNaN(json[obj.name]) || obj.name === "reportPeriod")
          json[obj.name] = obj.value;
        return json;
      }, {});
      console.log({ objData });

      /**
       * data sending process
       */
      const response = await axios.post(
        endPointSalesPurchase.purchaseSalesCreateSingle,
        {
          data: objData,
        }
      );
      console.log({ response });
      AvsSwal({
        data: {
          type: SwalTypes.success,
          intl,
          props: {
            title: "success",
            text: "success_add_new_entry",
          },
        },
      });
    } else {
      /**
       * error message
       */
      AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_title",
            text: "error_areas_must_filled",
          },
        },
      });
    }
  };

  // function capitalizeFirstLetter(string) {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // }

  // const convertNameToLabel = (name) => {
  //   let changedName = name.replaceAll('_', ' ');
  //   changedName = capitalizeFirstLetter(changedName);
  //   return changedName;
  // }

  const handleChange = (e, index) => {
    const { value } = e.target;
    const oldData = [...datas];
    oldData[index].value = value;
    setDatas(oldData);
  };

  const handleChangeSelect = (data, index) => {
    const oldData = [...datas];
    oldData[index].value = data.value;
    setDatas(oldData);
  };

  const handlePeriodChange = (e, index) => {
    const formattedDate = format(e["$d"] ? e["$d"] : new Date(), "yyyyMM", {
      locale: globalLocale === LOCALES.TURKISH ? tr : enGB,
    });
    const oldData = [...datas];
    oldData[index].value = formattedDate;
    setDatas(oldData);
  };

  const handleSetCompany = (company) => {
    setCompany(company.value);
  };

  // switch state
  const [visible, setVisible] = useState(false);
  const [mizanType, setMizanType] = useState("A");
  const [isInputModalOpened, setIsInputModalOpened] = useState(false);
  const [isImportModalOpened, setIsImportModalOpened] = useState(false);

  // global state
  const [mainFilterState, setMainFilterState] = useRecoilState(mainFilterAtom);

  //this global state will deleted after because create activetablist
  const [activeTab, setActiveTab] = useRecoilState(activeTabAtom);
  //new global state
  const [activeTabList, setActiveTabList] = useRecoilState(activeTabListAtom);
  // const [duzeltmeKayit, setDuzeltmeKayit] = useRecoilState(duzeltmeKayitAtom);
  // const [virmanKayit, setVirmanKayit] = useRecoilState(virmanKayitAtom);
  // const [reelKayit, setReelnKayit] = useRecoilState(reelKayitAtom);
  // const [axabtaMizanList, setAxabtaMizanList] = useRecoilState(axabtaMizanAtom);
  const [footerTotalState, setFooterTotalState] =
    useRecoilState(footerTotalAtom);
  const { duzeltmeKayitTtl, virmanKayitTtl, axabtaKayitTtl, reelKayitTtl } =
    footerTotalState;
  const [company, setCompany] = useState(mainFilterState.company);
  const [date, setDate] = useState(new Date());
  const [files, setFiles] = useState();
  const [firms, setFirms] = useState([]);
  const [companySelectItem, setCompanySelectItem] = useState(
    selectData.companyList.filter((a) => {
      if (a.value == mainFilterState.company) {
        return a;
      }
    })
  );
  const handleSetVisible = () => setVisible(!visible);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleSetActiveTabList = (event, newValue, menu) => {
    setActiveTabList((prevState) => ({
      ...prevState,
      [menu]: newValue,
    }));
  };
  const handleSetTabValueMizan = (event, newValue) => {
    setActiveTabMizan(newValue);
  };
  const handleSetTabValueBilanco = (event, newValue) => {
    setActiveTabBilanco(newValue);
  };
  const handleSetTabValueIncomeStatement = (event, newValue) => {
    setActiveTabIncomeStatement(newValue);
  };
  /**
   * Input Enter Modal open function
   */
  const handleEnterInputModalOpen = () => {
    setIsInputModalOpened(!isInputModalOpened);
  };
  /**
   * Input Enter Modal open function
   */
  const handleOpenModal = () => {};
  const handleImportModalOpen = () => {
    setIsImportModalOpened(!isImportModalOpened);
  };
  const handleSetDate = (newDate) => setDate(newDate[0]);

  const handleFiles = (files) => {
    setFiles(files.slice(0, 2));
    console.log({ files });
  };

  /*
   * this way is not good for table refresh when filter change
   */
  let prevActiveTab = activeTab;
  useEffect(() => {
    const refreshTab = activeTab === 0 ? 1 : 0;
    setActiveTab(refreshTab);
    setTimeout(() => setActiveTab(prevActiveTab), 100);
  }, [mainFilterState]);

  useEffect(() => {
    const loadOptions = async (field, inputValue, setter) => {
      const url =
        field === "FIRMA"
          ? `/purchaseSales/vendorList/${inputValue}`
          : field === "SHIPNAME"
          ? `/purchaseSales/vesselList/avs/${inputValue}`
          : field === "COUNTRY"
          ? `/purchaseSales/countryList/${inputValue}`
          : field === "CUSTOMER"
          ? `/purchaseSales/customerList/avs/${inputValue}`
          : field === "DELIVERYPORT" || field === "PURCHASEPLACE"
          ? `/purchaseSales/portList/${inputValue}`
          : field === "MAINDIVISIONS" ||
            field === "SUBDIVISIONS" ||
            field === "DIVISIONS"
          ? `/purchaseSales/divisionListWithSearch/${inputValue}`
          : "";

      const data = await axios
        .get(url)
        .then((res) => res.data)
        .then((data) => data.data)
        .catch((error) => {
          console.error({ error });
          throw error;
        });
      let result = [];

      data.map((key, value) => {
        result.push({
          value: key.NAME,
          label: key.NAME,
        });
      });
      setter(result);
    };

    loadOptions("FIRMA", "a", setFirms);
    console.log({ firms });
  }, []);
  const onBtnFark = () => {
    console.log("onBtnFark");
    setMizanType("F");
  };

  const onBtnReel = () => {
    console.log("onBtnReel");
    setMizanType("R");
  };

  return (
    <SuiBox>
      {props.mizan ? (
        <SuiBox mt={3}>
          <SuiBox
            display='flex'
            flexDirection={{ xs: "column", md: "column", lg: "row" }}
            justifyContent={{ md: "flex-end", lg: "space-between" }}
          >
            <SuiBox width={{ xs: "100%", md: "100%", lg: "80%" }}>
              <Tabs
                orientation={tabsOrientation}
                value={activeTab}
                onChange={handleSetTabValue}
              >
                <Tab
                  label={<FormattedMessage id='axapta_ledger' />}
                  {...a11yProps(0)}
                />
                <Tab
                  label={<FormattedMessage id='amendment_entry' />}
                  {...a11yProps(1)}
                />
                <Tab
                  label={<FormattedMessage id='virement' />}
                  {...a11yProps(2)}
                />
                <Tab
                  label={<FormattedMessage id='reel_ledger' />}
                  {...a11yProps(3)}
                />
                <Tab
                  label={<FormattedMessage id='trial_balance' />}
                  {...a11yProps(4)}
                />
                <Tab
                  label={<FormattedMessage id='balance_sheet' />}
                  {...a11yProps(5)}
                />
                <Tab
                  label={<FormattedMessage id='income_statement' />}
                  {...a11yProps(6)}
                />
              </Tabs>
            </SuiBox>
            <SuiBox width='50%' justifyContent='flex-end'>
              <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
                {activeTab === 0 && (
                  <AvsFooter
                    isShow={true}
                    totalBorcTry={axabtaKayitTtl?.totalBorcTry}
                    totalAlacakTry={axabtaKayitTtl?.totalAlacakTry}
                    totalTry={axabtaKayitTtl?.totalTry}
                    totalBorcUsd={axabtaKayitTtl?.totalBorcUsd}
                    totalAlacakUsd={axabtaKayitTtl?.totalAlacakUsd}
                    totalUsd={axabtaKayitTtl?.totalUsd}
                  />
                )}

                {activeTab === 2 && (
                  <AvsFooter
                    isShow={true}
                    totalBorcTry={virmanKayitTtl?.totalBorcTry}
                    totalAlacakTry={virmanKayitTtl?.totalAlacakTry}
                    totalTry={virmanKayitTtl?.totalTry}
                    totalBorcUsd={virmanKayitTtl?.totalBorcUsd}
                    totalAlacakUsd={virmanKayitTtl?.totalAlacakUsd}
                    totalUsd={virmanKayitTtl?.totalUsd}
                  />
                )}
                {activeTab === 1 && (
                  <SuiBox
                    display='flex'
                    justifyContent={{ md: "flex-end" }}
                    alignItems='center'
                    lineHeight={1}
                  >
                    <SuiTypography variant='caption' fontWeight='light'>
                      {visible ? (
                        <FormattedMessage id='close_input_monitor' />
                      ) : (
                        <FormattedMessage id='open_input_monitor' />
                      )}
                    </SuiTypography>
                    <SuiBox mx={1}>
                      <Switch checked={visible} onChange={handleSetVisible} />
                    </SuiBox>
                    <AvsFooter
                      isShow={true}
                      totalBorcTry={duzeltmeKayitTtl?.totalBorcTry}
                      totalAlacakTry={duzeltmeKayitTtl?.totalAlacakTry}
                      totalTry={duzeltmeKayitTtl?.totalTry}
                      totalBorcUsd={duzeltmeKayitTtl?.totalBorcUsd}
                      totalAlacakUsd={duzeltmeKayitTtl?.totalAlacakUsd}
                      totalUsd={duzeltmeKayitTtl?.totalUsd}
                    />
                  </SuiBox>
                )}
                {activeTab === 3 && (
                  <SuiBox
                    display='flex'
                    justifyContent={{ md: "flex-end" }}
                    alignItems='center'
                    lineHeight={1}
                  >
                    {/* <SuiTypography variant="caption" fontWeight="light">
                  {visible ? (
                    <FormattedMessage id="close_input_monitor" />
                  ) : (
                    <FormattedMessage id="open_input_monitor" />
                  )}
                </SuiTypography>
                <SuiBox mx={1}>
                  <Switch checked={visible} onChange={handleSetVisible} />
                </SuiBox> */}
                    <AvsFooter
                      isShow={true}
                      totalBorcTry={reelKayitTtl?.totalBorcTry}
                      totalAlacakTry={reelKayitTtl?.totalAlacakTry}
                      totalTry={reelKayitTtl?.totalTry}
                      totalBorcUsd={reelKayitTtl?.totalBorcUsd}
                      totalAlacakUsd={reelKayitTtl?.totalAlacakUsd}
                      totalUsd={reelKayitTtl?.totalUsd}
                    />
                  </SuiBox>
                )}
              </Grid>
            </SuiBox>
          </SuiBox>{" "}
          <TabPanel value={activeTab} index={0}>
            <GridExample
              endPoint={endPointMizan.mizanAxabta2}
              tableType={tableTypeList.axabtaList}
              gridType='mizan'
            />
            {/* <AvsDataTable
          isCheck={false}
          isInitalGroup={true}
          tableData={axabtaMizanList}
          tableType={tableTypeList.axabtaList}
        /> */}
            {/* <AvsDataGrid /> */}
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            {!visible ? <SuiBox /> : <AvsDataEntry />}
            {/* <AvsDataTable
          isCheck={false}
          isInitalGroup={false}
          tableData={duzeltmeKayit}
          tableType={tableTypeList.duzeltmeList}
        /> */}
            <GridExample
              endPoint={endPointMizan.mizanReel}
              tableType={tableTypeList.duzeltmeList}
              gridType='mizan'
            />
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <GridExample
              endPoint={endPointMizan.mizanReel}
              tableType={tableTypeList.virmanList}
              gridType='mizan'
            />
            {/* <AvsDataTable
          isCheck={false}
          isInitalGroup={false}
          tableData={virmanKayit}
          tableType={tableTypeList.virmanList}
        /> */}
          </TabPanel>
          <TabPanel value={activeTab} index={3}>
            {/* {!visible ? <SuiBox /> : <AvsDataEntry />} */}
            <GridExample
              endPoint={endPointMizan.mizanReel}
              tableType={tableTypeList.reelList}
              gridType='mizan'
            />
          </TabPanel>
          <TabPanel value={activeTab} index={4}>
            <SuiBox width={{ xs: "100%", md: "100%", lg: "50%" }}>
              <Tabs
                orientation={tabsOrientationMizan}
                value={activeTabMizan}
                onChange={handleSetTabValueMizan}
              >
                <Tab
                  label={<FormattedMessage id='axapta' />}
                  {...a11yProps(0)}
                />
                <Tab
                  label={<FormattedMessage id='reel_axapta' />}
                  {...a11yProps(0)}
                />
                <Tab
                  label={<FormattedMessage id='amendment' />}
                  {...a11yProps(1)}
                />
                <Tab label={<FormattedMessage id='reel' />} {...a11yProps(2)} />
              </Tabs>
            </SuiBox>
            <TabPanel value={activeTabMizan} index={0}>
              <GridTreeViewExample gtype={"A"} activeGrid={"tb"} />
            </TabPanel>
            <TabPanel value={activeTabMizan} index={1}>
              <GridTreeViewExample gtype={"RA"} activeGrid={"tb"} />
            </TabPanel>
            <TabPanel value={activeTabMizan} index={2}>
              <GridTreeViewExample gtype={"F"} activeGrid={"tb"} />
            </TabPanel>
            <TabPanel value={activeTabMizan} index={3}>
              <GridTreeViewExample gtype={"R"} activeGrid={"tb"} />
            </TabPanel>
          </TabPanel>
          <TabPanel value={activeTab} index={5}>
            <SuiBox width={{ xs: "100%", md: "100%", lg: "50%" }}>
              <Tabs
                orientation={tabsOrientationBilanco}
                value={activeTabBilanco}
                onChange={handleSetTabValueBilanco}
              >
                <Tab
                  label={<FormattedMessage id='active_axapta' />}
                  {...a11yProps(0)}
                />
                <Tab
                  label={<FormattedMessage id='passive_axapta' />}
                  {...a11yProps(1)}
                />
                <Tab
                  label={<FormattedMessage id='active_reel' />}
                  {...a11yProps(3)}
                />
                <Tab
                  label={<FormattedMessage id='passive_reel' />}
                  {...a11yProps(4)}
                />
              </Tabs>
            </SuiBox>
            <TabPanel value={activeTabBilanco} index={0}>
              <GridTreeViewExample
                gtype={reportDataType.axabta}
                activeGrid={tableTypeList.balanceSheetAxaptaActive}
              />
            </TabPanel>
            <TabPanel value={activeTabBilanco} index={1}>
              <GridTreeViewExample
                gtype={reportDataType.axabta}
                activeGrid={tableTypeList.balanceSheetAxaptaPassive}
              />
            </TabPanel>
            <TabPanel value={activeTabBilanco} index={2}>
              <GridTreeViewExample
                gtype={reportDataType.reel}
                activeGrid={tableTypeList.balanceSheetReelActive}
              />
            </TabPanel>
            <TabPanel value={activeTabBilanco} index={3}>
              <GridTreeViewExample
                gtype={reportDataType.reel}
                activeGrid={tableTypeList.balanceSheeReelPassive}
              />
            </TabPanel>
          </TabPanel>
          <TabPanel value={activeTab} index={6}>
            <SuiBox width={{ xs: "100%", md: "100%", lg: "50%" }}>
              <Tabs
                orientation={tabsOrientationIncomeStatement}
                value={activeTabIncomeStatement}
                onChange={handleSetTabValueIncomeStatement}
              >
                <Tab
                  label={<FormattedMessage id='axapta' />}
                  {...a11yProps(0)}
                />
                <Tab label={<FormattedMessage id='reel' />} {...a11yProps(1)} />
              </Tabs>
            </SuiBox>
            <TabPanel value={activeTabIncomeStatement} index={0}>
              <GridTreeViewExample
                gtype={reportDataType.axabta}
                activeGrid={"is"}
              />
              {/* <GridTreeViewExample gtype={"R"} activeGrid={"is"} /> */}
            </TabPanel>
            <TabPanel value={activeTabIncomeStatement} index={1}>
              <GridTreeViewExample
                gtype={reportDataType.reel}
                activeGrid={"is"}
              />
            </TabPanel>
          </TabPanel>
        </SuiBox>
      ) : props.sales ? (
        <SuiBox mt={3}>
          <SuiBox
            display='flex'
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent={{ xs: "flex-end", lg: "space-between" }}
          >
            <SuiBox width={{ xs: "100%", md: "100%", lg: "80%" }}>
              <Tabs
                orientation={tabsOrientation}
                value={activeTabList.sales}
                onChange={(event, newValue) => {
                  handleSetActiveTabList(event, newValue, "sales");
                }}
              >
                <Tab
                  label={<FormattedMessage id='sales' />}
                  {...a11yProps(0)}
                />
                <Tab
                  label={<FormattedMessage id='contract_sales' />}
                  {...a11yProps(1)}
                />
              </Tabs>
            </SuiBox>
            <SuiBox
              width={{ xs: "100%", md: "100%", lg: "50%" }}
              justifyContent='flex-end'
            >
              <Grid item xs={12} md={12} lg={3} sx={{ ml: "auto" }}>
                {activeTabList.sales === 0 && (
                  <SuiBox
                    display='flex'
                    justifyContent={{ md: "center", lg: "flex-end" }}
                    alignItems='center'
                    lineHeight={1}
                  >
                    <SuiBox m={1} display='flex'>
                      <SuiBox display='flex' gap='1rem'>
                        <SuiBox
                          display='flex'
                          width='250px'
                          justifyContent='flex-end'
                          alignItems='center'
                          mr={1}
                        >
                          <SuiTypography variant='caption' fontWeight='light'>
                            {visible ? (
                              <FormattedMessage id='close_error_monitor' />
                            ) : (
                              <FormattedMessage id='open_error_monitor' />
                            )}
                            <Switch
                              checked={visible}
                              onChange={handleSetVisible}
                              sx={{ marginLeft: "0.5rem" }}
                            />
                          </SuiTypography>
                        </SuiBox>
                        <SuiButton color='info' onClick={handleImportModalOpen}>
                          <FormattedMessage id='open_import' />
                        </SuiButton>
                        <SuiButton
                          color='success'
                          onClick={handleEnterInputModalOpen}
                        >
                          <FormattedMessage id='open_input_monitor' />
                        </SuiButton>
                      </SuiBox>
                      <Modal
                        open={isInputModalOpened}
                        onClose={handleEnterInputModalOpen}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                      >
                        <Grid
                          container
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "auto",
                          }}
                        >
                          <Card className='overflow-visible'>
                            <SuiBox p={3} className='overflow-y-scroll'>
                              <SuiBox
                                mt={1}
                                display='flex'
                                justifyContent='space-between'
                              >
                                <SuiTypography
                                  variant='h5'
                                  alignSelf='center'
                                  fontWeight='bold'
                                  mr={3}
                                >
                                  {" "}
                                  {formatMessage({ id: "create_new_entry" })}
                                </SuiTypography>
                                <SuiButton
                                  color='white'
                                  onClick={handleEnterInputModalOpen}
                                  iconOnly
                                >
                                  <Icon>close</Icon>
                                </SuiButton>
                              </SuiBox>{" "}
                              <SuiBox mt={1}>
                                <Grid
                                  container
                                  minWidth='800px'
                                  spacing={2}
                                  justifyContent='center'
                                >
                                  {
                                    /**
                                     * Generalized input mapping
                                     */
                                    datas.map((data, index) => {
                                      return (
                                        <Grid
                                          key={index}
                                          item
                                          lg={datas.length <= 4 ? 12 : 3}
                                          sm={6}
                                          xs={12}
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "self-end",
                                          }}
                                        >
                                          {data.type === "select" ? (
                                            <SuiSelect
                                              label={data.name}
                                              onChange={(e) =>
                                                handleChangeSelect(e, index)
                                              }
                                              options={
                                                data.name === "yil"
                                                  ? selectData.years
                                                  : data.name === "ay"
                                                  ? selectData.birthDate
                                                  : data.name ===
                                                      "currencyPurchase" ||
                                                    data.name ===
                                                      "currencySales"
                                                  ? selectData.currency
                                                  : []
                                              }
                                            />
                                          ) : data.type === "period" ? (
                                            <AvsMonthPicker
                                              label='period'
                                              value={data.value}
                                              onChange={(e) =>
                                                handlePeriodChange(e, index)
                                              }
                                            />
                                          ) : data.type === "select_server" ? (
                                            <SelectEditor
                                              valueFromProp
                                              label={data.name}
                                              value={data.value}
                                              datas={datas}
                                              index={index}
                                              setDatas={setDatas}
                                              colDef={{
                                                field: data.field,
                                              }}
                                            />
                                          ) : data.type === "number" ? (
                                            <AvsCurrencyInput
                                              props={{
                                                data: data,
                                                index,
                                                datas,
                                                setDatas,
                                              }}
                                            />
                                          ) : (
                                            <FormField
                                              type={data.label}
                                              label={formatMessage({
                                                id: data.name,
                                              })}
                                              name={data.name}
                                              value={data.value}
                                              placeholder={data.name}
                                              onChange={(e) =>
                                                handleChange(e, index)
                                              }
                                              required
                                            />
                                          )}
                                        </Grid>
                                      );
                                    })
                                  }
                                </Grid>
                                <SuiBox
                                  mt={1}
                                  display='flex'
                                  justifyContent='center'
                                >
                                  <SuiButton
                                    type='submit'
                                    variant='gradient'
                                    color='success'
                                    size='small'
                                    //  disabled={disabled}
                                    onClick={sendData}
                                  >
                                    {formatMessage({ id: "send" })}
                                  </SuiButton>
                                </SuiBox>
                              </SuiBox>
                            </SuiBox>
                          </Card>
                        </Grid>
                      </Modal>
                      <Modal
                        open={isImportModalOpened}
                        onClose={handleImportModalOpen}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                      >
                        <Grid
                          container
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "auto",
                          }}
                        >
                          <Card className='overflow-visible'>
                            <SuiBox p={3} className='overflow-y-scroll'>
                              <SuiBox
                                mt={1}
                                display='flex'
                                justifyContent='space-between'
                              >
                                <SuiTypography
                                  variant='h5'
                                  alignSelf='center'
                                  fontWeight='bold'
                                  mr={3}
                                >
                                  {" "}
                                  {formatMessage({ id: "create_new_entry" })}
                                </SuiTypography>
                                <SuiButton
                                  color='white'
                                  onClick={handleImportModalOpen}
                                  iconOnly
                                >
                                  <Icon>close</Icon>
                                </SuiButton>
                              </SuiBox>{" "}
                              <SuiBox mt={1}>
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <AvsMonthPicker
                                      value={date}
                                      onChange={setDate}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container mt={1} justifyContent='center'>
                                  {
                                    <Card sx={{ width: "inherit" }} px={2}>
                                      <Dropzone onDrop={handleFiles}>
                                        {({ getRootProps, getInputProps }) => (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                            {...getRootProps({
                                              className: "dropzone",
                                            })}
                                          >
                                            <input {...getInputProps()} />
                                            <SuiBox alignSelf='center'>
                                              {files && files.length !== 0 ? (
                                                files.map((file, key) => {
                                                  return (
                                                    <Card
                                                      sx={{
                                                        border: "0.1rem solid",
                                                        padding: "0.5rem",
                                                        margin: "1rem",
                                                      }}
                                                      key={key}
                                                    >
                                                      {file.name}
                                                    </Card>
                                                  );
                                                })
                                              ) : (
                                                <SuiBox
                                                  display='flex'
                                                  alignItems='center'
                                                  flexDirection='column'
                                                >
                                                  <SuiTypography
                                                    component='span'
                                                    variant='h6'
                                                    fontWeight='bold'
                                                    color='dark'
                                                  >
                                                    <FormattedMessage
                                                      id={"msg_drag_drop_file1"}
                                                    />
                                                  </SuiTypography>
                                                  <SuiTypography
                                                    component='span'
                                                    variant='h6'
                                                    fontWeight='bold'
                                                    color='dark'
                                                  >
                                                    <FormattedMessage
                                                      id={"msg_drag_drop_file2"}
                                                    />
                                                  </SuiTypography>
                                                  <SuiTypography
                                                    component='span'
                                                    variant='h6'
                                                    fontWeight='bold'
                                                    color='dark'
                                                  >
                                                    <FormattedMessage
                                                      id={"msg_drag_drop_file3"}
                                                    />
                                                  </SuiTypography>
                                                </SuiBox>
                                              )}
                                            </SuiBox>
                                          </div>
                                        )}
                                      </Dropzone>
                                    </Card>
                                  }
                                </Grid>
                                <SuiBox
                                  mt={1}
                                  display='flex'
                                  gap='1rem'
                                  justifyContent='center'
                                >
                                  {files && files.length !== 0 ? (
                                    <SuiButton
                                      type='submit'
                                      variant='gradient'
                                      color='error'
                                      size='small'
                                      //  disabled={disabled}
                                      onClick={removeAllFiles}
                                    >
                                      {formatMessage({ id: "remove_files" })}
                                    </SuiButton>
                                  ) : (
                                    ""
                                  )}
                                  <SuiButton
                                    type='submit'
                                    variant='gradient'
                                    color='success'
                                    size='small'
                                    //  disabled={disabled}
                                    onClick={sendImportedData}
                                  >
                                    {formatMessage({ id: "send" })}
                                  </SuiButton>
                                </SuiBox>
                              </SuiBox>
                            </SuiBox>
                          </Card>
                        </Grid>
                      </Modal>
                    </SuiBox>
                  </SuiBox>
                )}
                {activeTabList.sales === 1 && ""}
              </Grid>
            </SuiBox>
          </SuiBox>{" "}
          <TabPanel value={activeTabList.sales} index={0}>
            <GridExample
              endPoint={endPointSalesPurchase.purchaseSalesGetReel}
              tableType={tableTypeList.salesList}
            />
            {/* <AvsDataTable
          isCheck={false}
          isInitalGroup={true}
          tableData={axabtaMizanList}
          tableType={tableTypeList.axabtaList}
        /> */}
            {/* <AvsDataGrid /> */}
          </TabPanel>
          <TabPanel value={activeTabList.sales} index={1}>
            {/* <AvsDataTable
          isCheck={false}
          isInitalGroup={false}
          tableData={duzeltmeKayit}
          tableType={tableTypeList.duzeltmeList}
        /> */}
          </TabPanel>
        </SuiBox>
      ) : (
        ""
      )}
    </SuiBox>
  );
}

export default AvsTab;
