import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

//service axios
import axios from "services/axios";

//formatters
import { FormattedNumber } from "react-intl";

//table button
import ButtonRenderer from "components/avs/AvsAgGrid/ServerSide/buttonRenderer";

//table header
import HeaderRenderer from "components/avs/AvsAgGrid/ServerSide/headerRenderer";
//table header
import NumericEditor from "components/avs/AvsAgGrid/ServerSide/NumericEditor";
//table header
import SelectEditor from "components/avs/AvsAgGrid/ServerSide/SelectEditor";

// utils
import { tableTypeList } from "utils/constants/recordType";
import { getCurrencyListForSelect } from "utils/constants/currency";

import { useIntl } from "react-intl";

const CURRENCY_LIST = ["USD", "EUR", "TRY", "GBP", "SGD"];

const salesColumn = (tableType, filterDisable, filterData) => {
  let checkBox = {
    headerName: "",
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,

    pinned: "left",
    width: "50",
    field: "ID",
    filter: false,
    sort: false,
  };

  let action = {
    headerName: "#",
    sortable: false,
    filter: false,
    pinned: "right",
    width: 50,
    cellRenderer: ButtonRenderer,
  };

  let kyn = {
    headerName: "source_short",
    headerComponent: HeaderRenderer,
    field: "KYN",
    filter: "agTextColumnFilter",
  };

  let mainColumn = [
    {
      headerName: "ID",
      // sorting and other feature not showing from custom header
      // headerComponent: HeaderRenderer,
      field: "ID",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      headerName: "Dosya No",
      field: "DOSYANO",
      filter: "agTextColumnFilter",
      cellEditor: NumericEditor,
      cellEditorPopup: true,
      editable: true,
    },
    {
      headerName: "INVOICE DATE",
      field: "INVOICEDATE",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      headerName: "TRS No",
      field: "TRSNO",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      headerName: "Yıl",
      field: "YIL",
      filter: "agTextColumnFilter",
      cellEditor: NumericEditor,
      cellEditorPopup: true,
      editable: true,
      valueSetter: (params) => {
        console.log(params.data);

        params.data.YIL = params.newValue;
        return false;
      },
    },
    {
      headerName: "Ay",
      field: "AY",
      filter: "agTextColumnFilter",
      cellEditor: NumericEditor,
      cellEditorPopup: true,
      editable: true,
      // rowGroup: true,
      // filterParams: {
      //   values: ["120-001-001"],
      // },
    },
    {
      headerName: "P.A.USD",
      field: "PURCHASEAMOUNTUSD",
      filter: "agNumberColumnFilter",
      sortable: true,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
      cellEditor: NumericEditor,
      cellEditorPopup: true,
      editable: true,
    },
    {
      headerName: "S.A.USD",
      field: "SALESAMOUNTUSD",
      filter: "agNumberColumnFilter",
      sortable: true,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
      cellEditor: NumericEditor,
      cellEditorPopup: true,
      editable: true,
    },
    {
      headerName: "Firm ID",
      field: "FIRMAID",
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "Firm",
      field: "FIRMA",
      filter: "agTextColumnFilter",
      sortable: true,
      editable: true,
      cellEditorPopup: true,
      cellEditor: SelectEditor,
      valueSetter: (params) => {
        console.log(params.data);

        return params.data;
      },
    },
    {
      headerName: "Ship Name",
      field: "SHIPNAME",
      filter: "agTextColumnFilter",
      sortable: true,
      editable: true,
      cellEditorPopup: true,
      cellEditor: SelectEditor,
      valueSetter: (params) => {
        return params.data;
      },
    },
    {
      headerName: "Purchase Place",
      field: "PURCHASEPLACE",
      filter: "agTextColumnFilter",
      sortable: true,
      editable: true,
      cellEditorPopup: true,
      cellEditor: SelectEditor,
      valueSetter: (params) => {
        return params.data;
      },
    },
    {
      headerName: "Country",
      field: "COUNTRY",
      filter: "agTextColumnFilter",
      sortable: true,
      editable: true,
      cellEditorPopup: true,
      cellEditor: SelectEditor,
      valueSetter: (params) => {
        console.log(params.data);

        return params.data;
      },
    },
    {
      headerName: "Delivery Port",
      field: "DELIVERYPORT",
      filter: "agTextColumnFilter",
      sortable: true,
      editable: true,
      cellEditorPopup: true,
      cellEditor: SelectEditor,
      valueSetter: (params) => {
        return params.data;
      },
    },
    {
      headerName: "Currency Purchase",
      field: "CURRENCYPURCHASE",
      filter: "agTextColumnFilter",
      sortable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorPopup: true,
      cellEditorParams: (params) => {
        return {
          values: CURRENCY_LIST,
        };
      },
    },
    {
      headerName: "P.Amount",
      field: "PURCHASEAMOUNT",
      filter: "agNumberColumnFilter",
      sortable: true,
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
      cellEditor: NumericEditor,
      cellEditorPopup: true,
      editable: true,
    },
    {
      headerName: "Customer ID",
      field: "CUSTOMERID",
      filter: "agTextColumnFilter",
      // aggFunc: "mySum",
      sortable: true,
    },
    {
      headerName: "Customer",
      field: "CUSTOMER",
      filter: "agTextColumnFilter",
      sortable: true,
      editable: true,
      cellEditorPopup: true,
      cellEditor: SelectEditor,
      valueSetter: (params) => {
        return params.data;
      },
    },
    {
      headerName: "Currency Sales",
      field: "CURRENCYSALES",
      filter: "agTextColumnFilter",
      // aggFunc: "mySum",
      sortable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorPopup: true,
      cellEditorParams: (params) => {
        return {
          values: CURRENCY_LIST,
        };
      },
    },
    {
      headerName: "Customer Group",
      field: "CUSTOMERGROUP",
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "Main Divisions",
      field: "MAINDIVISIONS",
      filter: "agTextColumnFilter",
      sortable: true,
      editable: true,
      cellEditorPopup: true,
      cellEditor: SelectEditor,
      valueSetter: (params) => {
        console.log(params.data);

        return params.data;
      },
    },
    {
      headerName: "Subdivisions",
      field: "SUBDIVISIONS",
      filter: "agTextColumnFilter",
      sortable: true,
      editable: true,
      cellEditorPopup: true,
      cellEditor: SelectEditor,
      valueSetter: (params) => {
        console.log(params.data);

        return params.data;
      },
    },
    {
      headerName: "Divisions",
      field: "DIVISIONS",
      filter: "agTextColumnFilter",
      sortable: true,
      editable: true,
      cellEditorPopup: true,
      cellEditor: SelectEditor,
      valueSetter: (params) => {
        console.log(params.data);

        return params.data;
      },
    },
    {
      headerName: "Responsible Person",
      field: "RESPONSIBLEPERSON",
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "S.Amount",
      field: "SALESAMOUNT",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
      sortable: true,
      cellEditor: NumericEditor,
      cellEditorPopup: true,
      editable: true,
    },
    {
      headerName: "S.A.TL",
      field: "SALESAMOUNTTL",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
      sortable: true,
      cellEditor: NumericEditor,
      cellEditorPopup: true,
      editable: true,
    },
    {
      headerName: "P.A.TL",
      field: "PURCHASEAMOUNTTL",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={value} />
        </div>
      ),
      sortable: true,
      cellEditor: NumericEditor,
      cellEditorPopup: true,
      editable: true,
    },
    {
      headerName: "Other Purchase",
      field: "OTHERPURCHASE",
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "Other Sales",
      field: "OTHERSALES",
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "Created By",
      field: "created_by",
      filter: false,
      // aggFunc: "mySum",
      sortable: true,
      hide: true,
    },
    // {
    //   headerName: "Name Of Port",
    //   // headerName: "currency_rate_short",
    //   // headerComponent: HeaderRenderer,
    //   field: "name_of_port",
    //   filter: true,
    //   // aggFunc: "mySum",
    //   sortable: true,
    // },
    {
      headerName: "KYN",
      field: "KYN",
      filter: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: "Is Deleted",
      field: "ISDELETED",
      filter: false,
      // aggFunc: "mySum",
      sortable: false,
      hide: true,
    },
    {
      headerName: "Is Import",
      field: "ISIMPORT",
      filter: false,
      sortable: false,
      hide: true,
    },
    {
      headerName: "Data Area ID",
      field: "DATAAREAID",
      filter: false,
      sortable: false,
      hide: true,
    },
    {
      headerName: "Report Period",
      field: "REPORTPERIOD",
      filter: false,
      sortable: true,
      hide: true,
    },
  ];
  mainColumn.push(action);

  return mainColumn;
};

export { salesColumn };
