import Swal from "sweetalert2";
import axios from "services/axios";
import SwalTypes from "components/avs/AvsSwal/types";

//state
import { agGridServerSideAtom } from "recoil/atoms";
import { useRecoilState, useSetRecoilState } from "recoil";

const AvsSwal = ({ data }) => {
  const { type, intl, props } = data;
  const newSwal = Swal.mixin({
    customClass: {
      confirmButton: "button button-success",
      cancelButton: "button button-error",
    },
  });
  switch (type) {
    case SwalTypes.avsdataentryedit:
      newSwal
        .fire({
          title: intl.formatMessage({ id: "avsdataentryedit_title" }),
          text: intl.formatMessage(
            { id: "avsdataentryedit_text" },
            {
              name: props?.mainFilterState?.company.toUpperCase(),
            }
          ),
          showCancelButton: true,
          confirmButtonText: intl.formatMessage({ id: "confirm_button_text" }),
          cancelButtonText: intl.formatMessage({ id: "cancel_button_text" }),
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            props?.rows.shift();
            const response = await axios.post(
              props?.endPointMizan.mizanReelCreate,
              {
                data: props?.rows,
              }
            );
            const result = response.data;

            if (result.success) {
              // tableAction.refreshVirmanKayit();
              // tableAction.refreshReelKayit();
              newSwal.fire("Sended!", "Your data has been sended.", "success");

              props?.setRows([]);
              setTimeout(() => {
                props?.navigate(`/mizan`);
              }, 1000);
            } else {
              newSwal.fire("Error!", "Your data has not been sended.", "error");
            }
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            newSwal.fire(
              "Cancelled",
              "Your data not send to database",
              "error"
            );
          }
        });
      break;
    case SwalTypes.avsdataentry:
      newSwal
        .fire({
          title: intl.formatMessage({ id: "avsdataentryedit_title" }),
          text: intl.formatMessage(
            { id: "avsdataentryedit_text" },
            {
              name: props?.mainFilterState?.company.toUpperCase(),
            }
          ),
          showCancelButton: true,
          confirmButtonText: intl.formatMessage({ id: "confirm_button_text" }),
          cancelButtonText: intl.formatMessage({ id: "cancel_button_text" }),
          reverseButtons: false,
        })
        .then(async (result) => {
          if (result.value) {
            console.log(props?.rows);
            const response = await axios.post(
              props?.endPointMizan.mizanReelCreate,
              {
                data: props?.rows,
              }
            );
            const result = response.data;

            if (result.success) {
              //generate new fishNot
              if (!props.isTransferBalance) {
                const fNo = await props?.getFisNo();
                props?.setFishNo(fNo);
              }

              // tableAction.refreshDuzeltmeKayit();
              // tableAction.refreshReelKayit();
              newSwal.fire(
                intl.formatMessage({ id: "msg_success" }),
                intl.formatMessage({ id: "success_data_sended" }),
                "success"
              );

              props?.setRows([]);
              let prevActiveTab = props?.activeTab;
              const refreshTab = props?.activeTab === 0 ? 1 : 0;
              props?.setActiveTab(refreshTab);
              setTimeout(() => props?.setActiveTab(prevActiveTab), 100);
            } else {
              newSwal.fire(
                intl.formatMessage({ id: "msg_warning" }),
                intl.formatMessage({ id: "error_data_sended" }),
                "error"
              );
            }
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            newSwal.fire(
              intl.formatMessage({ id: "msg_cancelled" }),
              intl.formatMessage({ id: "error_data_sended" }),
              "error"
            );
          }
        });
      break;
    case SwalTypes.virementrequestdetailedit:
      newSwal
        .fire({
          title: intl.formatMessage({ id: "avsdataentryedit_title" }),
          showCancelButton: true,
          confirmButtonText: intl.formatMessage({ id: "confirm_button_text" }),
          cancelButtonText: intl.formatMessage({ id: "cancel_button_text" }),
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const request = await axios
              .delete(`/mizan/reel/virman/request-sales/all/${props?.id}`)
              .then((res) => res.data);

            if (request.success) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              setTimeout(() => {
                props?.navigate(`/virement/sales-request/create/${props?.id}`);
              }, 1000);
            } else {
              Swal.fire("Warning!", "Your file has not been deleted.", "error");
            }
            props?.setIsLoading(false);
          }
        });
      break;
    case SwalTypes.buttonRenderer:
      newSwal
        .fire({
          title: intl.formatMessage({ id: "avsdataentryedit_title" }),
          showCancelButton: true,
          confirmButtonText: intl.formatMessage({ id: "confirm_button_text" }),
          cancelButtonText: intl.formatMessage({ id: "cancel_button_text" }),
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await axios.delete(
              `${props?.endPointMizan.mizanReel}/${props?.data.fis}`
            );
            const result = response.data;

            if (result.data[0] <= 0) {
              return Swal.fire("Warning!", "Not founded fis no", "error");
            }

            if (result.success) {
              let prevActiveTab = props?.activeTab;
              const refreshTab = props?.activeTab === 0 ? 1 : 0;
              props?.setActiveTab(refreshTab);
              setTimeout(() => props?.setActiveTab(prevActiveTab), 100);
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            } else {
              Swal.fire("Warning!", "Your file has not been deleted.", "error");
            }
          }
        });
      break;
    case SwalTypes.buttonRendererTransferBalanceDelete:
      newSwal
        .fire({
          title: intl.formatMessage({ id: "avsdataentryedit_title" }),
          showCancelButton: true,
          confirmButtonText: intl.formatMessage({ id: "confirm_button_text" }),
          cancelButtonText: intl.formatMessage({ id: "cancel_button_text" }),
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await axios.delete(
              `${props?.endPointMizan.mizanTransferBalance}/${props?.data.id}`
            );
            const result = response.data;

            if (result.success) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            } else {
              Swal.fire("Warning!", "Your file has not been deleted.", "error");
            }
          }
        });
      break;
    case SwalTypes.buttonRendererSalesDeleteTrigger:
      newSwal
        .fire({
          title: intl.formatMessage({ id: "avsdataentryedit_title" }),
          showCancelButton: true,
          confirmButtonText: intl.formatMessage({ id: "confirm_button_text" }),
          cancelButtonText: intl.formatMessage({ id: "cancel_button_text" }),
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const newIsdeleted = !props?.data.ISDELETED;
            const response = await axios.get(
              `${props?.endPointSalesPurchase.purchaseSalesDeleteToggle}/${props?.data.ID}/${newIsdeleted}`
            );
            const result = response.data;

            if (result.success) {
              Swal.fire(
                newIsdeleted ? "Deleted!" : "Restored!",
                `Your record has been ${
                  newIsdeleted ? "deleted" : "restored"
                } .`,
                "success"
              );
            } else {
              return Swal.fire("Error!", result?.message, "error");
            }
          }
        });
      break;
    case SwalTypes.buttonRendererSalesDelete:
      newSwal
        .fire({
          title: intl.formatMessage({ id: "avsdataentryedit_title" }),
          showCancelButton: true,
          confirmButtonText: intl.formatMessage({ id: "confirm_button_text" }),
          cancelButtonText: intl.formatMessage({ id: "cancel_button_text" }),
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const newIsdeleted = !props?.data.ISDELETED;
            const response = await axios.delete(
              `${props?.endPointSalesPurchase.purchaseSalesDelete}/${props?.data.TRSNO}`
            );
            const result = response.data;

            if (result.success) {
              Swal.fire(
                newIsdeleted ? "Deleted!" : "Restored!",
                `Your record has been ${
                  newIsdeleted ? "deleted" : "restored"
                } .`,
                "success"
              );
            } else {
              return Swal.fire("Error!", result?.message, "error");
            }
          }
        });
      break;
    case SwalTypes.error:
      Swal.fire(
        intl.formatMessage({ id: props?.title }),
        intl.formatMessage({ id: props?.text }),
        "error"
      );
      props?.setIsLoading(false);
      break;
    case SwalTypes.success:
      Swal.fire(
        intl.formatMessage({ id: props?.title }),
        intl.formatMessage({ id: props?.text }),
        "success"
      );
      props?.setIsLoading(false);
      break;
    case SwalTypes.cancelQ:
      newSwal
        .fire({
          title: intl.formatMessage({ id: "avsdataentryedit_title" }),
          text: intl.formatMessage(
            { id: "cancelQ_text" },
            {
              name: intl.formatMessage({ id: props?.text }),
            }
          ),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: intl.formatMessage({ id: "confirm_button_text" }),
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            props?.navigate(`/${props?.navigateTo}`);
          }
        });
      break;
    case SwalTypes.fisDelete:
      newSwal
        .fire({
          title: intl.formatMessage({ id: "avsdataentryedit_title" }),
          showCancelButton: true,
          confirmButtonText: intl.formatMessage({ id: "confirm_button_text" }),
          cancelButtonText: intl.formatMessage({ id: "cancel_button_text" }),
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await axios.delete(
              `${props?.endPointMizan.mizanReel}/${props?.fisNo}`
            );
            const result = response.data;

            if (result.data[0] <= 0) {
              return Swal.fire(
                intl.formatMessage({ id: "error_title" }),
                intl.formatMessage({ id: "error_receipt_number_not_found" }),
                "error"
              );
            }
            //table action refresh data
            props?.tAction.refreshDuzeltmeKayit();
            props?.tAction.refreshVirmanKayit();
            props?.tAction.refreshReelKayit();

            if (result.success) {
              let prevActiveTab = props?.activeTab;
              const refreshTab = props?.activeTab === 0 ? 1 : 0;
              props?.setActiveTab(refreshTab);
              setTimeout(() => props?.setActiveTab(prevActiveTab), 100);

              newSwal.fire(
                intl.formatMessage({ id: "success_deleted" }),
                intl.formatMessage({ id: "success_deleted_message" }),
                "success"
              );
            } else {
              newSwal.fire(
                intl.formatMessage({ id: "error_title" }),
                intl.formatMessage({ id: "error_delete_message" }),
                "error"
              );
            }
          }
        });
      break;
    case SwalTypes.timeInterval:
      Swal.fire({
        title: intl.formatMessage({ id: props.title }),
        html: `${intl.formatMessage({
          id: props.text1,
        })} <br> ${intl.formatMessage({ id: props.text2 })}  ${
          props?.dataCount
        } `,
        allowOutsideClick: false,
        didOpen: () => {
          let transferIsFinished = false;
          newSwal.showLoading();
          props.timerInterval = setInterval(async () => {
            const data = await props?.getDiff();
            console.log(data);
            if (data === 0) {
              transferIsFinished = true;
            }
            const content = Swal.getHtmlContainer();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = props?.dataCount - data;
              }
              if (transferIsFinished) {
                clearInterval(props?.timerInterval);
                newSwal.close();
              }
            }
          }, 2000);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        newSwal.fire(
          intl.formatMessage({ id: "success_good_job" }),
          intl.formatMessage({ id: "success_data_sended" }),
          "success"
        );
      });
      break;
    case SwalTypes.updateRecord:
      newSwal
        .fire({
          title: intl.formatMessage({ id: "avsdataentryedit_title" }),
          text: intl.formatMessage(
            { id: "avsdataentryedit_text" },
            {
              name: props?.mainFilterState?.company.toUpperCase(),
            }
          ),
          showCancelButton: true,
          confirmButtonText: intl.formatMessage({ id: "confirm_button_text" }),
          cancelButtonText: intl.formatMessage({ id: "cancel_button_text" }),
          reverseButtons: false,
        })
        .then(async (result) => {
          if (result.value) {
            //create endpoint
            const reelDataCountUrl = await props?.getEndPointReelDataCount(
              props?.mainFilterState.company,
              props?.mainFilterState.startDate,
              props?.mainFilterState.endDate,
              props?.recordTtype.axabta
            );

            // call alert for data streaming
            await axios.get(reelDataCountUrl).then((res) => {
              props?.showAlert(res.data.data);
            });

            // axabta data transfer to reel db
            const bulkDeleteCreateUrl =
              await props?.getEndPointReelKayitDeleteAndCreateBulk(
                props?.mainFilterState.company,
                props?.mainFilterState.startDate,
                props?.mainFilterState.endDate,
                props?.recordTtype.axabta
              );

            const response = await axios.get(bulkDeleteCreateUrl);
            const result = await response.data;

            if (result.success) {
              // refresh tab
              // this way is not good . You must change . you resolve ag grid refresh
              props.transferIsFinished = true;
              props?.setActiveTab(2);
              setTimeout(() => props?.setActiveTab(3), 100);

              // refresh update data  button count
              const reelAxaptaDiffUrl = await props?.getEndPointReelAxaptaDiff(
                props?.mainFilterState.company,
                props?.mainFilterState.startDate,
                props?.mainFilterState.endDate,
                props?.recordTtype.axabta
              );
              const data = await axios
                .get(reelAxaptaDiffUrl)
                .then((res) => res.data.data);

              props?.setDiffItemNumber(data);
            } else {
              newSwal.fire(
                intl.formatMessage({ id: "msg_cancelled" }),
                intl.formatMessage({ id: "error_data_sended_reeldb" }),
                "error"
              );
            }
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            newSwal.fire(
              intl.formatMessage({ id: "msg_cancelled" }),
              intl.formatMessage({ id: "error_data_sended" }),
              "error"
            );
          }
        });
      break;
    case SwalTypes.sendToReel:
      newSwal
        .fire({
          title: intl.formatMessage({ id: "avsdataentryedit_title" }),
          text: intl.formatMessage(
            { id: "avsdataentryedit_text" },
            {
              name: props?.mainFilterState?.company.toUpperCase(),
            }
          ),
          showCancelButton: true,
          confirmButtonText: intl.formatMessage({ id: "confirm_button_text" }),
          cancelButtonText: intl.formatMessage({ id: "cancel_button_text" }),
          reverseButtons: false,
        })
        .then(async (result) => {
          if (result.value) {
            const response = await axios.post(
              props.endPointMizan.mizanReelCreate,
              {
                data: props.data,
              }
            );
            const result = response.data;

            if (result.success) {
              props.tableAction.refreshDuzeltmeKayit();
              props.setData([]);
              props.setExcelFile(null);
              props.setExcelData(null);

              newSwal.fire("Sended!", "Your data has been sended.", "success");
            } else {
              newSwal.fire("Error!", "Your data has not been sended.", "error");
            }
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            newSwal.fire(
              intl.formatMessage({ id: "msg_cancelled" }),
              intl.formatMessage({ id: "error_data_sended" }),
              "error"
            );
          }
        });
      break;
    case SwalTypes.createVirmanMultipleEditQ:
      newSwal
        .fire({
          title: intl.formatMessage({ id: "avsdataentryedit_title" }),
          text: intl.formatMessage(
            { id: "cancelQ_text" },
            {
              name: intl.formatMessage({ id: props?.text }),
            }
          ),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: intl.formatMessage({ id: "confirm_button_text" }),
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            props.setIsLoading(true);
            const response = await axios.post(props.postTo, {
              data: props.sendData,
            });
            const result = response.data;
            const trsNo = result.data;

            if (result.success) {
              newSwal.fire(
                "Success!",
                "Your file has been success.",
                "success"
              );
              setTimeout(() => {
                props.navigate(`/virman/multiple/edit/${trsNo}`);
                props.setIsLoading(false);
              }, 1000);
            } else {
              newSwal.fire(
                "Warning!",
                "Your file has not been success.",
                "error"
              );
            }
            props.setIsLoading(false);
          }
        });
      break;
    case SwalTypes.delete:
      newSwal
        .fire({
          title: intl.formatMessage({ id: "avsdataentryedit_title" }),
          text: intl.formatMessage(
            { id: "cancelQ_text" },
            {
              name: intl.formatMessage({ id: props?.text }),
            }
          ),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: intl.formatMessage({ id: "confirm_button_text" }),
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await axios.delete(props.route);
            const result = response.data;

            if (result.success) {
              props.setIsLoading(false);

              newSwal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              );
              setTimeout(() => {
                props.navigate(`/${props.navigateTo}`);
              }, 1000);
            } else {
              newSwal.fire(
                "Warning!",
                "Your file has not been deleted.",
                "error"
              );
            }
          } else {
            props.setIsLoading(false);
          }
        });
      break;
    default:
      newSwal.fire({
        title: intl.formatMessage({ id: "avsdataentryedit_title" }),
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({ id: "confirm_button_text" }),
        cancelButtonText: intl.formatMessage({ id: "cancel_button_text" }),
        reverseButtons: true,
      });
      break;
  }
  return newSwal;
};

export default AvsSwal;
