import React, { Component, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";


class ReverseRecordGrid extends Component {
  constructor(props) {
    super(props);
    this.state = props.state;
  }

  getData = () => {
    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      this.setState({ rowData: data });
    };

    httpRequest.open(
      "GET",
      "https://raw.githubusercontent.com/ag-grid/ag-grid/master/grid-packages/ag-grid-docs/src/olympicWinnersSmall.json"
    );
    httpRequest.send();
    httpRequest.onreadystatechange = () => {
      if (httpRequest.readyState === 4 && httpRequest.status === 200) {
        let data = JSON.parse(httpRequest.responseText).map((d, id) => ({
          ...d,
          id,
          registered: (Math.random() < 0.5) ? 0 : 1,
        }));
        updateData(data);
      }
    };
  }
  

  /**
   * If there is a change in state, rerender the component
   */
  componentDidUpdate(prevProps) {
    if (prevProps.state !== this.props.state) {
      this.setState(this.props.state);
      this.getData();
    }
  }
  
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.getData();
  };

  render() {
    return (
      <div style={{ width: "100%", height: "100vh" }}>
        <div
          id="myGrid"
          style={{
            height: "100%",
            width: "100%",
          }}
          className="ag-theme-alpine"
        >
          <AgGridReact
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
          />
        </div>
      </div>
    );
  }
}

export default ReverseRecordGrid;
