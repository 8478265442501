import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";

import { useNavigate } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//state
import { mainFilterAtom, reelKayitAtom } from "recoil/atoms";
import { useRecoilState } from "recoil";

// Soft UI component
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiDatePicker from "components/SuiDatePicker";
import SuiSelect from "components/SuiSelect";

// utils
import { v4 as uuidv4 } from "uuid";
import { getFisNo } from "utils/helpers/avs/index";
import { recordTtype } from "utils/constants/recordType";

//service
import axios from "services/axios/index";
import { endPointMizan } from "services/axios/endpoint";

//actions
import { useTableActions } from "actions/tableAction";
import AvsSwal from "components/avs/AvsSwal";
import SwalTypes from "components/avs/AvsSwal/types";
import { useIntl } from "react-intl";

function AvsDataEntryEdit({ isVirman, id }) {
  const navigate = useNavigate();
  const intl = useIntl();
  // dynamic row state
  const [mainRecord, setMainRecord] = useState();
  const [rows, setRows] = useState([]);
  // dynamic row total state

  //global state
  const [mainFilterState, setMainFilterState] = useRecoilState(mainFilterAtom);
  const [reelKayit, setReelnKayit] = useRecoilState(reelKayitAtom);

  //Hesab kodu list
  const [hesabTypeList, setHesabTypeList] = useState([]);
  const [hesabKoduList, setHesabKoduList] = useState([]);
  const [hesabKoduListSelect, setHesabKoduListSelect] = useState([]);

  //fishno
  const [fishNo, setFishNo] = useState("");
  // const [dataArea, setDateArea] = useState("");

  //use table action
  const tableAction = useTableActions();

  useEffect(() => {
    async function fetchData() {
      const data = await axios
        .get(`${endPointMizan.mizanReel}/${id}`)
        .then((res) => res.data.data);
      console.log(data);
      var newData = {
        ...data,
        id: uuidv4(),
      };
      setMainRecord(newData);

      const fNo = await getFisNo();
      setFishNo(fNo);
      const itemReverse = await generateRow(data, fNo, true);
      const itemNew = await generateRow(data, fNo, false);
      setRows([newData, itemReverse, itemNew]);
    }

    fetchData();
  }, [id]);

  // useEffect(() => {
  //   async function fetchData() {
  //     var data = reelKayit.find((o) => o.id === parseInt(id));

  //     var newData = {
  //       ...data,
  //       id: uuidv4(),
  //     };
  //     setMainRecord(newData);

  //     const fNo = await getFisNo();
  //     setFishNo(fNo);
  //     const itemReverse = await generateRow(data, fNo, true);
  //     const itemNew = await generateRow(data, fNo, false);
  //     setRows([newData, itemReverse, itemNew]);
  //   }

  //   fetchData();
  // }, []);

  //generate row
  const generateRow = (data, fNo, isReverse) => {
    console.log(data);
    const item = {
      ...data,
      id: uuidv4(),
      fis: fNo,
      kyn: recordTtype.virman,
      hesapTipi: isReverse ? data.hesapTipi : "",
      hesapKodu: isReverse ? data.hesapKodu : "",
      name: isReverse ? data.name : "",
      txt: isReverse ? data.txt : "",
      isVirman: true,

      isReverse: isReverse ? true : false,
      isCredit: isReverse ? !data.isCredit : data.isCredit,
      tutar: isReverse ? -1 * data.tutar : data.tutar,
      borcUsd: isReverse ? data.alacakUsd : data.borcUsd,
      alacakUsd: isReverse ? data.borcUsd : data.alacakUsd,
      tutarUsd: isReverse ? -1 * data.tutarUsd : data.tutarUsd,
      borcTry: isReverse ? data.alacakTry : data.borcTry,
      alacakTry: isReverse ? data.borcTry : data.alacakTry,
      tutarTry: isReverse ? -1 * data.tutarTry : data.tutarTry,
    };
    return item;
  };

  // get mizan list form api
  useEffect(() => {
    async function fetchDataMizanAciklama() {
      const request = await axios.get(endPointMizan.mainAccount);
      let selectHesabKodu = [],
        selectHesabAdi = [];

      request.data.data.map((item) => {
        selectHesabKodu.push({
          value: item.value,
          label: `${item.value} | ${item.label}`,
        });
      });
      setHesabKoduListSelect(selectHesabKodu);
      setHesabKoduList(request.data.data);
      return request;
    }
    fetchDataMizanAciklama();

    async function getAccountType() {
      try {
        const request = await axios.get(endPointMizan.postingType);
        setHesabTypeList([]);
        request.data.data.map((item) => {
          setHesabTypeList((data) => [
            ...data,
            {
              value: item.descriptionTR,
              label: item.descriptionTR,
            },
          ]);
        });
      } catch (err) {
        console.log(err);
      }
    }

    getAccountType();
  }, []);

  // input change and update to row state
  const handleChange = (id) => (e) => {
    const { name, value } = e.target;
    const newRows = [...rows];
    const index = newRows.findIndex((o) => o.id === id);
    //console.log(value);
    newRows[index] = {
      ...newRows[index],
      [name]: value,
    };

    //update tutarTry and TutarUsd
    if (
      name === "alacakTry" ||
      name === "borcTry" ||
      name === "alacakUsd" ||
      name === "borcUsd"
    ) {
      const tutarTry =
        parseFloat(newRows[index].alacakTry) -
        parseFloat(newRows[index].borcTry);
      const tutarUsd =
        parseFloat(newRows[index].alacakUsd) -
        parseFloat(newRows[index].borcUsd);

      newRows[index] = {
        ...newRows[index],
        ["tutarTry"]: tutarTry,
        ["tutarUsd"]: tutarUsd,
      };
    }

    setRows(newRows);
  };

  const handleTarih = (newDate, id) => {
    const newRows = [...rows];

    const index = newRows.findIndex((o) => o.id === id);

    newRows.map((item) => {
      item.tarih = newDate[0];
    });

    setRows(newRows);
  };

  const handleSelect = (value, idx, selectType) => {
    const newRows = [...rows];

    if (selectType === "hesapKodu") {
      const arr = value.label.split("|");
      newRows[idx] = {
        ...newRows[idx],
        ["hesapKodu"]: arr[0].trim(),
        ["name"]: arr[1].trim(),
      };
    } else {
      newRows[idx] = {
        ...newRows[idx],
        [selectType]: value,
      };
    }

    setRows(newRows);
  };

  //when currency rate fetch and company change
  useEffect(() => {
    //setRows([]);
  }, []);

  // add new row
  useEffect(() => {
    if (rows.length == 0) {
      console.log("rows.length == 0");
      const addRow = async () => {
        const item1 = await generateRow();
        const item2 = await generateRow();

        setRows([item1, item2]);
      };
      //addRow();
    }
  }, [rows]);

  //add row to list
  const handleAddRow = () => {
    const addRow = async () => {
      const item = await generateRow(mainRecord);
      setRows((prevRows) => [...prevRows, item]);
    };
    addRow();
  };

  const handleRemoveSpecificRow = (id) => () => {
    const newRows = [...rows];

    const index = newRows.findIndex((o) => o.id === id);
    if (index !== -1) newRows.splice(index, 1);
    setRows(newRows);
  };

  //data entry submit
  const handleSubmit = async () => {
    var customValidation = await validatorForm();

    //form valdiator
    if (customValidation.status) {
      return AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_form_validator",
            text: customValidation.msg,
          },
        },
      });
    }

    AvsSwal({
      data: {
        type: SwalTypes.avsdataentryedit,
        intl,
        props: {
          mainFilterState,
          rows,
          endPointMizan,
          setRows,
          navigate,
          isTransferBalance: false,
        },
      },
    });
  };

  const validatorForm = () => {
    var msg = "",
      isError = false;
    rows.map((row, i) => {
      if (row.hesapKodu === "") {
        msg += `\nItem ${i + 1} :Hesab Kodu is required!`;
        isError = true;
      }

      if (row.name === "") {
        msg += `\nItem ${i + 1} :Hesab Adi is required!`;
        isError = true;
      }
    });
    return {
      msg: msg,
      status: isError,
    };
  };

  const handleCancel = () => {
    AvsSwal({
      data: {
        type: SwalTypes.cancelQ,
        intl,
        props: {
          text: "virman_record",
          navigateTo: "mizan",
          navigate,
        },
      },
    });
  };

  return (
    <SuiBox mt={1} bgColor="#fff">
      <Table aria-label="simple table">
        <TableHead style={{ display: "table-header-group" }}>
          <TableRow>
            <TableCell
              width="50px"
              style={{
                padding: "2px",
                fontSize: "12px",
              }}
            >
              Tarih
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Fiş No
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Hesap Tipi
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Hesap Kodu - Hesap Adı
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Açıklama
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              PB
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Tutar
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              BorcTRY
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Alacak TRY
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Tutar TRY
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Borc USD
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Alacak USD
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Tutar USD
            </TableCell>
            <TableCell
              width="auto"
              style={{ padding: "2px", fontSize: "12px" }}
            >
              Kur
            </TableCell>
            <TableCell width="40px">
              <SuiBox
                bgColor="success"
                borderRadius="8px"
                width="1.625rem"
                height="1.625rem"
                display="flex"
                style={{ visibility: "hidden" }}
                justifyContent="center"
                alignItems="center"
                color="white"
                mx="auto"
                variant="gradient"
                sx={{ cursor: "pointer" }}
                onClick={handleAddRow}
              >
                <Icon fontSize="small">add</Icon>
              </SuiBox>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((item, idx) => (
            <TableRow
              key={item.id + idx}
              id={idx}
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderBottom: "none",
                },
              }}
            >
              <TableCell
                component="th"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiBox width="100px">
                  <SuiDatePicker
                    name="tarih"
                    value={rows[idx].tarih}
                    onChange={(newDate) => handleTarih(newDate, item.id)}
                  />
                </SuiBox>
              </TableCell>
              <TableCell
                align="right"
                width="140px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="fis"
                  value={idx === 0 ? rows[idx].fis : fishNo}
                  disabled={true}
                />
              </TableCell>
              <TableCell
                width="130px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiBox bgColor="white">
                  {idx < 2 ? (
                    <SuiInput
                      type="text"
                      name="hesapTipi"
                      value={rows[idx].hesapTip}
                      disabled={true}
                    />
                  ) : (
                    <SuiSelect
                      options={hesabTypeList}
                      onChange={(data) =>
                        handleSelect(data.value, idx, "hesapTipi")
                      }
                    />
                  )}
                </SuiBox>
              </TableCell>
              <TableCell
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                {idx < 2 ? (
                  <SuiInput
                    type="text"
                    name="hesapTipi"
                    value={`${rows[idx].hesapKodu} | ${rows[idx].name}`}
                    disabled={true}
                  />
                ) : (
                  <SuiSelect
                    options={hesabKoduListSelect}
                    onChange={(data) => handleSelect(data, idx, "hesapKodu")}
                  />
                )}
              </TableCell>
              <TableCell
                align="right"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="txt"
                  value={rows[idx].txt}
                  disabled={idx < 2 ? true : false}
                  onChange={handleChange(item.id)}
                />
              </TableCell>
              <TableCell
                align="right"
                width="80px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="hesapTipi"
                  value={`${rows[idx].currency}`}
                  disabled={true}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="tutar"
                  disabled={true}
                  value={rows[idx].tutar}
                  onChange={handleChange(item.id)}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="borcTry"
                  disabled={true}
                  value={rows[idx].borcTry}
                  onChange={handleChange(item.id)}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="alacakTry"
                  disabled={true}
                  value={rows[idx].alacakTry}
                  onChange={handleChange(item.id)}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="name"
                  disabled={true}
                  value={rows[idx].tutarTry}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="borcUsd"
                  disabled={true}
                  value={rows[idx].borcUsd}
                  onChange={handleChange(item.id)}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="alacakUsd"
                  disabled={true}
                  value={rows[idx].alacakUsd}
                  onChange={handleChange(item.id)}
                />
              </TableCell>
              <TableCell
                align="right"
                width="100px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="tutarUsd"
                  disabled={true}
                  value={rows[idx].tutarUsd}
                  onChange={handleChange(item.id)}
                />
              </TableCell>
              <TableCell
                align="right"
                width="70px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <SuiInput
                  type="text"
                  name="kur"
                  disabled={true}
                  defaultValue={rows[idx].kur}
                  onChange={handleChange(item.id)}
                />
              </TableCell>
              <TableCell
                width="40px"
                style={{
                  padding: "2px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                {/* {idx > 1 && (
                  <SuiBox
                    bgColor="error"
                    borderRadius="8px"
                    width="1.625rem"
                    height="1.625rem"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    color="white"
                    mx="auto"
                    variant="gradient"
                    sx={{ cursor: "pointer" }}
                    onClick={handleRemoveSpecificRow(item.id)}
                  >
                    <Icon fontSize="small">delete</Icon>
                  </SuiBox>
                )} */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <SuiBox display="flex" justifyContent="center" py={2}>
        <SuiBox mr={1}>
          <SuiButton variant="gradient" color="dark" onClick={handleSubmit}>
            {isVirman ? "Nakil" : "Onayla"}
          </SuiButton>
        </SuiBox>
        {!isVirman ? (
          ""
        ) : (
          <SuiBox mr={1}>
            <SuiButton variant="outline" color="dark" onClick={handleCancel}>
              İptal
            </SuiButton>
          </SuiBox>
        )}
      </SuiBox>
    </SuiBox>
  );
}

// AvsDataEntryEdit default values for the props of AvsDataEntryEdit
AvsDataEntryEdit.defaultProps = {
  isVirman: false,
};

// Typechecking props for the AvsDataEntryEdit
AvsDataEntryEdit.propTypes = {
  isVirman: PropTypes.bool,
};

export default AvsDataEntryEdit;
