const initialValues = {
  date: "",
  dailyNo: "",
  receiptNo: "",
  accType: "",
  accCode: "",
  accName: "",
  explanation: "",
  pb: "",
  total: "",
  debtTRY: "",
  creditTRY: "",
  totalTRY: "",
  debtUSD: "",
  creditUSD: "",
  totalUSD: "",
  rateOfExchange: "",
};

export default initialValues;
