import React, { Component } from "react";
import Checkbox from "@mui/material/Checkbox";

export default class extends Component {
  constructor(props) {
    super(props);
    this.checkedHandler = this.checkedHandler.bind(this);
  }
  checkedHandler(event) {
    let checked = event.target.checked;
    let colId = this.props.column.colId;
    let rowId = this.props.node.id;
    console.log(checked, colId, parseInt(rowId));
    this.props.node.setDataValue(colId, checked ? 1 : 0);
  }
  render() {
    return (
      <Checkbox
        type="checkbox"
        checked={this.props.value === 1 ? true : false}
        onChange={this.checkedHandler}
      />
    );
  }
}
