import { LOCALES } from "i18n/locales";

export const messages = {
  [LOCALES.ENGLISH]: {
    period: "Period",
    reportPeriod: "Report Period",
    finance: "Finance",
    contact_us: "Contact us",
    axapta_ledger: "Axapta Ledger",
    reel_ledger: "Reel Ledger",
    trial_balance: "Trial Balance",
    balance_sheet: "Balance Sheet",
    trial_balance_balance_sheet: "Trial Balance Balance Sheet",
    trial_balance_balance_sheet_income_statement:
      "Trial Balance | Balance Sheet | Income Statement",
    income_statement: "Income Statement",
    sales: "Sales",
    contract_sales: "Contract Sales",
    virement_request_detail: "Virement Request Detail",
    transfer_balance: "Transfer Balance",
    firm: "Company",
    start_date: "Start Date",
    end_date: "End Date",
    axapta_trial_balance: "Axapta Trial Balance",
    amendment_entry: "Amendment Entry",
    virement: "Transfer",
    real_trial_balance: "Real Trial Balance",
    account_plan: "Account Plan",
    account_code: "Account Code",
    account_name: "Account Name",
    account_code_name: "Account Code Account Name",
    date: "Date",
    log_number: "Log Number",
    file_no: "File No",
    voucher_no: "Voucher No",
    account_type: "Account Type",
    currency_unit: "Currency",
    currency_unit_short: "Currency",
    amount: "Amount",
    debit: "Debit",
    credit: "Credit",
    currency_rate: "Currency Rate",
    currency_rate_short: "C.Rate",
    total: "Total",
    confirm: "Confirm",
    import: "Import",
    export: "Export",
    open_input_monitor: "Input Monitor",
    close_input_monitor: "Input Monitor",
    open_error_monitor: "Open Error Monitor",
    close_error_monitor: "Close Error Monitor",
    open_import: "Import",
    remove_files: "Remove Files",
    show_table: "Show Table",
    create_reverse: "Create Reverse",
    create_clone_record: "Create Clone Record",
    create_virement: "Create Transfer",
    create_virement_request: "Create Transfer Request",
    create_partial_virement: "Create Partial Transfer",
    create_multiple_virement: "Create Multiple Transfer",
    create_axabta_virement: "Create Axapta Transfer",
    create_request_virement: "Create Request Transfer",
    transfer: "Transfer",
    cancel: "Cancel",
    out_account_code: "Out Account Code",
    in_account_code: "In Account Code",
    in_record: "In Record",
    out_record: "Out Record",
    update_record: "Update Record",
    list_all: "List All",
    sales: "Sales",
    purchase: "Purchase",
    payment_plan: "Payment Plan",
    payment_plan_1: "Payment",
    payment_plan_2: "Plan",
    receiving_payment: "Receiving Payment",
    receiving: "Receiving",
    payment: "Payment",
    cash: "Cash",
    bank: "Bank",
    description: "Description",
    description_placeholder: "Please enter a description...",
    financial_placeholder: "Please enter a financial...",
    amount_placeholder: "Please enter an amount...",
    receipt_number: "Receipt Number",
    source: "Source",
    source_short: "Src",
    send: "Send",
    main_record: "Main Record",
    reverse_record: "Reverse Record",
    virman_record: "Virman Record",
    new_record: "New Record",
    virement_wish_list: "Virement Wish List",
    create: "Create",
    next: "Next",
    back: "Back",
    list: "List",
    explanation: "Explanation",
    request_type: "Request Type",
    return_action: "Return  Action",

    customer: "Customer",
    vendor: "Vendor",

    //mizan table
    axapta: "Axapta",
    reel_axapta: "Reel Axapta",
    reel: "Reel",
    amendment: "Amendment",
    active: "Active",
    passive: "Passive",
    active_axapta: "Active Axapta",
    passive_axapta: "Passive Axapta",
    active_reel: "Active Reel",
    passive_reel: "Passive Reel",

    registered: "Registered",
    dailyNo: "Daily No",
    debit_try: "DebitTRY",
    credit_try: "CreditTRY",
    amount_try: "AmountTRY",
    debit_usd: "DebitUSD",
    credit_usd: "CreditUSD",
    amount_usd: "AmountUSD",

    debit_try_short: "D.TRY",
    credit_try_short: "C.TRY",
    amount_try_short: "A.TRY",
    debit_usd_short: "D.USD",
    credit_usd_short: "C.USD",
    amount_usd_short: "A.USD",

    //footer total
    total_debit_try: "Total Debit (TRY)",
    total_credit_try: "Total Credit (TRY)",
    total_try: "Total (TRY)",
    total_debit_usd: "Total Debit (USD)",
    total_credit_usd: "Total Credit (USD)",
    total_usd: "Total (USD)",

    warning: "Warning",
    success: "Success",
    unsuccess: "Unsuccess",

    //message
    msg_are_you_sure: "Are you sure?",
    msg_you_wont_be_able_to_revert_this: "You won't be able to revert this!",
    msg_yes_delete_it: "Yes, delete it.",
    msg_yes_send_it: "Yes, send it.",
    msg_please_select: "Please select...",
    msg_you_file_has_been_success: "Your file has been success!",
    msg_form_validator: "Form validator!",
    msg_you_must_check_subtotal_all_subtotal_price_must_be_zero:
      "You must check subtotal. All subtotal price must be 0!",
    msg_you_entered_data_to: "You entered the data to",
    msg_there_is_no_new_data_in_axabta: "There is no new data in Axapta!",
    msg_cancelled: "Cancelled!",
    msg_your_data_not_sended_to_database: "Your data not sended to database!",
    msg_enter_explanation: "Please enter explanation...",
    msg_drag_drop_file1: "To upload a file,",
    msg_drag_drop_file2: "drag the file you want",
    msg_drag_drop_file3: "to upload or click here.",

    msg_cancel: "Cancel!",
    msg_warning: "Warning!",
    msg_success: "Success!",
    msg_unsuccess: "Unsuccess!",

    //Swal parts
    avsdataentryedit_title: "Are you sure?",
    avsdataentryedit_text: "Data entered to {name}",
    cancelQ_text: "{name} will be cancelled!",
    confirm_button_text: "Yes, send!",
    cancel_button_text: "No, cancel!",
    error_title: "Warning!",
    error_form_validator: "Form Validator!",
    error_bad_job: "Bad Job!",
    error_check_subtotal: "You must check Subtotal. All Subtotal not be 0!",
    error_no_new_data_in_axabta: "There is no new data in Axapta.",
    error_axapta_listing: "Problem occured when Axapta data listing...",
    error_create_reverse: "Reverse Record create error!",
    error_create_selected_item: "Please , Reverse Record select!",
    error_select_only_excel_file_type: "Please select only excel file types.",
    error_select_account_type: "Please select account type.",
    error_enter_record_description: "Please enter record description.",
    error_select_record: "Please select record.",
    error_fill_sections: "Please fill sections below.",
    error_select_date: "Please Select a date...",
    error_create_reverse_record_first: "Please create reverse record first...",
    error_description: "Description must be entered.",
    error_virement_request_record: "Virement Request Record couldn't created.",
    error_virement_update: "Virement Update Request Error.",
    error_reverse_record_create: "Reverse Record Create Request Error.",
    error_new_record_create: "New Record Create Request Error.",
    error_virement_sales_delete: "Virman Sales Delete Request Error.",
    error_receipt_number_not_found: "No Receipt Number Found.",
    error_delete_message: "Your file has not been deleted.",
    error_data_sended: "Your data has not been sended.",
    error_data_sended_reeldb: "Your data has not been sended to Reel DB.",
    error_areas_must_filled: "Please fill all the inputs.",
    start_data_transfer: "Start data transfer",
    data_transfer_text: "I will send <b></b> data from",
    data_transfer_text1: "Total data count -> <b></b> ",
    data_transfer_text2: "Number of data to send -> ",
    success_good_job: "Good Job!",
    success_deleted: "Deleted!",
    success_good_job_message: "Transaction Successful...",
    success_create_reverse: "Reverse Record creation successful!",
    success_deleted_message: "Your file has been deleted.",
    success_data_sended: "Your data has been sended.",
    success_axapta_data_listed: "Axapta data listed.",
    success_virement_request_record: "Virement Request Record created.",
    success_virement_update: "Virement Update Request Done.",
    success_reverse_record_create: "Reverse Record Create Request Done.",
    success_new_record_create: "New Record Create Request Done.",
    success_virement_sales_delete: "Virman Sales Delete Request Done.",
    success_add_new_entry: "Adding new entry successful",
    //edit part
    id: "ID",
    dosyaNo: "File No",
    trs_no: "TRS No",
    yil: "Year",
    ay: "Month",
    purchaseAmountUsd: "Purchase USD",
    salesAmountUsd: "Sales USD",
    firmaId: "Firm ID",
    firma: "Firm",
    shipName: "Ship Name",
    purchasePlace: "Purchase Place",
    country: "Country",
    deliveryPort: "Delivery Port",
    currencyPurchase: "Cur. Purchase",
    purchaseAmount: "Purchase",
    customerId: "Customer ID",
    currencySales: "Currency Sales",
    customerGroup: "Customer Group",
    mainDivision: "Main Divisions",
    subDivisions: "Subdivisions",
    divisions: "Divisions",
    responsiblePerson: "Res. Person",
    salesAmount: "Sales Amount",
    salesAmountTl: "Sales TL",
    purchaseAmountTl: "Purchase TL",
    otherPurchase: "Other Purchase",
    otherSales: "Other Sales",
    created_by: "Created By",
    name_port: "Port Name",
    kyn: "KYN",
    create_new_entry: "Create New Entry",
  },

  [LOCALES.TURKISH]: {
    period: "Dönem",
    reportPeriod: "Rapor Dönemi",
    finance: "Finans",
    contact_us: "Kontaktiere uns",
    axapta_ledger: "Axapta Defter",
    reel_ledger: "Reel Defter",
    trial_balance: "Mizan",
    balance_sheet: "Bilanço",
    trial_balance_balance_sheet: "Mizan | Bilanço",
    trial_balance_balance_sheet_income_statement:
      "Mizan | Bilanço | Gelir Tablosu",
    income_statement: "Gelir Tablosu",
    sales: "Satış",
    contract_sales: "Sözleşmeli Satış",
    virement_request_detail: "Virman İstek Detayı",
    transfer_balance: "Yıllık Açılış/Kapanış",
    firm: "Şirket",
    start_date: "Başlangıç Tarihi",
    end_date: "Bitiş Tarihi",
    axapta_trial_balance: "A.M.Mizan",
    amendment_entry: "Düzeltme Kayıtları",
    virement: "Virman",
    real_trial_balance: "R.M.Mizan",
    account_plan: "Hesap Planı",
    account_code: "Hesap Kodu",
    account_name: "Hesap Adı",
    account_code_name: "Hesap Kodu Hesap Adı",
    date: "Tarih",
    log_number: "Günlük No",
    file_no: "Dosya Numarası",
    voucher_no: "Fiş No",
    account_type: "Hesap Tipi",
    currency_unit: "Para Birimi",
    currency_unit_short: "PB",
    amount: "Tutar",
    debit: "Borç",
    credit: "Alacak",
    currency_rate: "Kur",
    currency_rate_short: "Kur",
    total: "Toplam",
    confirm: "Onayla",
    import: "İçe Aktar",
    export: "Dışa Aktar",
    remove_files: "Dosyaları Sil",
    open_input_monitor: "Giriş Ekranını Aç",
    close_input_monitor: "Giriş Ekranını Kapat",
    open_error_monitor: "Hata Ekranını Aç",
    close_error_monitor: "Hata Ekranını Kapat",
    open_import: "İçeri Aktar",
    show_table: "Tabloyu Göster",
    create_reverse: "Ters Kayıt Oluştur",
    create_clone_record: "Klon Kayıt Oluştur",
    create_virement: "Virman Oluştur",
    create_virement_request: "Virman İsteği Oluştur",
    create_partial_virement: "Kısmİ Virman Oluştur",
    create_multiple_virement: "Çoklu Virman Oluştur",
    create_axabta_virement: "Çoklu Axapta Oluştur",
    create_request_virement: "İstek Virman Oluştur",
    transfer: "Nakil",
    cancel: "İptal",
    out_account_code: "Çıkan Hesap Kodu",
    in_account_code: "Giren Hesap Kodu",
    in_record: "Giren Kayıt",
    out_record: "Çıkan Kayıt",
    update_record: "Güncel Kayıt",
    list_all: "Tümünü Listele",
    sales: "Satış",
    purchase: "Alış",
    payment_plan: "Ödeme Planı",
    payment_plan_1: "Ödeme",
    payment_plan_2: "Planı",
    receiving: "Tahsilat",
    payment: "Ödemesi",
    cash: "Kasa",
    bank: "Banka",
    description: "Açıklama",
    description_placeholder: "Açıklama Giriniz...",
    financial_placeholder: "Finans Başlığı Giriniz...",
    amount_placeholder: "Miktar Giriniz...",
    receipt_number: "Fiş No",
    source: "Kaynak",
    source_short: "Kyn",
    send: "Gönder",
    main_record: "Ana Kayıt",
    reverse_record: "Ters Kayıt",
    virman_record: "Virman Kayıt",
    new_record: "Yeni Kayıt",
    virement_wish_list: "Virman İstek Listesi",
    create: "Oluştur",
    next: "Sonraki",
    back: "Geri",
    list: "Listele",
    explanation: "Açıklama",
    add_row: "Satır Ekle",
    search_placeholder: "Ara...",
    request_type: "İstek Türü",
    return_action: "Geri İşlem",

    customer: "Alıcı",
    vendor: "Satıcı",

    //mizan table
    axapta: "Axapta",
    reel_axapta: "Reel Axapta",
    reel: "Reel",
    amendment: "Fark",
    active: "Aktif",
    passive: "Pasif",
    active_axapta: "Aktif Axapta",
    passive_axapta: "Pasif Axapta",
    active_reel: "Aktif Reel",
    passive_reel: "Pasif Reel",

    registered: "Kayıtlı",
    dailyNo: "Günlük No",

    debit_try: "BorcTRY",
    credit_try: "AlacakTRY",
    amount_try: "TutarTRY",
    debit_usd: "BorcUSD",
    credit_usd: "AlacakUSD",
    amount_usd: "TutarUSD",

    debit_try_short: "B.TRY",
    credit_try_short: "A.TRY",
    amount_try_short: "T.TRY",
    debit_usd_short: "B.USD",
    credit_usd_short: "A.USD",
    amount_usd_short: "T.USD",

    //footer toplam
    total_debit_try: "Toplam Borc (TRY)",
    total_credit_try: "Toplam Alacak (TRY)",
    total_try: "Toplam (TRY)",
    total_debit_usd: "Toplam Borc (USD)",
    total_credit_usd: "Toplam Alacak (USD)",
    total_usd: "Toplam (USD)",

    cancel: "İptal",
    warning: "Uyarı",
    success: "Başarılı",
    unsuccess: "Başarısız",

    //message
    msg_are_you_sure: "Emin misin?",
    msg_you_wont_be_able_to_revert_this: "Bu geri alınamaz!",
    msg_yes_delete_it: "Evet, silmek istiyorum.",
    msg_yes_send_it: "Evet, göndermek istiyorum.",
    msg_please_select: "Lütfen seçiniz...",
    msg_you_file_has_been_success: "Dosya başarılı!",
    msg_form_validator: "Form doğrulayıcı!",
    msg_you_must_check_subtotal_all_subtotal_price_must_be_zero:
      "Ara toplamı kontrol ediniz. Her ara 0 olmalıdır!",
    msg_you_entered_data_to: "Veri girişini yaptınız ",
    msg_there_is_no_new_data_in_axabta: "Axapta'da yeni veri yok!",
    msg_cancelled: "İptal edildi!",
    msg_your_data_not_sended_to_database: "Veri, veri tabanına gönderilmedi!",
    msg_enter_explanation: "Açıklama giriniz...",
    msg_drag_drop_file1: "Dosya yüklemek için yüklemek",
    msg_drag_drop_file2: "istediğiniz dosyayı sürükleyin",
    msg_drag_drop_file3: "veya buraya bırakın",

    msg_cancel: "İptal!",
    msg_warning: "Uyarı!",
    msg_success: "Başarılı!",
    msg_unsuccess: "Başarısız!",

    //Swal parts
    avsdataentryedit_title: "Emin Misiniz?",
    avsdataentryedit_text: "Veri giriliyor -> {name}",
    cancelQ_text: "{name} iptal edilecek!",
    confirm_button_text: "Evet, Gönder!",
    cancel_button_text: "Hayır, iptal et!",
    error_title: "Dikkat!",
    error_form_validator: "Form Doğrulayıcı!",
    error_bad_job: "Hata!",
    error_check_subtotal:
      "Ara toplamı kontrol edin. Bütün ara toplamlar 0 olamaz!",
    error_no_new_data_in_axabta: "Axapta'da yeni veri yok.",
    error_axapta_listing: "Axapta'da veri listelenirken problem oluştu...",
    error_create_reverse: "Ters Kayıt oluşturma hatası!",
    error_create_selected_item: "Lütfen , Kayıt seçiniz!",
    error_select_only_excel_file_type:
      "Lütfen sadece excel tipindeki dosyaları seçiniz.",
    error_select_account_type: "Lütfen hesap tipi seçiniz.",
    error_enter_record_description: "Lütfen kayıt açıklaması giriniz.",
    error_select_record: "Lütfen Kayıt seçiniz.",
    error_fill_sections: "Lütfen aşağıdaki kısımları doldurunuz.",
    error_select_date: "Lütfen bir tarih seçin...",
    error_create_reverse_record_first: "Lütfen önce ters kayıt oluşturunuz...",
    error_description: "Açıklama girilmek zorundadır.",
    error_virement_request_record: "Virman İstek Kaydı oluşturulamadı.",
    error_virement_update: "Virman Güncelleme İsteği Hatası!",
    error_reverse_record_create: "Ters Kayıt Oluşturma İsteği Hatası!",
    error_new_record_create: "Yeni Kayıt Oluşturma İsteği Hatası!",
    error_virement_sales_delete: "Virman Satış Silme İsteği Hatası",
    error_receipt_number_not_found: "Fiş Numarası Bulunamadı.",
    error_delete_message: "Dosya silme başarısız.",
    error_data_sended: "Veri gönderilemedi.",
    error_data_sended_reeldb: "Veri Reel Veritabanına gönderilemedi.",
    start_data_transfer: "Veri transferine başlanıyor",
    data_transfer_text1: "Toplam veri sayısı -> <b></b> ",
    data_transfer_text2: "Gönderilecek data sayısı -> ",
    error_areas_must_filled: "Lütfen Alanları Doldurunuz.",
    start_data_transfer: "Veri transferine başla",
    data_transfer_text: "<b></b> gönderilecek -> ",
    success_good_job: "Tamamlandı!",
    success_deleted: "Silindi!",
    success_good_job_message: "İşlem Başarılı...",
    success_create_reverse: "Ters Kayıt Oluşturma Başarılı!",
    success_deleted_message: "Dosya başarıyla silindi.",
    success_data_sended: "Veri başarıyla gönderildi.",
    success_axapta_data_listed: "Axapta verileri listelendi.",
    success_virement_request_record: "Virman Kayıt İsteği oluşturuldu.",
    success_virement_update: "Virman Güncelleştirme İsteği tamamlandı.",
    success_reverse_record_create: "Ters Kayıt Oluşturma İsteği tamamlandı.",
    success_new_record_create: "Yeni Kayıt Oluşturma İsteği tamamlandı.",
    success_virement_sales_delete: "Virman Satış Silme İsteği Tamamlandı.",
    success_add_new_entry: "Yeni kayıt ekleme başarılı.",

    //edit part
    id: "ID",
    dosyaNo: "Dosya No",
    trs_no: "TRS No",
    yil: "Yıl",
    ay: "Ay",
    purchaseAmountUsd: "Satın Alma USD",
    salesAmountUsd: "Satış USD",
    firmaId: "Firma ID",
    firma: "Firma",
    shipName: "Gemi Adı",
    purchasePlace: "Satın Alma Yeri",
    country: "Ülke",
    deliveryPort: "Teslimat Limanı",
    currencyPurchase: "Satın Alma Birimi",
    purchaseAmount: "Satın Alma",
    customerId: "Alıcı ID",
    currencySales: "Satış Para Birimi",
    customerGroup: "Alıcı Grubu",
    mainDivision: "Ana Bölümler",
    subDivisions: "Alt Bölümler",
    divisions: "Bölümler",
    responsiblePerson: "Sorumlu Kişi",
    salesAmount: "Satış Miktarı",
    salesAmountTl: "Satış TL",
    purchaseAmountTl: "Satın Alma TL",
    otherPurchase: "Diğer Satın Almalar",
    otherSales: "Diğer Satışlar",
    created_by: "Oluşturan",
    name_port: "Port İsmi",
    kyn: "KYN",
    create_new_entry: "Yeni Giriş Oluştur",
  },
};
