/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";
import { FormattedMessage } from "react-intl";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select from "react-select";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

// Custom styles for SuiSelect
import styles from "components/SuiSelect/styles";

//Soft UI Components
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";

const SuiSelect = forwardRef(({ size, error, success, label, ...rest }, ref) => {
  const { light } = colors;

  return (
    <SuiBox >
      {label && (
        <SuiBox display="flex" alignItems="center" my={1}>
          <SuiTypography
            variant="caption"
            textTransform="capitalize"
            fontWeight="bold"
          >
            <FormattedMessage id={label} />
          </SuiTypography>
        </SuiBox>
      )}
      <Select
        {...rest}
        instanceId={"sui-select-instance"}
        ref={ref}
        styles={styles(size, error, success)}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: light.main,
            primary: light.main,
          },
        })}
      // loadingMessage={() => "Loading..."}
      />
    </SuiBox>
  );
});

// Setting default values for the props of SuiSelect
SuiSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
};

// Typechecking props for the SuiSelect
SuiSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
};

export default SuiSelect;
