import React from "react";

// @mui material components
import Icon from "@mui/material/Icon";

//navigate
import { useNavigate , useLocation} from "react-router-dom";

//axios
import axios from "services/axios/index";
import { endPointMizan ,endPointSalesPurchase } from "services/axios/endpoint";

//state
import { activeTabAtom } from "recoil/atoms";
import { useRecoilState } from "recoil";
import AvsSwal from "components/avs/AvsSwal";
import SwalTypes from "components/avs/AvsSwal/types";
import { useIntl } from "react-intl";

export default (props) => {
  let activeTabState = 3;
  
  const navigate = useNavigate();
  const { hash, pathname, search } = useLocation();
  const intl = useIntl();
  
  //global state
  const [activeTab, setActiveTab] = useRecoilState(activeTabAtom);

  //delete handle function . You must global
  const handleDeleteWithFis = () => {
    activeTabState = activeTab;
    //console.log(props.data.fis);
    AvsSwal({
      data: {
        type: SwalTypes.buttonRenderer,
        intl,
        props: {
          endPointMizan,
          data: props.data,
          activeTab,
          setActiveTab,
        },
      },
    });
  };
  const handleDeleteTransferBalance = () => {
    AvsSwal({
      data: {
        type: SwalTypes.buttonRendererTransferBalanceDelete,
        intl,
        props: {
          endPointMizan,
          data: props.data  
        },
      },
    });
  };

  const handleDeleteSalesPurchaseTrigger = () => {
    console.log(props.data.ID);
    activeTabState = activeTab;
 
    AvsSwal({
      data: {
        type: SwalTypes.buttonRendererSalesDeleteTrigger,
        intl,
        props: {
          endPointSalesPurchase,
          data: props.data,
          activeTab,
          setActiveTab,
        },
      },
    });
  };
  const handleDeleteSalesPurchase = () => {
    activeTabState = activeTab;
 
    AvsSwal({
      data: {
        type: SwalTypes.buttonRendererSalesDelete,
        intl,
        props: {
          endPointSalesPurchase,
          data: props.data,
          activeTab,
          setActiveTab,
        },
      },
    });
  };

  const handleMultiVirmanShow = () => {
    navigate(`/virman/multiple/edit/${props.data.trsNo}`);
  };

  const handleRequestSalesVirmanShow = () => {
    navigate(`/virement/sales-request/edit/${props.data.requestSalesId}`);
  };
  const handleChangeAxabtaRecordForVirman = () => {
    navigate(`/virman/with-record/${props.data.id}`);
  };

  return (
    <div>
      {props.data.isMainAccount  && props.data.kyn !== "A" &&
        !props.data.isVirmanRelation &&
        props.data.requestSalesId === null && !props.data.isOpening &&(
          <Icon
            fontSize="medium"
            color="error"
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteWithFis()}
          >
            delete
          </Icon>
        )}
      { props.data.kyn === "V" && props.data.isOpening && (
          <Icon
            fontSize="medium"
            color="error"
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteTransferBalance()}
          >
            delete_forever
          </Icon>
        )}

     {/* add this condition for sales purchase  */}
      { props.data.hasOwnProperty('CURRENCYSALES') && props.data.hasOwnProperty('CURRENCYPURCHASE') 
      && !props?.data.ISDELETED && props?.data.KYN === "A" && props?.data.ISCLONED === false && (
          <Icon
            fontSize="medium"
            color="error"
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteSalesPurchaseTrigger()}
          >
            delete
          </Icon>
        )}
      { props.data.hasOwnProperty('CURRENCYSALES') && props.data.hasOwnProperty('CURRENCYPURCHASE') && !props?.data.ISDELETED && props?.data.KYN === "D"  &&(
          <Icon
            fontSize="medium"
            color="error"
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteSalesPurchase()}
          >
            delete
          </Icon>
        )}
      { props.data.hasOwnProperty('CURRENCYSALES') && props.data.hasOwnProperty('CURRENCYPURCHASE') && props?.data.ISDELETED && (
          <Icon
            fontSize="medium"
            color="warning"
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteSalesPurchaseTrigger()}
          >
            restore_from_trash
          </Icon>
        )}
      { props.data.kyn === "A" && (
        <Icon
          fontSize="medium"
          color="info"
          style={{ cursor: "pointer" }}
          onClick={() => handleChangeAxabtaRecordForVirman()}
        >
          published_with_changes
        </Icon>
      )}

      {  props.data.kyn === "V" && props.data.isVirmanRelation === true && (
        <Icon
          fontSize="medium"
          color="info"
          style={{ cursor: "pointer" }}
          onClick={() => handleMultiVirmanShow()}
        >
          attachment
        </Icon>
      )}
      {props.data.isMainAccount  && props.data.kyn === "V" && props.data.requestSalesId !== null && (
        <Icon
          fontSize="medium"
          color="info"
          style={{ cursor: "pointer" }}
          onClick={() => handleRequestSalesVirmanShow()}
        >
          group
        </Icon>
      )}
    </div>
  );
};
