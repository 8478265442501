import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { LicenseManager } from "ag-grid-enterprise";

import "./styles.css";

import { useIntl } from "react-intl";

//service axios
import axios from "services/axios/index";
import { endPointSalesPurchase } from "services/axios/endpoint";
import { getDateFormatYearMonthDay } from "utils/helpers/dateFormat";

//state
import {
  footerTotalAtom,
  mainFilterAtom,
  virmanSelectAtom,
  agGridServerSideAtom,
  isLoadingAtom,
} from "recoil/atoms";
import { useSetRecoilState, useRecoilValue } from "recoil";

//table column
import { mizanColumn } from "components/avs/AvsAgGrid/ServerSide/column";
import { salesColumn } from "components/avs/AvsAgGrid/ServerSide/salesColumn";
// utils
import { tableTypeList } from "utils/constants/recordType";

import AvsSwal from "components/avs/AvsSwal";
import SwalTypes from "components/avs/AvsSwal/types";

LicenseManager.setLicenseKey(
  "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-4_September_2022_[v2]_MTY2MjI0NjAwMDAwMA==a44b97b1205cafc9e866fb63f3524402"
);

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};

const _optionalChain = (ops) => {
  let lastAccessLHS = undefined;
  let value = ops[0];
  let i = 1;
  while (i < ops.length) {
    const op = ops[i];
    const fn = ops[i + 1];
    i += 2;
    if ((op === "optionalAccess" || op === "optionalCall") && value == null) {
      return undefined;
    }
    if (op === "access" || op === "optionalAccess") {
      lastAccessLHS = value;
      value = fn(value);
    } else if (op === "call" || op === "optionalCall") {
      value = fn((...args) => value.call(lastAccessLHS, ...args));
      lastAccessLHS = undefined;
    }
  }
  return value;
};

//get server side data
const getServerSideDatasource = (
  endPoint,
  tableType,
  setFooter,
  filterState,
  filterData
) => {
  let lastFilter = {};
  let lastFilterStatus = true;
  let lastFilterStatusCount = 0;
  return {
    getRows: async (params) => {
      console.log(params.request.filterModel);
      if (
        JSON.stringify(lastFilter) !==
        JSON.stringify(params.request.filterModel)
      ) {
        lastFilter = params.request.filterModel;
        lastFilterStatus = true;
      } else {
        lastFilterStatus = false;
      }

      if (params.request.startRow === 0) {
        lastFilterStatus = true;
      }

      if (Object.keys(filterData).length !== 0) {
        params.request.filterModel = { ...lastFilter, ...filterData };
      }

      var sDate = getDateFormatYearMonthDay(filterState.startDate);
      var eDate = getDateFormatYearMonthDay(filterState.endDate);
      // console.log(
      //   "[Datasource] - rows requested by grid: ",
      //   filterState.company
      // );
      // console.log(params.request);
      // console.log(sDate, eDate);
      var response = await axios.post(endPoint, {
        data: {
          ...params.request,
          lastFilterStatus: lastFilterStatus,
          company: filterState.company,
          startDate: sDate,
          endDate: eDate,
          kyn:
            tableTypeList.duzeltmeList === tableType
              ? "D"
              : tableTypeList.virmanList === tableType
              ? "V"
              : "",
        },
      });
      if (lastFilterStatus) {
        setFooter((s) => {
          return {
            ...s,

            [tableType === tableTypeList.axabtaList
              ? "axabtaKayitTtl"
              : tableType === tableTypeList.duzeltmeList
              ? "duzeltmeKayitTtl"
              : tableType === tableTypeList.virmanList
              ? "virmanKayitTtl"
              : tableType === tableTypeList.reelList
              ? "reelKayitTtl"
              : ""]: response.data?.footerTotal[0],
          };
        });
      }

      // adding delay to simulate real server call
      setTimeout(function () {
        if (response.data.success) {
          // const lastRow =
          // response.data.rows.length <= params.request.endRow
          //   ? response.data.rows.length
          //   : -1;
          // call the success callback
          console.log(response.data);
          params.success({
            rowData: response.data.rows,
            rowCount:
              response.data.lastRow === null ? 0 : response.data.lastRow,
          });
        } else {
          // inform the grid request failed
          params.fail();
        }
      }, 1000);
    },
  };
};

const GridExample = ({
  endPoint,
  tableType,
  filterDisable,
  filterData = {},
  gridType,
}) => {
  const intl = useIntl();
  const gridRef = useRef();
  const refRefresh = useRef();

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const setFooterTotalState = useSetRecoilState(footerTotalAtom);
  const setIsLoading = useSetRecoilState(isLoadingAtom);
  const getMainFilterState = useRecoilValue(mainFilterAtom);
  const getAgGridServerSideAtom = useRecoilValue(agGridServerSideAtom);
  const setVirmanSelectState = useSetRecoilState(virmanSelectAtom);

  const [columnDefs, setColumnDefs] = useState(
    tableType === tableTypeList.salesList
      ? salesColumn(tableType, filterDisable, filterData)
      : mizanColumn(tableType, filterDisable, filterData)
  );

  function onSelectionChanged() {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setVirmanSelectState(selectedRows);
  }

  //custom aggregate function
  const aggFuncs = {
    //for group
    // mySum: (params) => {
    //   let sum = 0;
    //   params.values.forEach((value) => (sum += value));
    //   return Math.round(sum * 100) / 100;
    // },
  };
  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  // const onRowValueChanged = useCallback((event) => {
  //   var data = event.data;
  //   console.log(data);
  // }, []);

  // const onCellValueChanged = useCallback(async (event) => {
  //   switch (tableType) {
  //     case tableTypeList.salesList:
  //       const trsNo = event.data.TRSNO;
  //       const field = event.colDef.field;
  //       const oldValue = event.oldValue;
  //       const newValue = event.newValue;

  //       var res = await axios
  //         .post(
  //           `${endPointSalesPurchase.purchaseSalesUpdateSingleOneItem}/${trsNo}`,
  //           {
  //             data: {
  //               column: field,
  //               newValue: newValue,
  //             },
  //           }
  //         )
  //         .then((res) => res.data);

  //       console.log(res);
  //       break;
  //   }
  // }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 80,
      resizable: true,
      floatingFilter: filterDisable ? true : false,
      filter: true,
      sortable: true,
      editable: true,
    };
  }, []);

  // const autoGroupColumnDef = useMemo(() => {
  //   return {
  //     minWidth: 200,
  //     // supplies 'country' values to the filter
  //     filterValueGetter: (params) =>
  //       _optionalChain([
  //         params,
  //         "access",
  //         (_) => _.data,
  //         "optionalAccess",
  //         (_2) => _2.hesapKodu,
  //       ]),
  //   };
  // }, []);

  // optional as 'singleColumn' is the default group display type
  const groupDisplayType = "singleColumn";

  // const onGridReady = useCallback((params) => {
  //   setRowData(tableData);
  // }, []);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    // create datasource with a reference to the fake server
    var datasource = getServerSideDatasource(
      endPoint,
      tableType,
      setFooterTotalState,
      getMainFilterState,
      filterData
    );
    // register the datasource with the grid
    params.api.setServerSideDatasource(datasource);
    /**
     * TODO : resize function will be controlled after
     * data's coming truely
     */
    setTimeout(() => autoSizeAll(), 200);
  }, []);

  const rowClassRules = useMemo(() => {
    return {
      // row style function
      amendmentEntry: (params) => {
        return params.data?.kyn === "D";
      },
      salesWarning: (params) => {
        return params.data?.PURCHASEAMOUNTUSD > 0;
      },
      transferEntry: (params) => {
        return params.data?.kyn === "V" || params.data?.KYN === "D";
      },
      transferEntryAxapta: (params) => {
        return params.data?.isUsed === true;
      },
      salesItemUsed: (params) => {
        return params.data?.KYN === "A" && params.data?.ISCLONED === true;
      },
    };
  }, []);
  const refreshStore = () => {
    gridApi.refreshServerSideStore({ purge: true });
  };

  const onRowValueChanged = useCallback((event) => {
    var data = event.data;
    console.log(data);
  }, []);

  const onCellEditRequest = useCallback(async (event) => {
    const field = event.colDef.field;
    const oldValue = event.oldValue;
    let newValue;
    console.log("onCellEditRequest");
    let res;
    switch (tableType) {
      case tableTypeList.salesList:
        const trsNo = event.data.TRSNO;
        if (typeof event.newValue === "object") {
          newValue = event.newValue.NAME;
        } else {
          newValue = event.newValue;
        }

        res = await axios
          .patch(
            `${endPointSalesPurchase.purchaseSalesUpdateSingleOneItem}/${trsNo}`,
            {
              data: {
                column: field,
                newValue: newValue,
              },
            }
          )
          .then((res) => res.data);
        break;
      case tableTypeList.transferBalance:
        const id = event.data.id;
        const kur = event.data.kur;
        if (typeof event.newValue === "object") {
          newValue = event.newValue.NAME;
        } else {
          newValue = event.newValue;
        }
        console.log(id, field, parseFloat(newValue), kur);
        res = await axios
          .patch(`/mizan/reel/mizan-clone-transfer-record`, {
            data: {
              id: id,
              column: field,
              newValue: newValue,
            },
          })
          .then((res) => res.data);
        break;
    }

    if (res.success) {
      refRefresh.current.click();
    } else {
      AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_bad_job",
            text: "error_create_reverse",
            setIsLoading,
          },
        },
      });
    }
  }, []);

  const onCellValueChanged = useCallback((params) => {
    console.log("onCellValueChanged, new value = " + params.newValue);
  }, []);

  return (
    <div style={{ height: "520px" }}>
      <div>
        <a
          ref={refRefresh}
          onClick={refreshStore}
          style={{ cursor: "pointer", fontSize: "12px", marginRight: "10px" }}
        >
          Refresh
        </a>
      </div>
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            aggFuncs={aggFuncs}
            // rowData={rowData}
            // columnDefs={columnDefs}
            // defaultColDef={defaultColDef}
            // autoGroupColumnDef={autoGroupColumnDef}
            // groupDisplayType={groupDisplayType}
            // animateRows={true}
            // onGridReady={onGridReady}
            // rowModelType={"serverSide"}
            // serverSideInfiniteScroll={true}
            // suppressAggFuncInHeader={true}
            // cacheBlockSize={5}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowClassRules={rowClassRules}
            rowModelType={"serverSide"}
            serverSideInfiniteScroll={true}
            onGridReady={onGridReady}
            //checkbox selection
            rowSelection="multiple"
            cacheBlockSize={75}
            animateRows={true}
            onSelectionChanged={onSelectionChanged}
            suppressRowClickSelection
            // editType={"fullRow"}
            // onRowValueChanged={onRowValueChanged}
            readOnlyEdit={true}
            onCellEditRequest={onCellEditRequest}
            onRowValueChanged={onRowValueChanged}
            onCellValueChanged={onCellValueChanged}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

export default GridExample;

GridExample.defaultProps = {
  endPoint: "",
  tableType: "",
  filterDisable: true,
  filterData: {},
};
