/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

//language
import { FormattedDate, FormattedMessage } from "react-intl";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// react-router-dom components
import { Link } from "react-router-dom";

//utils
import { getTextShortWithThreeDots } from "utils/helpers/avs";

function AVSRequestCard({
  color,
  voucherCount,
  description,
  createDate,
  href,
}) {
  const cardStyle = {
    width: "205px",
    height: "190px",
    margin: "auto",
    cursor: "pointer",
  };

  return (
    <Card style={cardStyle}>
      <Link to={`${href}`}>
        <SuiBox p={2} mx={3} display="flex" justifyContent="center">
          <SuiBox
            display="grid"
            justifyContent="center"
            alignItems="center"
            bgColor={color}
            color="white"
            width="4rem"
            height="4rem"
            shadow="md"
            borderRadius="lg"
            variant="gradient"
          >
            <SuiTypography
              variant="h6"
              color="white"
              textAlign="center"
              fontWeight="bold"
            >
              {voucherCount}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox px={2} textAlign="center" lineHeight={1}>
          <SuiTypography
            variant="h6"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {getTextShortWithThreeDots(description, 18)}
            <br />
            <SuiTypography
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color="inherit"
              sx={{ fontWeight: "100%" }}
            >
              <FormattedDate value={createDate} />
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
      </Link>
    </Card>
  );
}

// Setting default values for the props of AVSDefaultInfoCard
AVSRequestCard.defaultProps = {
  color: "primary",
  value: "",
};

// Typechecking props for the AVSDefaultInfoCard
AVSRequestCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  voucherCount: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default AVSRequestCard;
