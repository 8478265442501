import moment from "moment";

const getDateFormatCurrencyRate = (rateDate) => {
  return moment(rateDate).add(10, "days").calendar();
};

const getDateFormatYearMonthDay = (rateDate) => {
  return moment(rateDate).format("YYYY-MM-DD");
};
const getYearStartFromDate = (rateDate) => {
  const year = moment(rateDate).year();
  return `${year}-01-01`;
};

export {
  getDateFormatCurrencyRate,
  getDateFormatYearMonthDay,
  getYearStartFromDate,
};
