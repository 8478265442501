/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
// react-flatpickr components
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr";
import { LOCALES } from "i18n/locales";
import { useRecoilValue } from "recoil";
import { localeAtom } from "recoil/atoms";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

// Soft UI Dashboard PRO React components
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

function SuiDatePicker({ input, label, button_name, ...rest }) {
  const globalLocale = useRecoilValue(localeAtom);

  return (
    <>
      {label && (
        <SuiBox display="flex" alignItems="center" my={1}>
          <SuiTypography
            variant="caption"
            textTransform="capitalize"
            fontWeight="bold"
          >
            <FormattedMessage id={label} />
          </SuiTypography>
        </SuiBox>
      )}
      <SuiBox display="flex">
        <Flatpickr
          {...rest}
          options={{
            locale: globalLocale === LOCALES.TURKISH ? Turkish : "default",
            // dateFormat: globalLocale === LOCALES.TURKISH ? "d.m.Y" : "m.d.Y",
            dateFormat: "Y-m-d",
          }}
          render={({ defaultValue }, ref) => (
            <SuiInput {...input} defaultValue={defaultValue} inputRef={ref} />
          )}
        />
        {button_name !== "" && (
          <SuiButton
            variant="gradient"
            color="dark"
            sx={{ marginLeft: "1rem" }}
          >
            <FormattedMessage id={button_name} />
          </SuiButton>
        )}
      </SuiBox>
    </>
  );
}

// Setting default values for the props of SuiDatePicker
SuiDatePicker.defaultProps = {
  input: {},
  button_name: "",
};

// Typechecking props for the SuiDatePicker
SuiDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
};

export default SuiDatePicker;
