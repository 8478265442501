import React, { useState } from "react";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiDatePicker from "components/SuiDatePicker";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";

import AvsVirmanGrid from "components/avs/AvsAgGrid/AvsVirmanGrid";
import { FormattedMessage, useIntl } from "react-intl";

// Soft UI component
import AvsBreadcrumbs from "components/avs/AvsBreadcrumbs";

//service
import axios from "services/axios/index";

//utils
import { getDateFormatYearMonthDay } from "utils/helpers/dateFormat";

//state
import { virmanSelectAtom, isLoadingAtom } from "recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import AvsSwal from "components/avs/AvsSwal";
import SwalTypes from "components/avs/AvsSwal/types";

function VirmanRequestCreate() {
  const breadcrumbRoutes = [
    {
      route: "mizan",
      label: "trial_balance",
    },
    {
      route: "menu",
      label: "finance",
    },
  ];

  const typleList = [
    { value: "customer", label: <FormattedMessage id="customer" /> },
    { value: "vendor", label: <FormattedMessage id="vendor" /> },
  ];

  const { formatMessage } = useIntl();
  const intl = useIntl();

  //global state
  const getVirmanSelect = useRecoilValue(virmanSelectAtom);
  const setIsLoading = useSetRecoilState(isLoadingAtom);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [requstType, setRequestType] = useState(typleList[0].value);
  const [explanation, setExplanation] = useState("");
  const [fileNo, setFileNo] = useState("");
  const [tableData, setTableData] = useState([]);

  const handleSetStartDate = (newDate) => setStartDate(newDate[0]);
  const handleSetEndDate = (newDate) => setEndDate(newDate[0]);
  const handleSetFileNo = (e) => {
    setFileNo(e.target.value);
  };

  const handleSetRequestType = (type) => {
    setRequestType(type.value);
  };
  const handleSetExplanation = (e) => {
    setExplanation(e.target.value);
  };

  const handleListed = async () => {
    setIsLoading(true);
    const arrFileNo = fileNo.split("\n").map((item) => item.trim());

    if (arrFileNo.length === 0 || startDate === null || endDate === null) {
      setIsLoading(false);
      return AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_title",
            text: "error_fill_sections",
          },
        },
      });
    }

    var data = {
      docNoList: arrFileNo,
      startDate: getDateFormatYearMonthDay(startDate),
      endDate: getDateFormatYearMonthDay(endDate),
      requestType: requstType,
    };

    const request = await axios
      .post("/mizan/axabta/get-general-trans", {
        data: data,
      })
      .then((res) => res.data);
    //console.log(request.data.length);
    if (request.data.length === 0) {
      return AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_title",
            text: "error_no_new_data_in_axabta",
          },
        },
      });
    }
    if (request.success) {
      setTableData([]);
      setTimeout(() => setTableData(request.data), 100);

      AvsSwal({
        data: {
          type: SwalTypes.success,
          intl,
          props: {
            title: "success",
            text: "success_axapta_data_listed",
          },
        },
      });
    } else {
      AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_title",
            text: "error_axapta_listing",
          },
        },
      });
    }
    setIsLoading(false);
  };

  const handleSend = async () => {
    console.log(getVirmanSelect);
    setIsLoading(true);
    if (explanation === "") {
      setIsLoading(false);
      return AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_title",
            text: "error_description",
          },
        },
      });
    }

    var data = {
      voucherList: getVirmanSelect,
      startDate: getDateFormatYearMonthDay(startDate),
      endDate: getDateFormatYearMonthDay(endDate),
      description: explanation,
      requestType: requstType,
    };

    const request = await axios
      .post("/mizan/reel/virman/create-request-sales", {
        data: data,
      })
      .then((res) => res.data);

    if (request.success) {
      AvsSwal({
        data: {
          type: SwalTypes.success,
          intl,
          props: {
            title: "success",
            text: "success_virement_request_record",
          },
        },
      });
      setStartDate(null);
      setEndDate(null);
      setExplanation("");
      setFileNo("");
      setTableData([]);
    } else {
      AvsSwal({
        data: {
          type: SwalTypes.error,
          intl,
          props: {
            title: "error_title",
            text: "error_virement_request_record",
          },
        },
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      {/* Section Breadcrumb */}
      <AvsBreadcrumbs
        title="create_virement_request"
        currentRoute="virement"
        otherRoutes={breadcrumbRoutes}
      />
      <Grid
        container
        spacing={3}
        style={{
          paddingTop: "0.5rem",
        }}
      >
        <Grid item xs={3}>
          <SuiBox>
            <SuiButton
              variant="gradient"
              color="dark"
              onClick={handleListed}
              sx={{
                width: "100%",
              }}
            >
              <FormattedMessage id="list" />
            </SuiButton>
          </SuiBox>
        </Grid>
        <Grid item xs={9}>
          <SuiBox
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SuiButton variant="gradient" color="dark" onClick={handleSend}>
              <FormattedMessage id="send" />
            </SuiButton>
          </SuiBox>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <SuiDatePicker
            label="start_date"
            value={startDate}
            onChange={handleSetStartDate}
          />
          <SuiDatePicker
            label="end_date"
            value={endDate}
            onChange={handleSetEndDate}
          />
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
          >
            <SuiBox my={1} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                <FormattedMessage id="request_type" />
              </SuiTypography>
            </SuiBox>
            <SuiBox
              bgColor="white"
              style={{ position: "relative", zIndex: 100 }}
            >
              <SuiSelect
                options={typleList}
                defaultValue={typleList[0]}
                onChange={(value) => handleSetRequestType(value)}
              />
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={9}>
          <SuiInput
            label="explanation"
            placeholder="msg_enter_explanation"
            multiline
            rows={4}
            sx={{
              alignItems: "center",
            }}
            value={explanation}
            onChange={handleSetExplanation}
          />
          {/* <AvsVirmanGrid setT /> */}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <SuiBox>
            <SuiInput
              label="file_no"
              multiline
              rows={20}
              value={fileNo}
              onChange={handleSetFileNo}
            />
          </SuiBox>
        </Grid>
        <Grid item xs={9}>
          {tableData.length && (
            <AvsVirmanGrid data={tableData} tableType={"requestVirman"} />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default VirmanRequestCreate;
