/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

//language
import { FormattedMessage } from "react-intl";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// react-router-dom components
import { Link } from "react-router-dom";

function AVSDefaultInfoCard({
  color,
  icon,
  titleFirstLine,
  titleSecondLine,
  href,
}) {
  const cardStyle = {
    width: "205px",
    height: "190px",
    margin: "auto",
    cursor: "pointer",
  };

  return (
    <Link to={`${href}`}>
      <Card style={cardStyle}>
        <SuiBox p={2} mx={3} display="flex" justifyContent="center">
          <SuiBox
            display="grid"
            justifyContent="center"
            alignItems="center"
            bgColor={color}
            color="white"
            width="4rem"
            height="4rem"
            shadow="md"
            borderRadius="lg"
            variant="gradient"
          >
            <Icon fontSize="default">{icon}</Icon>
          </SuiBox>
        </SuiBox>
        <SuiBox pb={2} px={2} textAlign="center" lineHeight={1}>
          <SuiTypography
            variant="h6"
            fontWeight="bold"
            textTransform="capitalize"
          >
            <FormattedMessage id={titleFirstLine} />
            <br />
            <FormattedMessage id={titleSecondLine} />
          </SuiTypography>
        </SuiBox>
      </Card>
    </Link>
  );
}

// Setting default values for the props of AVSDefaultInfoCard
AVSDefaultInfoCard.defaultProps = {
  color: "primary",
  value: "",
};

// Typechecking props for the AVSDefaultInfoCard
AVSDefaultInfoCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  titleFirstLine: PropTypes.string.isRequired,
  titleSecondLine: PropTypes.string.isRequired,
};

export default AVSDefaultInfoCard;
