/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";
// import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DefaultNavbarLink from "components/Navbars/DefaultNavbar/DefaultNavbarLink";

// DefaultNavbar dropdown menus
import PagesMenu from "components/Navbars/DefaultNavbar/Menus/PagesMenu";
import AuthenticationMenu from "components/Navbars/DefaultNavbar/Menus/AuthenticationMenu";
import ApplicationsMenu from "components/Navbars/DefaultNavbar/Menus/ApplicationsMenu";
import EcommerceMenu from "components/Navbars/DefaultNavbar/Menus/EcommerceMenu";
import DocsMenu from "components/Navbars/DefaultNavbar/Menus/DocsMenu";

// DefaultNavbar dropdown menus
import SingleNavbarLink from "components/Navbars/DefaultNavbar/SingleNavbarLink";

function DefaultNavbarMobile({ routes, open, close }) {
  const { width } = open && open.getBoundingClientRect();
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleSepOpenCollapse = (name) =>
    openCollapse === name ? setOpenCollapse(false) : setOpenCollapse(name);

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <SuiBox px={0.5}>
        <SingleNavbarLink name={"HR Ticket"} route="/hrticket" />
        <SingleNavbarLink name={"Person Ticket"} route="/personticket" />
        <SingleNavbarLink name={"calendar"} route="/calendar" />
        <SingleNavbarLink name={"profile"} route="/profile" />
        <SingleNavbarLink name={"vehicles"} route="/vehicles" />
        <SingleNavbarLink name={"Kanban"} route="/kanban" />
        <SingleNavbarLink name={"Messages"} route="/messages" />
      </SuiBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  // routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object])
    .isRequired,
};

export default DefaultNavbarMobile;
