import React, { useState } from "react";
// react-router components
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import { navbarIconButton } from "components/Navbars/DashboardNavbar/styles";
import SuiBox from "components/SuiBox";
import Icon from "@mui/material/Icon"; 
import {
  Avatar,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { GroupsSharp, Logout, PersonAdd ,LibraryBooksIcon } from "@mui/icons-material";
import stringAvatar from "assets/theme/functions/stringAvatar";
const Header = ({ email, signOut, light }) => {
  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenMenu = ({ currentTarget }) => setOpenMenu(currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  return (
    <SuiBox
      display={{
        xs: "none",
        lg: "inline-flex",
        marginRight: 15,
      }}
    >
      {email ? (
        <SuiBox>
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleOpenMenu}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={openMenu ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
            >
              <Avatar
                {...stringAvatar({
                  name: email,
                  width: 46,
                  height: 46,
                  fontSize: 16,
                })}
                size="sx"
              />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={openMenu}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
          > 
            <Link href="/">
              <MenuItem onClick={handleCloseMenu} size="small" circular>
                <ListItemIcon>
                  <GroupsSharp fontSize="small" />
                </ListItemIcon>
                {"Documentation"}
              </MenuItem>
            </Link>
            <Divider />
            <MenuItem onClick={() => handleCloseMenu && signOut()}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </SuiBox>
      ) : (
        <Link href="/authentication/sign-in/basic">
          <IconButton
            sx={navbarIconButton}
            aria-label="Sign In"
            color="inherit"
          >
            <Icon>account_circle</Icon>
          </IconButton>
        </Link>
      )}
    </SuiBox>
  );
};

export default Header;
