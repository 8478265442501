const getCurrencyListForSelect = () => {
  return [
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "TRY", label: "TRY" },
    { value: "GBP", label: "GBP" },
    { value: "SGD", label: "SGD" },
  ];
};

export { getCurrencyListForSelect };
