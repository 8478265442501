import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { LicenseManager } from "ag-grid-enterprise";

//Soft Ui
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiDatePicker from "components/SuiDatePicker";

import "./styles.css";

//localization
import { FormattedMessage } from "react-intl";

//service axios
import axios from "services/axios/index";
import moment from "moment";

//state
import {
  footerTotalAtom,
  mainFilterAtom,
  virmanSelectAtom,
  virmanRequestChangeDateAtom,
} from "recoil/atoms";
import { useSetRecoilState, useRecoilValue } from "recoil";

//table column
import { mizanColumn } from "components/avs/AvsAgGrid/ClientSide/column";

// utils
import { tableTypeList } from "utils/constants/recordType";

LicenseManager.setLicenseKey(
  "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-4_September_2022_[v2]_MTY2MjI0NjAwMDAwMA==a44b97b1205cafc9e866fb63f3524402"
);

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};

const _optionalChain = (ops) => {
  let lastAccessLHS = undefined;
  let value = ops[0];
  let i = 1;
  while (i < ops.length) {
    const op = ops[i];
    const fn = ops[i + 1];
    i += 2;
    if ((op === "optionalAccess" || op === "optionalCall") && value == null) {
      return undefined;
    }
    if (op === "access" || op === "optionalAccess") {
      lastAccessLHS = value;
      value = fn(value);
    } else if (op === "call" || op === "optionalCall") {
      value = fn((...args) => value.call(lastAccessLHS, ...args));
      lastAccessLHS = undefined;
    }
  }
  return value;
};

const GridExample = ({
  height,
  data,
  tableType,
  filterDisable = true,
  title,
  date,
  buttonName,
}) => {
  console.log(buttonName);
  const gridRef = useRef();
  const refRefresh = useRef();

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [rowData, setRowData] = useState(data);

  const setFooterTotalState = useSetRecoilState(footerTotalAtom);
  const setVirmanRequestChangeDateSate = useSetRecoilState(
    virmanRequestChangeDateAtom
  );
  const getMainFilterState = useRecoilValue(mainFilterAtom);
  const setVirmanSelectState = useSetRecoilState(virmanSelectAtom);

  const [columnDefs, setColumnDefs] = useState(
    mizanColumn(tableType, filterDisable)
  );

  function onSelectionChanged() {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setVirmanSelectState(selectedRows);
  }

  //custom aggregate function
  const aggFuncs = {
    //for group
    // mySum: (params) => {
    //   let sum = 0;
    //   params.values.forEach((value) => (sum += value));
    //   return Math.round(sum * 100) / 100;
    // },
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 80,
      resizable: true,
      floatingFilter: filterDisable ? true : false,
      filter: true,
      sortable: true,
      editable: true,
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
      // supplies 'country' values to the filter
      filterValueGetter: (params) =>
        _optionalChain([
          params,
          "access",
          (_) => _.data,
          "optionalAccess",
          (_2) => _2.hesapKodu,
        ]),
    };
  }, []);

  // optional as 'singleColumn' is the default group display type
  const groupDisplayType = "singleColumn";

  // const onGridReady = useCallback((params) => {
  //   setRowData(tableData);
  // }, []);

  const onGridReady = useCallback((params) => {}, []);

  const rowClassRules = useMemo(() => {
    return {
      // row style function
      amendmentEntry: (params) => {
        //console.log(params.data?.kyn);
        return params.data?.kyn === "D";
      },
      transferEntry: (params) => {
        //console.log(params.data?.kyn);
        return params.data?.kyn === "V";
      },
    };
  }, []);

  function removeObjectWithId(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
    const a = [...arr];
    a.splice(objWithIdIndex, 1);
    console.log(a);
    return { list: a, index: objWithIdIndex };
  }

  const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];

  const onCellEditRequest = useCallback(
    async (event) => {
      const id = event.data.id;

      // let newValue;
      // if (typeof event.newValue === "object") {
      //   newValue = event.newValue.NAME;
      // } else {
      //   newValue = event.newValue;
      // }

      const node = rowData.filter((item) => item.id === id);
      const prevData = node[0];

      const newData = {
        ...prevData,
        hesapKodu: event.newValue.HesapKodu,
        name: event.newValue.NAME,
      };
      // const sarr = rowData;
      const { list, index } = await removeObjectWithId(rowData, id);
      const newArr = await insert(list, index, newData);

      setRowData([...newArr]);
      setVirmanRequestChangeDateSate([...newArr]);

      gridRef.current.api.refreshCells({
        force: false,
      });
    },
    [rowData]
  );

  useEffect(() => console.log(rowData), [rowData]);

  return (
    <div style={{ height: `${height}px` }}>
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            aggFuncs={aggFuncs}
            rowData={rowData}
            // columnDefs={columnDefs}
            // defaultColDef={defaultColDef}
            // autoGroupColumnDef={autoGroupColumnDef}
            // groupDisplayType={groupDisplayType}
            // animateRows={true}
            // onGridReady={onGridReady}
            // rowModelType={"serverSide"}
            // serverSideInfiniteScroll={true}
            // suppressAggFuncInHeader={true}
            // cacheBlockSize={5}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowClassRules={rowClassRules}
            // rowModelType={"serverSide"}
            // serverSideInfiniteScroll={true}
            onGridReady={onGridReady}
            //checkbox selection
            enableCellChangeFlash={true}
            rowSelection="multiple"
            onSelectionChanged={onSelectionChanged}
            suppressRowClickSelection
            readOnlyEdit={true}
            onCellEditRequest={onCellEditRequest}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};
export default GridExample;
