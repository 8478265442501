import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { FormattedMessage } from "react-intl";

import AsyncSelect from "react-select/async";

//service axios
import axios from "services/axios";

const KEY_BACKSPACE = "Backspace";
const KEY_DELETE = "Delete";
const KEY_F2 = "F2";
const KEY_ENTER = "Enter";
const KEY_TAB = "Tab";

const SelectEditor = memo(
  forwardRef((props, ref) => {
    const initialState = {
      label: props.value,
      value: props.value,
    };
    const [value, setValue] = useState(initialState);
    const [inputValue, setInputValue] = useState("");

    const field = props.colDef.field;

    const refInput = useRef(null);

    /* Utility Methods */
    const cancelBeforeStart =
      props.charPress && "1234567890".indexOf(props.charPress) < 0;

    const isLeftOrRight = (event) => {
      return ["ArrowLeft", "ArrowLeft"].indexOf(event.key) > -1;
    };

    const isCharNumeric = (charStr) => {
      return !!/\d/.test(charStr);
    };

    const isKeyPressedNumeric = (event) => {
      const charStr = event.key;
      return isCharNumeric(charStr);
    };

    const deleteOrBackspace = (event) => {
      return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
    };

    const finishedEditingPressed = (event) => {
      const key = event.key;
      return key === KEY_ENTER || key === KEY_TAB;
    };

    const onKeyDown = (event) => {
      if (isLeftOrRight(event) || deleteOrBackspace(event)) {
        event.stopPropagation();
        return;
      }

      if (!finishedEditingPressed(event) && !isKeyPressedNumeric(event)) {
        if (event.preventDefault) event.preventDefault();
      }

      if (finishedEditingPressed(event)) {
        props.stopEditing();
      }
    };

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return value;
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return cancelBeforeStart;
        },

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          // will reject the number if it greater than 1,000,000
          // not very practical, but demonstrates the method.
          return value > 1000000;
        },
      };
    });

    // handle input change event
    const handleInputChange = (value) => {
      setInputValue(value);
    };
    // handle input change event
    const handleChange = (value) => {
      setValue(value);
    };

    const handleChangeSelect = (data) => {
      console.log(data);
      const oldData = [...props.datas];
      oldData[props.index].value = data.NAME;
      props.setDatas(oldData);
    };

    const loadOptions = (inputValue) => {
      console.log(inputValue);
      const url =
        field === "FIRMA"
          ? `/purchaseSales/vendorList/${inputValue}`
          : field === "SHIPNAME"
          ? `/purchaseSales/vesselList/avs/${inputValue}`
          : field === "COUNTRY"
          ? `/purchaseSales/countryList/${inputValue}`
          : field === "CUSTOMER"
          ? `/purchaseSales/customerList/avs/${inputValue}`
          : field === "DELIVERYPORT" || field === "PURCHASEPLACE"
          ? `/purchaseSales/portList/${inputValue}`
          : field === "MAINDIVISIONS" ||
            field === "SUBDIVISIONS" ||
            field === "DIVISIONS"
          ? `/purchaseSales/divisionListWithSearch/${inputValue}`
          : "";
      return axios
        .get(url)
        .then((res) => res.data)
        .then((data) => data.data);
    };

    return (
      <SuiBox style={{ width: "300px", border: "none" }}>
        {props.label && (
          <SuiBox display='flex' alignItems='center' my={1}>
            <SuiTypography
              variant='caption'
              textTransform='capitalize'
              fontWeight='bold'
            >
              <FormattedMessage id={props.label} />
            </SuiTypography>
          </SuiBox>
        )}
        {props.valueFromProp ? (
          <AsyncSelect
            ref={refInput}
            cacheOptions
            defaultOptions
            getOptionLabel={(e) => e.NAME}
            getOptionValue={(e) => e.NAME}
            loadOptions={loadOptions}
            onInputChange={handleInputChange}
            onChange={handleChangeSelect}
          />
        ) : (
          <AsyncSelect
            ref={refInput}
            cacheOptions
            defaultOptions
            value={value}
            getOptionLabel={(e) => e.NAME}
            getOptionValue={(e) => e.NAME}
            loadOptions={loadOptions}
            onInputChange={handleInputChange}
            onChange={handleChange}
          />
        )}
      </SuiBox>
    );
  })
);

export default SelectEditor;
//sorgu numarasi 393034
