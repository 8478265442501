import React from "react";

// Soft UI component
import SuiBox from "components/SuiBox";

// Avs  UI component
import AvsBreadcrumbs from "components/avs/AvsBreadcrumbs";
import AvsDataEntry from "components/avs/AvsDataEntry";

function PartVirman() {
  const breadcrumbRoutes = [
    {
      route: "mizan",
      label: "trial_balance",
    },
    {
      route: "menu",
      label: "finance",
    },
  ];

  return (
    <SuiBox>
      {/* Section Breadcrumb */}
      <AvsBreadcrumbs
        title="create_partial_virement"
        currentRoute="create_partial_virement"
        otherRoutes={breadcrumbRoutes}
      />
      <AvsDataEntry isVirman={true} />
    </SuiBox>
  );
}

export default PartVirman;
