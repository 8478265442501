import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { tr } from 'date-fns/locale'

import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import { FormattedMessage } from 'react-intl';

export default function AvsMonthPicker({ value, onChange, label }) {
    return (
        <SuiBox width="100%">
            {label && (
                <SuiBox display="flex" alignItems="center" my={1}>
                    <SuiTypography
                        variant="caption"
                        textTransform="capitalize"
                        fontWeight="bold"
                    >
                        <FormattedMessage id={label} />
                    </SuiTypography>
                </SuiBox>
            )}
            <LocalizationProvider
                dateAdapter={AdapterDayjs}>
                <DatePicker
                    views={['year', 'month']}
                    value={value}
                    onChange={onChange}
                    inputFormat="YYYYMM"
                    InputAdornmentProps={{
                        position: 'start', variant: 'standard'
                    }}

                    renderInput={(params) => <TextField sx={{ width: "inherit" }}  {...params} />}
                />
            </LocalizationProvider>
        </SuiBox>
    );
}
