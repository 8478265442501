import stringToColor from "assets/theme/functions/stringToColor";

function stringAvatar({ name, width, height, fontSize, color }) {
  return {
    sx: {
      bgcolor: color ? color : stringToColor(name),
      width: width && width,
      height: height && height,
      fontSize: fontSize && fontSize,
      textTransform: "uppercase",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[0][1]}${
      name.split(" ")[0][2]
    }`,
  };
}

export default stringAvatar;
