import React from 'react';
//language
import { FormattedMessage } from "react-intl";

export default (props) => {  
  return (
    <div>
       <FormattedMessage id={props.displayName} />
    </div>
  );
};